const { REACT_APP_BASE_URL } = process.env;
// export const baseUrl = REACT_APP_BASE_URL;
export const baseUrl = REACT_APP_BASE_URL||"https://development-salon-backend-xi.vercel.app";

export const headers = {
  'Content-Type': 'application/json',
};

export const jwtHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${JSON.parse(localStorage.getItem('aaradhak-admin'))?.token}`,
    Authorization: `${localStorage.getItem('shuddham_token')}`,
  };
  return headers;
};

export const jwtFormDataHeaders = () => {
  const headers = {
    Authorization: `${localStorage.getItem('shuddham_token')}`,
  };
  return headers;
};

export const logoutUtil = () => {
  localStorage.removeItem('aaradhak-admin');
  window.location.href = '/login';
};

export const handleCheckTexts = (value) => {
  if (value?.trim() === '') return true;
  return false;
};

export const handleCheckNumbers = (value) => {
  if (value?.length !== 10) return true;
  return false;
};

export const handleCheckEmail = (value) => {
  if (value?.trim() === '' || !value?.includes('@') || !value.includes('.')) return true;
  return false;
};

export const handleCheckPassword = (value1, value2) => {
  if (value1?.length < 8 || value1 !== value2) return true;
  return false;
};

export const handleCheckTime = (value1, value2) => {
  if (value1 >= value2) return true;
  return false;
};

export const handleCheckBeforeDate = (value) => {
  if (value === '' || new Date(value) < new Date(new Date().toLocaleDateString())) return true;
  return false;
};

export const handleCheckAfterDate = (value) => {
  if (value === '' || new Date(value) >= new Date(new Date().toLocaleDateString())) return true;
  return false;
};
