import { Box, MenuItem, Select, Typography } from '@mui/material';
import React from 'react'
import { ReqField } from '../CustomComponents/CustomLoader';

const SelectInputs = ({ title, name, value, handleChange, options, required, optionTitle, optionValue,disabled ,fieldValidation}) => {
    return (
        <Box>
            <Typography
                variant="subtitle1"
                fontWeight={600}
                component="label"
                htmlFor={name}
                mb="5px"
            >
                {title} {required !== false && <ReqField />}
            </Typography>
            <Select
                fullWidth
                labelId={name}
                id={name}
                size="medium"
                mt="25"
                value={value}
                name={name}
                onChange={handleChange}
                disabled={disabled ? true : false}
                error={!!fieldValidation}
            >
                {options?.map((item, index) => {
                    return <MenuItem value={item[optionValue]} key={index}>{item[optionTitle]}</MenuItem>
                })}
                
            </Select>
            {fieldValidation&& <Typography color="error" fontSize='12px' fontWeight={100} ml='15px' mt='2px'>{`${title} is required`}</Typography>}
        </Box>
    )
}

export default SelectInputs
