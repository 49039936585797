import React from 'react';
import { TextField, InputAdornment, Grid } from '@mui/material';

const InputSelect = ({ value, onChange, options, name }) => {
  const handleInputChange = (event) => {
    onChange(event);
  };

  const handleSelectChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    });
  };

  return (
    <TextField
      name={name}
      value={value}
      onChange={handleInputChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">INR</InputAdornment>,
        endAdornment: (
          <TextField
            select
            value={value}
            onChange={handleSelectChange}
            SelectProps={{
              native: true,
            }}
            sx={{
              width: '200px',
              ml: 1,
              '& .MuiInputBase-root': {
                height: '48px',
                borderRadius: '0px 4px 4px 0px',
                borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            variant="outlined"
          >
            {/* Placeholder option */}
            <option value="" disabled>
              Select
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        ),
      }}
      variant="outlined"
      fullWidth
      sx={{
        mt: 0,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '4px 0px 0px 4px',
          },
        },
      }}
    />
  );
}

export default InputSelect;
