import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, Button, Input, InputLabel, Select, MenuItem, Pagination } from '@mui/material';
import './PackagePage.css';
// import { useMain } from 'src/views/hooks/useMain';
import { useMain } from '../../hooks/useMain';
import { createPackages } from 'src/context/contextPages/MembershipContexts';
import TextInputs from 'src/components/inputs/TextInputs';
import { GrStar } from "react-icons/gr";
import { ButtonToggle } from 'reactstrap';
import SearchImg from '../../../assets/search.svg';
import { Grid } from 'rsuite';
import { Box } from '@mui/system';

const PackagePage = () => {
    const { activeBranchId, getAllService, setShowMessage,createPackages} = useMain();
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [packages, setPackages] = useState([]);
    const [showPackageCard, setShowPackageCard] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [clear, setClear] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [packageDetails, setPackageDetails] = useState({
        packageName: '',
        validTill: '',
        totalAmount: 0
    });
    const [loading, setLoader] = useState(false);

    useEffect(() => {
        applyFilters(services, limit, page, search);
    }, [services, limit, page, search]);

    const clearDataHandler = () => {
        setLimit(10);
        setPage(1);
        setSearch('');
        if (clear) {
            getAllServicefData('clear');
            setServices('clear');
            setClear(false);
        }
    };

    const getAllServicefData = async () => {
        try {
            const res = await getAllService(activeBranchId);
            setServices(res?.data || []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (activeBranchId) {
            getAllServicefData();
        }
        return () => {
            setServices([]);
        };
    }, [activeBranchId]);

    const handleCheckboxChange = (service) => {
        setSelectedServices((prevSelected) => {
            const isSelected = prevSelected.some(item => item.service._id === service._id);
            if (isSelected) {
                return prevSelected.filter(item => item.service._id !== service._id);
            } else {
                return [...prevSelected, { service }];
            }
        });
    };

    const handlePackageDetailsChange = (e) => {
        const { name, value } = e.target;
        setPackageDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const recalculateTotalAmount = (updatedServices) => {
        const totalAmount = updatedServices.reduce((total, item) => total + item.service.price || 0, 0);
        setPackageDetails(prevDetails => ({
            ...prevDetails,
            totalAmount
        }));
    };

    const calculateTotalSelectedPrice = () => {
        return selectedServices.reduce((total, item) => total + (item.service.price || 0), 0);
    };

    const handleOpenDialog = () => {
        setShowPackageCard(true);
        setOpenDialog(true);
        recalculateTotalAmount(selectedServices);
        setPackages([selectedServices]);
        setSelectedServices([]);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPackages([]);
        setPackageDetails({
            packageName: '',
            validTill: '',
            totalAmount: 0
        });
    };

    const handleSubmitPackage = async () => {
        const packageData = {
            packageName: packageDetails.packageName,
            price: packageDetails.totalAmount,
            services: packages[0].map((item) => item.service._id),
            validTill: packageDetails.validTill,
            branchDetails: activeBranchId
        };
        //console.log(packageData, "packageDataaaaaaaaaaa");

        try {
            setLoader(true);
            const res = await createPackages(
                JSON.stringify({
                    ...packageData
                })
            );

            handleCloseDialog();
            if (res?.statusCode === 200) {
                setShowMessage({
                    message: res.message || 'Package created successfully',
                    messageType: 'success',
                });
            } else {
                setShowMessage({ message: res.message || 'Package not created', messageType: 'error' });
            }
        } catch (error) {
            setShowMessage({
                message: error.message || 'Error creating Packages',
                messageType: 'error',
            });
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (activeBranchId) {
            setClear(true);
            getAllServicefData();
        }
        return () => {
            setServices([]);
        };
    }, [activeBranchId, limit, page]);

    const handleLimitChange = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    const applyFilters = (services, limit, page, searchName) => {
        let filteredData = services.filter(item => {
            let isNameMatch = true;
            if (searchName) {
                isNameMatch = (item.serviceName.toLowerCase().includes(searchName.toLowerCase()) || item.serviceName.includes(searchName));
            }
            return isNameMatch;
        });

        // Applying pagination
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        filteredData = filteredData.slice(startIndex, endIndex);
        setFilterData(filteredData);
    };

    return (
        <div className="package-container" style={{ display: 'flex', gap: '20px' }}>
            <div style={{ flex: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem' }}>
                    <div><h2>Packages</h2></div>
                    <div>
                        <Grid item xs={2}>
                            <InputLabel>Show per page</InputLabel>
                            <Select
                                style={{
                                    borderRadius: '7px',
                                    border: '0.5px solid #6174DD',
                                    padding: '4px 12px',
                                    background: '#FFF',
                                    height: '40px',
                                }}
                                value={limit}
                                onChange={handleLimitChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </Grid>
                    </div>
                    <div className="search-input" style={{ width: '15rem' }}>
                        <img src={SearchImg} alt="" />
                        <input
                            type="text"
                            placeholder="Search services"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#6174DD', color: '#fff' }}>
                            {['S.no', 'Category', 'Service name', 'Service For', 'Price', 'Select'].map((h, index) => (
                                <th key={index} style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left' }}>{h}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filterData.map((item, index) => {
                            const isSelected = selectedServices.some(service => service.service._id === item._id);
                            return (
                                <tr key={item._id} className={isSelected ? 'selected' : ''} style={{ backgroundColor: isSelected ? '#f0f8ff' : '#fff' }}>
                                    <td style={{ padding: '5px', border: '1px solid #ddd' }}>{index + 1 + ((page - 1) * limit)}</td>
                                    <td style={{ padding: '5px', border: '1px solid #ddd' }}>{item?.category}</td>
                                    <td style={{ padding: '5px', border: '1px solid #ddd' }}>{item?.serviceName}</td>
                                    <td style={{ padding: '5px', border: '1px solid #ddd' }}>{item?.serviceFor}</td>
                                    <td style={{ padding: '5px', border: '1px solid #ddd' }}>₹ {item?.price}</td>
                                    <td style={{ padding: '5px', border: '1px solid #ddd' }}>
                                        <input
                                            type="checkbox"
                                            checked={isSelected}
                                            onChange={() => handleCheckboxChange(item)}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Pagination
                    count={Math.ceil(services.length / limit)}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </div>

             
          <div style={{ flex: 1, marginTop: '45px' }}>
                <h3>Selected Services</h3>
               {selectedServices.length > 0 ? (
                     <ul>
                        {selectedServices.map((item, index) => (
                           <li key={index}>
                               {item.service.serviceName} - ₹{item.service.price}
                          </li>
                        ))}
                    </ul>
                ) : (
                    <p>No services selected</p>
                )}
                 {selectedServices.length > 0 && (
                     <div>
                        <h4>Total Price: ₹{calculateTotalSelectedPrice()}</h4>
                    </div>
                 )}
               <div>
                    <button className="create-btn" onClick={handleOpenDialog}>
                        Create Package
                    </button>
                </div>
            </div> 

           {showPackageCard && packages.length > 0 && (
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    // fullWidtzh='md'
                    // fullWidth='cm'
                    PaperProps={{
                        style: {
                            width: '500px', // set your custom width here
                        },
                    }}
                >
                    <DialogTitle>Package Details</DialogTitle>
                    {packages.map((packageItems, packageIndex) => (
                        <DialogContent key={packageIndex} className="dialog-content">
                            <DialogContentText>Here you can add Package</DialogContentText>
                            <div className='packagebox' style={{ display: 'flex', gap: '16px' }}>
                                <div style={{ flex: 1 }}>
                                    <label>Package Name<GrStar style={{ color: '#FF0000', fontSize: '7.7px', marginBottom: '10px', marginLeft: '2px', fontWeight: '900' }} /></label>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        name="packageName"
                                        value={packageDetails.packageName}
                                        onChange={handlePackageDetailsChange}
                                    />
                                </div>
                            </div>

                            <div style={{ width: '100%' }}>
                                {/* <div className='heading-h6' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h6>Services</h6>
                                    <h6>Price</h6>
                                </div> */}

                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #D3D3D3', padding: '8px', fontWeight: 'bolder' }}>Service</th>
                                            <th style={{ border: '1px solid #D3D3D3', padding: '8px', fontWeight: 'bolder' }}>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packageItems.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid #D3D3D3', padding: '8px' }}>{item.service.serviceName}</td>
                                                <td style={{ border: '1px solid #D3D3D3', padding: '8px' }}>{item.service.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <div style={{ width: '100%' }}>
                                    <TextInputs
                                        title={'Valid Till'}
                                        name={'validTill'}
                                        value={packageDetails?.validTill}
                                        handleChange={handlePackageDetailsChange}
                                        type={'date'}
                                        min={new Date().toISOString().split('T')[0]}
                                    />
                                </div>
                            </div>

                            <div style={{ width: '100%', marginTop: '20px' }}>
                                <label>Package amount<GrStar style={{ color: '#FF0000', fontSize: '7.7px', marginBottom: '10px', marginLeft: '2px', fontWeight: '900' }} /></label>
                                <Input
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    name="totalAmount"
                                    value={packageDetails.totalAmount}
                                    onChange={handlePackageDetailsChange}
                                />
                                <DialogActions>
                                    <ButtonToggle onClick={handleCloseDialog} color="primary">
                                        Cancel
                                    </ButtonToggle>
                                    <ButtonToggle onClick={handleSubmitPackage} color="primary">
                                        Create
                                    </ButtonToggle>
                                </DialogActions>
                            </div>
                        </DialogContent>
                    ))}
                </Dialog>
            )}
        </div>
    );
};

export default PackagePage;


