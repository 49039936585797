import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
} from '@mui/material';
import TableCells from 'src/components/Tables/TableCells';


export const TrimData = (data) => {
    if (data?.length < 25)
        return data;
    return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};


const FieldData = ({ data }) => {
    // const [isEdit, setIsEdit] = useState(false);
    // const [openDialogue, setOpenDialogue] = useState(false);
    // const [alert, setAlert] = useState('');

    // const { deleteProduct } = useMain();

    // const navigate = useNavigate();

    // const handleCloseDialogue = () => {
    //     setOpenDialogue(false);
    // };

    // const handleDeleteField = async (id) => {
    
    //     const res = await deleteProduct(id);

    //     if (res?.status === 'success') {
    //         setProducts(data?.filter((blog) => blog?._id !== id));
    //         setOpenDialogue(false);
    //         setAlert('Product deleted successfully')
    //     }
    //     else {
    //         setOpenDialogue(false);
    //         setAlert(res?.error?.message)
    //     }
    // };

    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            {/* <Snackbar
                open={alert === '' ? false : true}
                autoHideDuration={6000}
                onClose={() => {
                    // setAlert('');
                }}
                severity="error"
                message={alert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            /> */}

            <Table
                aria-label="simple table"
                sx={{
                    whiteSpace: "nowrap",
                    mt: 2
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            background: '#6174DD',
                        }}
                    >
                        <TableCells
                            borderRadius={'10px 0 0 10px'}
                            color={'white'}
                            type={'Heading'}
                            data={'Bill Id'}
                        />
                        {
                            [
                                'Category',
                            ].map((item, index) =>
                                <TableCells
                                    key={index}
                                    color={'white'}
                                    type={'Heading'}
                                    data={item}
                                />
                            )
                        }
                        <TableCells
                            borderRadius={'0px 10px 10px 0px'}
                            color={'white'}
                            type={'Heading'}
                            data={'Actions'}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item) => (
                        <TableRow
                            key={item?._id}
                            sx={{
                                // background:'#FAFAFA'
                            }}
                        >
                            <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={item?._id}
                            />
                            <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={item?.name}
                            />
                            <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'button'}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default FieldData;