import React, { useEffect, useState } from 'react'
import PageContainer from 'src/components/container/PageContainer'
import DashboardCard from 'src/components/shared/DashboardCard'
import { Box, Grid } from '@mui/material'
import Users from './Users'
import { useMain } from '../hooks/useMain'
import ShowLoader from 'src/components/CustomComponents/ShowLoader'

const AllUsers = () => {
    const [users, setUsers] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const { getAllUsers } = useMain();

    const getUsers = async () => {
        setShowLoader(true);
        const res = await getAllUsers();
        setUsers(res?.data?.users);
        setShowLoader(false);
    }

    useEffect(() => {
        getUsers();

        return () => { }
    }, []);

    return (
        <PageContainer title="All Users" description="this contains all users">
            <DashboardCard title="All Users">
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Users users={users} />
                        </Grid>
                    </Grid>
                </Box>
                {showLoader && <ShowLoader value={showLoader} />}
            </DashboardCard>
        </PageContainer>
    )
}

export default AllUsers
