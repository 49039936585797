import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { Box, Button, Grid, Input } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import NotFound from 'src/components/NotFound/NotFound';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import RefreshIcon from '@mui/icons-material/Refresh';

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

export default function IvrLogs() {
  const { setShowMessage, fetchCloudConnect, connectToCall, branchData } = useMain();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resLoading, setResLoading] = useState(false);
  const [connectingId, setConnectingId] = useState(null);
  const [interval, setInterval] = useState({
    startDate: getCurrentMonthDates().startDate,
    endDate: getCurrentMonthDates().endDate,
  });

  const handleDateChange = (e) => {
    const { value, name } = e.target;
    setInterval((pre) => ({ ...pre, [name]: value }));
  };

  const connectToCallHanlder = async (connectingId, number) => {
    setConnectingId(connectingId);
    setResLoading(true);
    try {
      const res = await connectToCall({ number });
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res?.message || 'Successfully connected',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res?.message || 'Some error occures',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Some error occures', messageType: 'error' });
    } finally {
      setResLoading(false);
      setConnectingId(null);
    }
  };

  const getData = async () => {
    const queryParams = `?startDate=${`${interval.startDate}-00:00`}&endDate=${`${interval.endDate}-23:59`}`;
    setLoading(true);
    try {
      const res = await fetchCloudConnect(queryParams);
      if (res.statusCode === 200) {
        const result = res.data;
        setData(result.data || []);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();

    return () => {
      setData([]);
    };
  }, []);

  return (
    <div>
      <PageContainer title="Ivr" description="This contains all Ivr">
        <DashboardCard2 title="Ivr">
          <Grid container>
            <Grid container>
              <Grid item xs={6} sx={{ display: 'flex', gap: '20px' }}>
                <Input
                  type="date"
                  value={interval.startDate}
                  fullWidth
                  onChange={handleDateChange}
                  name="startDate"
                />
                <Input
                  type="date"
                  value={interval.endDate}
                  fullWidth
                  onChange={handleDateChange}
                  name="endDate"
                />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end" gap="10px">
                <Button
                  variant="contained"
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={getData}
                >
                  Apply
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={getData}
                >
                  <RefreshIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loading ? (
            <ShowLoader />
          ) : (
            <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
              {data.length === 0 ? (
                <NotFound />
              ) : (
                <table className="table-container mt-3">
                  <thead className="table-thead">
                    <tr>
                      {['Number', 'Date', 'Time', 'Duration', 'Status', 'Forward', 'Call'].map(
                        (h, index) => {
                          return (
                            <th key={index} style={{ width: '100%' }}>
                              {h}
                            </th>
                          );
                        },
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-tbody">
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <td style={{ width: '10%' }}>
                          {typeof branchData?.isMasked !== 'boolean' || !item?.callee
                            ? 'loading...'
                            : maskData(item?.callee, branchData?.isMasked)}
                        </td>
                        <td style={{ width: '100%' }}>{item?.start_date.split(' ')[0]}</td>
                        <td style={{ width: '100%' }}>{item?.start_date.split(' ')[1]}</td>
                        <td style={{ width: '100%' }}>{item?.call_minute}</td>
                        <td style={{ width: '100%' }}>{item?.call_status}</td>
                        <td style={{ width: '100%' }}>{item?.forward}</td>
                        <td style={{ width: '100%' }}>
                          {connectingId && connectingId === item?.callee && resLoading ? (
                            <span>connecting...</span>
                          ) : (
                            <AddIcCallIcon
                              sx={{ color: '#6174DD', '&:hover': { cursor: 'pointer' } }}
                              onClick={() => connectToCallHanlder(index, item?.callee)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Box>
          )}
        </DashboardCard2>
      </PageContainer>
    </div>
  );
}

function getCurrentMonthDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // Note: Months are zero-based

  const startDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  const lastDay = new Date(currentYear, currentMonth, 0);
  const endDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDay.getDate()}`;

  return {
    startDate,
    endDate,
  };
}


//dev
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import CallIcon from '@mui/icons-material/Call';
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Grid,
// } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import NotFound from 'src/components/NotFound/NotFound';
// import AddIcCallIcon from '@mui/icons-material/AddIcCall';
// import TableCells from 'src/components/Tables/TableCells';

// export default function IvrLogs() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [onUpdateLoading, setOnUpdateLoading] = useState(false);
//   const [updateFormData, setUpdateFromData] = useState({ clientLeadType: '', comment: '' });
//   const [selectClientNumber, setSelectedClientNumber] = useState('');
//   const [openDialogue, setOpenDialogue] = useState(false);

//   const { fetchIvr, IvrCall, setShowMessage, updateIvrDetails } = useMain();

//   const handleUpdate = (item) => {
//     setSelectedClientNumber(item?.callerNumber);
//     setUpdateFromData((pre) => ({
//       ...pre,
//       clientLeadType: item.clientLeadType || '',
//       comment: item.comment || '',
//     }));
//     setOpenDialogue(true);
//   };

//   const handleCloseDialogue = () => {
//     setOpenDialogue(false);
//   };

//   const handleOnChange = (e) => {
//     const { name, value } = e.target;
//     setUpdateFromData((pre) => ({ ...pre, [name]: value }));
//   };

//   const hadleupdateIvrData = async () => {
//     try {
//       setOnUpdateLoading(true);
//       const res = await updateIvrDetails(selectClientNumber, updateFormData);
//       if (res.statusCode === 200) {
//         getData();
//         handleCloseDialogue();
//         setShowMessage({ message: res.message || 'Update Successfully', messageType: 'success' });
//       } else {
//         setShowMessage({ message: res.message || 'Update Error', messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
//     } finally {
//       setOnUpdateLoading(false);
//     }
//   };

//   const postIvrCallHanlder = async (number) => {
//     try {
//       const res = await IvrCall({ number });
//       if (res?.data?.status === 'success') {
//         setShowMessage({
//           message: res?.data?.details || 'Request accepted successfully',
//           messageType: 'success',
//         });
//       } else {
//         setShowMessage({
//           message: res?.data?.message || 'Some error occures',
//           messageType: 'error',
//         });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Some error occures', messageType: 'error' });
//     } finally {
//     }
//   };

//   const getData = async () => {
//     setLoading(true);
//     try {
//       const res = await fetchIvr();
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       }
//     } catch (error) {
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getData();
//     return () => {
//       setData([]);
//     };
//   }, []);

//   return (
//     <div>
//       <PageContainer title="IVR LEADS" description="This contains all IVR LEADS">
//         <DashboardCard2 title="IVR LEADS">
//           <Grid container spacing={2} alignItems="center">
//             {/* <Grid item xs={6}>
//             <Box>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Box>
//           </Grid> */}
//             {/* <Grid item xs={6} container justifyContent="flex-end">
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 gap: '10px',
//               }}
//             >
//               <div
//                 style={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   margin: '0px 5px 0px 10px',
//                   minWidth: '56px',
//                 }}
//               >
//                 <span>Sort By : </span>
//               </div>
//               <div>
//                 <Box>
//                   <Select
//                     style={{
//                       borderRadius: '7px',
//                       border: '0.5px solid #6174DD',
//                       padding: '4px 12px',
//                       background: '#FFF',
//                       height: '40px',
//                     }}
//                     value={sortBy}
//                     onChange={handleFilter}
//                   >
//                    {filterMenu.map((item,index)=><MenuItem key={index} value={item.value}>{item.label}</MenuItem>)}
//                   </Select>
//                 </Box>
//               </div>
//               <div
//                 style={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   margin: '0px 5px 0px 10px',
//                   minWidth: '74px',
//                 }}
//               >
//                 <span>Search By : </span>
//               </div>
//               <div>
//                 <Box>
//                   <Select
//                     style={{
//                       borderRadius: '7px',
//                       border: '0.5px solid #6174DD',
//                       padding: '4px 12px',
//                       background: '#FFF',
//                       height: '40px',
//                       minWidth: '190px',
//                     }}
//                     value={searchKey}
//                     onChange={handleSearchKey}
//                   >
//                     {searchMenu.map((item, index) => (
//                       <MenuItem key={index} value={item.searchKey}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </Box>
//               </div>
//               <div className="search-input">
//                 <img src={SearchImg} alt="" />
//                 <input
//                   type="text"
//                   placeholder={searchInputPlaceholder[searchKey]}
//                   value={searchValue}
//                   onChange={(e) => setSearchValue(e.target.value)}
//                 />
//               </div>
//               <div>
//                 <Button
//                   disabled={searchValue ? false : true}
//                   variant="contained"
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#6174DD',
//                   }}
//                   onClick={onApplyHandler}
//                 >
//                   Apply
//                 </Button>
//               </div>
//               <div>
//                 <Button
//                   disabled={searchValue ? false : true}
//                   variant="contained"
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#ff4d4d',
//                     '&:hover': {
//                       background: '#ff6666',
//                     },
//                   }}
//                   onClick={clearDataHandler}
//                 >
//                   Clear
//                 </Button>
//               </div>
//             </div>
//           </Grid> */}
//             {/* Add another Grid item for the Refresh button */}
//             <Grid item xs={12} container justifyContent="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   background: '#6174DD',
//                 }}
//                 onClick={getData}
//               >
//                 <RefreshIcon />
//               </Button>
//             </Grid>
//           </Grid>
//           {loading ? (
//             <ShowLoader />
//           ) : (
//             <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
//               {data.length === 0 ? (
//                 <NotFound />
//               ) : (
//                 <table className="table-container mt-3">
//                   <thead className="table-thead">
//                     <tr>
//                       <th
//                         style={{
//                           width: '40%',
//                           justifyContent: 'space-between',
//                           alignContent: 'space-between',
//                         }}
//                       >
//                         {' '}
//                       </th>
//                       {[
//                         'Caller Number',
//                         'Department',
//                         'Date',
//                         'Time',
//                         'Call Duration',
//                         'Type',
//                         'Comment',
//                       ].map((h, index) => {
//                         return (
//                           <th key={index} style={{ width: '100%' }}>
//                             {h}
//                           </th>
//                         );
//                       })}
//                       <th style={{ width: '90%' }}>
//                         <div
//                           style={{
//                             width: '50%',
//                             padding: '2px',
//                             display: 'flex',
//                             justifyContent: 'center',
//                           }}
//                         >
//                           Reverse Call
//                         </div>
//                       </th>
//                       <th style={{ width: '20%' }}>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody className="table-tbody">
//                     {data?.map((item, index) => (
//                       <tr key={index}>
//                         <td style={{ width: '40%' }}>
//                           <div
//                             style={{
//                               width: '60%',
//                               background: `${
//                                 item?.loadDetails[0]?.action === 'missed' ? '#fb6f61' : '#acd842'
//                               }`,
//                               padding: '2px',
//                               display: 'flex',
//                               justifyContent: 'center',
//                             }}
//                           >
//                             <CallIcon sx={{ color: 'white' }} />
//                           </div>
//                         </td>
//                         <td style={{ width: '100%' }}>{item?.callerNumber}</td>
//                         <td style={{ width: '100%' }}>{item?.displayName}</td>
//                         <td style={{ width: '100%' }}>
//                           {formatRestTime(item?.loadDetails[0]?.restTime).formattedDate}
//                         </td>
//                         <td style={{ width: '100%' }}>
//                           {formatRestTime(item?.loadDetails[0]?.restTime).formattedTime}
//                         </td>
//                         <td style={{ width: '100%' }}>{item?.callDuration}</td>
//                         <td style={{ width: '100%' }}>{item?.clientLeadType}</td>
//                         <td style={{ width: '100%' }}>{item?.comment}</td>
//                         <td style={{ width: '100%' }}>
//                           <AddIcCallIcon
//                             sx={{ color: '#6174DD', '&:hover': { cursor: 'pointer' } }}
//                             onClick={() => postIvrCallHanlder(item?.callerNumber)}
//                           />
//                         </td>
//                         <td>
//                           <TableCells type="button" handleUpdate={() => handleUpdate(item)} />
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               )}
//             </Box>
//           )}
//           {/* ---------------------- Client Details Dialog ---------------- */}
//           <Dialog
//             open={openDialogue}
//             onClose={handleCloseDialogue}
//             PaperProps={{
//               style: {
//                 maxWidth: '1200px',
//                 width: '40%',
//                 minHeight: '40%',
//               },
//             }}
//           >
//             <DialogTitle>IVR Details</DialogTitle>
//             <DialogContent>
//               <DialogContentText>Here you can update IVR details.</DialogContentText>

//               <div style={{ width: '100%', marginTop: '20px' }}>
//                 <label for="exampleInputEmail1" className="form-label">
//                   Select Lead Type
//                 </label>
//                 <select
//                   class="form-select"
//                   aria-label="Default select example"
//                   name="clientLeadType"
//                   value={updateFormData?.clientLeadType}
//                   onChange={handleOnChange}
//                 >
//                   <option value="" disabled>
//                     --select--
//                   </option>
//                   <option value="Hot">Hot</option>
//                   <option value="Cold">Cold</option>
//                   <option value="Dead">Dead</option>
//                 </select>
//               </div>
//               <div style={{ width: '100%', marginTop: '20px' }}>
//                 <label for="exampleInputEmail1" className="form-label">
//                   Add Comment
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="exampleInputEmail1"
//                   aria-describedby="emailHelp"
//                   placeholder="add comment"
//                   value={updateFormData.comment}
//                   onChange={handleOnChange}
//                   name="comment"
//                 />
//               </div>
//             </DialogContent>
//             <DialogActions>
//               {onUpdateLoading ? (
//                 <ShowLoader />
//               ) : (
//                 <>
//                   <div style={{ width: '200px', margin: '3rem auto' }}>
//                     <Button
//                       variant="contained"
//                       fullWidth
//                       color="primary"
//                       target="_blank"
//                       sx={{
//                         background: '#6174DD',
//                       }}
//                       onClick={handleCloseDialogue}
//                     >
//                       Cancel
//                     </Button>
//                   </div>
//                   <div style={{ width: '200px', margin: '3rem auto' }}>
//                     <Button
//                       variant="contained"
//                       fullWidth
//                       color="primary"
//                       target="_blank"
//                       sx={{
//                         background: '#6174DD',
//                       }}
//                       onClick={hadleupdateIvrData}
//                     >
//                       update
//                     </Button>
//                   </div>
//                 </>
//               )}
//             </DialogActions>
//           </Dialog>
//         </DashboardCard2>
//       </PageContainer>
//     </div>
//   );
// }

// function formatRestTime(restTimeStr) {
//   // Convert the string to a Date object
//   const restTime = new Date(restTimeStr);

//   // Format date in "YYYY-MM-DD" format
//   const formattedDate = restTime.toLocaleDateString('en-CA', {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//   });

//   // Convert time to Indian Standard Time (IST)
//   const indianTime = new Date(restTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));

//   // Format time separately
//   const formattedTime = indianTime.toLocaleTimeString('en-US', { hour12: true });

//   // Return the formatted date and time
//   return {
//     formattedDate,
//     formattedTime,
//   };
// }
