import {  Checkbox } from '@mui/material';
import React from 'react';

const CheckboxInputs = ({
  type,
  isAllChecked,
  handleAllCheckboxChange,
  handleSignleCheckboxChange,
  checkedItems,
  fieldId,
}) => {
  const handleHeadingChangeCheckbox = () => {
    handleAllCheckboxChange();
  };
  const handleContentChangeCheckbox = () => {
    handleSignleCheckboxChange(fieldId);
  };
  const handleCustomChangeCheckbox = () => {
  };
  return (
    <>
      {type === 'Heading' ? (
        <td style={{ borderRadius: '10px 0 0 10px', padding: 0, border: "none" }}>
          <input
            style={{
              color: 'white',
              '& .MuiSvgIcon-root': {
                fill: 'white', // Set the checkmark color to white
              },
              '&.Mui-checked': {
                color: 'white', // Set the checkbox color to red when checked
              },
            }}
            type='checkbox'
            checked={isAllChecked}
            onChange={handleHeadingChangeCheckbox}
            name="HeadingCheckbox"
          />
        </td>
      ) : type === 'content' ? (
        <td
          style={{
            padding: 0,
            borderRadius: '0px',
            my: '10px',
          }}
        >
          <input
            checked={
              (checkedItems?.length && checkedItems.some((check) => check._id === fieldId)) ||
              false
            }
            type='checkbox'
            onChange={handleContentChangeCheckbox}
            name="ContentCheckbox"
          />
        </td>
      ) : (
        <td>
          <input type='checkbox' onChange={handleCustomChangeCheckbox} name="CustomCheckbox" />
        </td>
      )}
    </>
  );
};

export default CheckboxInputs;
