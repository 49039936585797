import React, { useEffect, useState } from 'react'
import PageContainer from 'src/components/container/PageContainer'
import DashboardCard from 'src/components/shared/DashboardCard'
import { Box, Grid } from '@mui/material'
import Banners from './Banners'
import { useMain } from '../hooks/useMain'
import ShowLoader from 'src/components/CustomComponents/ShowLoader'

const AllBanners = () => {
    const [banners, setBanners] = useState([]);
    const [showLoader, setShowLoader]=useState(false);

    const { getAllBanners } = useMain();

    const getBanners = async () => {
        setShowLoader(true);
        const res = await getAllBanners();
        setBanners(res?.data?.banners);
        setShowLoader(false);
    }

    useEffect(() => {
        getBanners();

        return () => { }
    }, []);

    return (
        <PageContainer title="All Banners" description="this contains all banners">
            <DashboardCard title="All Banners">
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Banners banners={banners} setBanners={setBanners} />
                        </Grid>
                    </Grid>
                </Box>
                {showLoader && <ShowLoader value={showLoader} />}
            </DashboardCard>
        </PageContainer>
    )
}

export default AllBanners
