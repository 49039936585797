// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';

// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SearchImg from '../../assets/search.svg';
// import Expenses from './Expenses';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';

// const expensestype = ['Salary', 'Others'];

// const filterModeOfPayment = [
//   { label: 'Cash', value: 'Cash' },
//   { label: 'Credit/Debit Card', value: 'Credit/Debit Card' },
//   { label: 'Paytm', value: 'Paytm' },
//   { label: 'PhonePe', value: 'PhonePe' },
//   { label: 'Gpay', value: 'Gpay' },
// ];

// const AllExpenses = () => {
//   const [data, setData] = useState([]);
//   const [typeData, setTypeData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [modeOfPayment, setModeOfPayment] = useState('');
//   const [typeOfExpense, setTypeOfExpense] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [filterData, setFilterData] = useState([])
//   const { fetchAllExpenses, activeBranchId, fetchAllExpenseType } = useMain();
 
//   useEffect(()=>{
//     applyFilters()
//   },[data, modeOfPayment, typeOfExpense, limit, page, search])
//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setModeOfPayment('');
//     setTypeOfExpense('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   // const onApplyHandler = () => {
//   //   if (search) {
//   //     getData();
//   //     setClear(true);
//   //   }
//   // };

//   const getData = async (key) => {
//     let queryParams = `?page=${page}&limit=${limit}${
//       modeOfPayment ? `&modeOfPayment=${modeOfPayment}` : ''
//     }${typeOfExpense ? `&typeOfExpense=${typeOfExpense}` : ''}${search ? `&search=${search}` : ''}`;

//     if (key) queryParams = `?page=${page}&limit=${limit}`;
//     try {
//       setShowLoader(true);
//       const res = await fetchAllExpenses(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//       }
//     } catch (error) {
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId, limit, page, modeOfPayment, typeOfExpense]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const getExpensesTypeData = async () => {
//     try {
//       const res = await fetchAllExpenseType(activeBranchId);
//       if (res.statusCode === 200) {
//         setTypeData(res.data || []);
//       }
//     } catch (error) {}
//   };

//   useEffect(() => {
//     if (activeBranchId) getExpensesTypeData();
//     return () => {
//       setTypeData([]);
//     };
//   }, [activeBranchId]);

//   const applyFilters = () => {
//     let filteredData = data.filter(item => {
//       let isModeMatch = true;
//       let isNameMatch = true;
//       let isTypeMatch = true;

//       if (modeOfPayment) {
//         isModeMatch = item.modeOfPayment === modeOfPayment;
//       }

//       if (typeOfExpense) {
//         isTypeMatch = item?.typeOfExpense === typeOfExpense;
//       }

//       if (search) {
//         // isNameMatch = item.recipientName.toLowerCase().includes(search.toLowerCase());
//         isNameMatch = item?.recipientName?.toLowerCase().includes(search.toLowerCase())
//         || item?.typeOfExpense?.toLowerCase().includes(search.toLowerCase());
//       }

//       return isModeMatch && isNameMatch && isTypeMatch;
//     });

//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };
//   console.log(filterData, "filterDataaaaaaaaaaaaa")

//   return (
//     <PageContainer title="All Expenses" description="this contains all expenses">
//       <DashboardCard2 title="All Expenses">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Type Of Expenses</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={typeOfExpense}
//                   onChange={(e) => setTypeOfExpense(e.target.value)}
//                 >
//                   {typeData.map((type) => (
//                     <MenuItem key={type} value={type}>
//                       {type}
//                     </MenuItem>
//                   ))}
//                   {expensestype.map((type) => (
//                     <MenuItem key={type} value={type}>
//                       {type}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>
//             <Grid item xs={2}>
//               <InputLabel>Mode Of Payment</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={modeOfPayment}
//                 onChange={(e) => setModeOfPayment(e.target.value)}
//               >
//                 {filterModeOfPayment.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>

//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//               {/* <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   background: '#6174DD',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 onClick={onApplyHandler}
//               >
//                 Apply
//               </Button> */}

//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {showLoader ? (
//           <ShowLoader />
//         ) :filterData && filterData?.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <Expenses data={filterData} setData={setData} getExpenses={getData} />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             // count={data.length < limit ? page : page + 1}
//             count={filterData && filterData.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllExpenses;

//new filter code
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { MenuItem, Select, Box, Button, Grid, InputLabel, Pagination } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SearchImg from '../../assets/search.svg';
// import Expenses from './Expenses';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import BigFilter from 'src/components/inputs/BigFilter';

// const expensestype = ['Salary', 'Others'];

// const filterModeOfPayment = [
//     { label: 'Cash', value: 'Cash' },
//     { label: 'Credit/Debit Card', value: 'Credit/Debit Card' },
//     { label: 'Paytm', value: 'Paytm' },
//     { label: 'PhonePe', value: 'PhonePe' },
//     { label: 'Gpay', value: 'Gpay' },
// ];

// const AllExpenses = () => {
//     const { fetchAllExpenses, activeBranchId, fetchAllExpenseType } = useMain();

//     const [data, setData] = useState([]);
//     const [typeData, setTypeData] = useState([]);
//     const [showLoader, setShowLoader] = useState(true);
//     const [search, setSearch] = useState('');
//     const [filterSelection, setFilterSelection] = useState([]);
//     const [limit, setLimit] = useState(10);
//     const [page, setPage] = useState(1);
//     const [filteredData, setFilteredData] = useState([]);

//     const clearDataHandler = () => {
//         setLimit(10);
//         setPage(1);
//         setSearch('');
//         setFilterSelection([]); // Clear the filters
//         setFilteredData(data); // Reset the filtered data to the original data
//     };

//     const getData = async () => {
//         let queryParams = `?page=${page}&limit=${limit}${filterSelection.length ? `&filterSelection=${filterSelection.join(',')}` : ''}${search ? `&search=${search}` : ''}`;

//         try {
//             setShowLoader(true);
//             const res = await fetchAllExpenses(activeBranchId, queryParams);
//             if (res.statusCode === 200) {
//                 setData(res?.data || []);
//             }
//         } catch (error) {
//         } finally {
//             setShowLoader(false);
//         }
//     };

//     useEffect(() => {
//         if (activeBranchId) {
//             getData();
//         }
//         return () => {
//             setData([]);
//         };
//     }, [activeBranchId, limit, page, filterSelection, search]);

//     const handleLimitChange = (e) => {
//         setLimit(e.target.value);
//         setPage(1);
//     };

//     const getExpensesTypeData = async () => {
//         try {
//             const res = await fetchAllExpenseType(activeBranchId);
//             if (res.statusCode === 200) {
//                 setTypeData(res.data || []);
//             }
//         } catch (error) {
//             setTypeData([]);
//         }
//     };

//     useEffect(() => {
//         if (activeBranchId) {
//             getExpensesTypeData();
//         }
//         return () => {
//             setTypeData([]);
//         };
//     }, [activeBranchId]);

//     // const applyFilters = () => {
//     //     let filteredData = data.filter(item => {
//     //         let isFilterMatch = filterSelection.length === 0 || filterSelection.includes(item.modeOfPayment) || filterSelection.includes(item?.typeOfExpense);
//     //         let isNameMatch = search ? item?.recipientName?.toLowerCase().includes(search.toLowerCase()) || item?.typeOfExpense?.toLowerCase().includes(search.toLowerCase()) : true;

//     //         return isFilterMatch && isNameMatch;
//     //     });

//     //     setFilteredData(filteredData);
//     // };

//     //*filter correction 
//     const applyFilters = () => {
//         let filteredData = data.filter(item => {
//             // Check if Mode of Payment is selected and matches
//             let isModeOfPaymentMatch = filterSelection.some(filter => filterModeOfPayment.find(option => option.value === filter)?.value === item.modeOfPayment);

//             // Check if Type of Expense is selected and matches
//             let isTypeOfExpenseMatch = filterSelection.some(filter => typeData.includes(filter) && filter === item?.typeOfExpense);

//             // If both filters are applied, check that both match
//             let isFilterMatch =
//                 (filterSelection.length === 0) ||
//                 (isModeOfPaymentMatch && isTypeOfExpenseMatch) ||
//                 (isModeOfPaymentMatch && !filterSelection.some(filter => typeData.includes(filter))) ||
//                 (isTypeOfExpenseMatch && !filterSelection.some(filter => filterModeOfPayment.find(option => option.value === filter)));

//             // Check if the search matches
//             let isNameMatch = search
//                 ? item?.recipientName?.toLowerCase().includes(search.toLowerCase()) ||
//                 item?.typeOfExpense?.toLowerCase().includes(search.toLowerCase())
//                 : true;

//             return isFilterMatch && isNameMatch;
//         });

//         setFilteredData(filteredData);
//     };


//     useEffect(() => {
//         applyFilters();
//     }, [limit, page, filterSelection, search, data]);

//     const paginatedData = filteredData.slice((page - 1) * limit, page * limit);

//     const groupedOptions = [
//         {
//             title: 'Mode of Payment',
//             items: filterModeOfPayment.map(item => ({ label: item.label, value: item.value }))
//         },
//         {
//             title: 'Type of Expense',
//             items: typeData.map(type => ({ label: type, value: type }))
//         }
//     ];

//     return (
//         <PageContainer title="All Expenses" description="this contains all expenses">
//             <DashboardCard2 title="All Expenses">
//                 <Grid container style={{ gap: '10px' }}>
//                     <Grid container justifyContent="space-between">
//                         <Grid item xs={2}>
//                             <InputLabel>Show per page</InputLabel>
//                             <Select
//                                 style={{
//                                     borderRadius: '7px',
//                                     border: '0.5px solid #6174DD',
//                                     padding: '4px 12px',
//                                     background: '#FFF',
//                                     height: '40px',
//                                 }}
//                                 value={limit}
//                                 onChange={handleLimitChange}
//                             >
//                                 <MenuItem value={10}>10</MenuItem>
//                                 <MenuItem value={20}>20</MenuItem>
//                                 <MenuItem value={50}>50</MenuItem>
//                             </Select>
//                         </Grid>
//                         <Grid item xs={12} sm={6} md={3}>
//                             {/* <InputLabel>Filter</InputLabel> */}
//                             <BigFilter
//                                 options={groupedOptions}
//                                 value={filterSelection}
//                                 onChange={(newValues) => setFilterSelection(newValues)}
//                                 placeholder="Filters"
//                             />
//                         </Grid>
//                         <Grid item xs={2}>
//                             <InputLabel>Search</InputLabel>
//                             <div className="search-input">
//                                 <img src={SearchImg} alt="" />
//                                 <input
//                                     type="text"
//                                     placeholder=""
//                                     value={search}
//                                     onChange={(e) => setSearch(e.target.value)}
//                                 />
//                             </div>
//                         </Grid>
//                         <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
//                             <Button
//                                 variant="contained"
//                                 color="secondary"
//                                 onClick={clearDataHandler}
//                                 // style={{ width: '100%', backgroundColor: 'red', color: 'white', marginTop: '1.5rem' }}
//                                 style={{ width: '100%', backgroundColor: '#ff4d4d', color: 'white', marginTop: '1.5rem', padding: '0.5rem' }}
//                             >
//                                 Clear
//                             </Button>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 {showLoader ? (
//                     <ShowLoader />
//                 ) : filteredData && filteredData.length > 0 ? (
//                     <>
//                         <Box>
//                             <Grid container spacing={3}>
//                                 <Grid item xs={12} lg={12}>
//                                     <Expenses data={paginatedData} setData={setData} getExpenses={getData} />
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                         <Box
//                             display="flex"
//                             // justifyContent="center"
//                             justifyContent="end"
//                             marginTop="20px"
//                         >
//                             <Pagination
//                                 style={{ justifyContent: "end" }}
//                                 count={Math.ceil(filteredData.length / limit)}
//                                 page={page}
//                                 onChange={(event, value) => setPage(value)}
//                                 color="primary"
//                                 // shape="rounded"
//                                 shape="circle"
//                                 justifyContent="end"
//                             />
//                         </Box>
//                     </>
//                 ) : (
//                     <Box
//                         display="flex"
//                         justifyContent="center"
//                         alignItems="center"
//                         height="200px"
//                         marginTop="20px"
//                         flexDirection="column"
//                     >
//                         <p>No expenses found.</p>
//                     </Box>
//                 )
//                 }
//             </DashboardCard2 >
//         </PageContainer >
//     );
// };

// export default AllExpenses;


//pagination corrected
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { MenuItem, Select, Box, Button, Grid, InputLabel, Pagination } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SearchImg from '../../assets/search.svg';
import Expenses from './Expenses';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import BigFilter from 'src/components/inputs/BigFilter';

const expensestype = ['Salary', 'Others'];

const filterModeOfPayment = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Credit/Debit Card', value: 'Credit/Debit Card' },
    { label: 'Paytm', value: 'Paytm' },
    { label: 'PhonePe', value: 'PhonePe' },
    { label: 'Gpay', value: 'Gpay' },
];

const AllExpenses = () => {
    const { fetchAllExpenses, activeBranchId, fetchAllExpenseType } = useMain();

    const [data, setData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [search, setSearch] = useState('');
    const [filterSelection, setFilterSelection] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);

    const clearDataHandler = () => {
        setLimit(10);
        setPage(1);
        setSearch('');
        setFilterSelection([]); // Clear the filters
        setFilteredData(data); // Reset the filtered data to the original data
    };

    const getData = async () => {
        try {
            setShowLoader(true);
            const res = await fetchAllExpenses(activeBranchId);
            if (res.statusCode === 200) {
                setData(res?.data || []);
                setFilteredData(res?.data || []); // Initialize filtered data
            }
        } catch (error) {
            // Handle error if necessary
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        if (activeBranchId) {
            getData();
        }
        return () => {
            setData([]);
            setFilteredData([]);
        };
    }, [activeBranchId]);

    const handleLimitChange = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    const getExpensesTypeData = async () => {
        try {
            const res = await fetchAllExpenseType(activeBranchId);
            if (res.statusCode === 200) {
                setTypeData(res.data || []);
            }
        } catch (error) {
            setTypeData([]);
        }
    };

    useEffect(() => {
        if (activeBranchId) {
            getExpensesTypeData();
        }
        return () => {
            setTypeData([]);
        };
    }, [activeBranchId]);

    const applyFilters = () => {
        let filteredData = data.filter(item => {
            let isModeOfPaymentMatch = filterSelection.some(filter => filterModeOfPayment.find(option => option.value === filter)?.value === item.modeOfPayment);
            let isTypeOfExpenseMatch = filterSelection.some(filter => typeData.includes(filter) && filter === item?.typeOfExpense);
            let isFilterMatch =
                (filterSelection.length === 0) ||
                (isModeOfPaymentMatch && isTypeOfExpenseMatch) ||
                (isModeOfPaymentMatch && !filterSelection.some(filter => typeData.includes(filter))) ||
                (isTypeOfExpenseMatch && !filterSelection.some(filter => filterModeOfPayment.find(option => option.value === filter)));
            let isNameMatch = search
                ? item?.recipientName?.toLowerCase().includes(search.toLowerCase()) ||
                item?.typeOfExpense?.toLowerCase().includes(search.toLowerCase())
                : true;

            return isFilterMatch && isNameMatch;
        });

        setFilteredData(filteredData);
    };

    useEffect(() => {
        applyFilters();
    }, [search, filterSelection, limit, page, data]);

    const paginatedData = filteredData.slice((page - 1) * limit, page * limit);

    const groupedOptions = [
        {
            title: 'Mode of Payment',
            items: filterModeOfPayment.map(item => ({ label: item.label, value: item.value }))
        },
        {
            title: 'Type of Expense',
            items: typeData.map(type => ({ label: type, value: type }))
        }
    ];

    return (
        <PageContainer title="All Expenses" description="This contains all expenses">
            <DashboardCard2 title="All Expenses">
                <Grid container style={{ gap: '10px' }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={2}>
                            <InputLabel>Show per page</InputLabel>
                            <Select
                                style={{
                                    borderRadius: '7px',
                                    border: '0.5px solid #6174DD',
                                    padding: '4px 12px',
                                    background: '#FFF',
                                    height: '40px',
                                }}
                                value={limit}
                                onChange={handleLimitChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <BigFilter
                                options={groupedOptions}
                                value={filterSelection}
                                onChange={(newValues) => setFilterSelection(newValues)}
                                placeholder="Filters"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabel>Search</InputLabel>
                            <div className="search-input">
                                <img src={SearchImg} alt="" />
                                <input
                                    type="text"
                                    placeholder=""
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={clearDataHandler}
                                style={{ width: '100%', backgroundColor: '#ff4d4d', color: 'white', marginTop: '1.5rem', padding: '0.5rem' }}
                            >
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {showLoader ? (
                    <ShowLoader />
                ) : filteredData && filteredData.length > 0 ? (
                    <>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <Expenses data={paginatedData} setData={setData} getExpenses={getData} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            marginTop="20px"
                        >
                            <Pagination
                                style={{ justifyContent: "end" }}
                                count={Math.ceil(filteredData.length / limit)}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                color="primary"
                                shape="circle"
                            />
                        </Box>
                    </>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="start"
                        height="200px"
                        marginTop="20px"
                        flexDirection="column"
                    >
                        <p>No expenses found.</p>
                    </Box>
                )}
            </DashboardCard2 >
        </PageContainer >
    );
};

export default AllExpenses;