import React, { useState, useEffect } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, Select, Typography,
  Grid as MuiGrid, Pagination
} from '@mui/material';
import TableCells from 'src/components/Tables/TableCells';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const PackageAllBill = ({ data, setData, getData }) => {
  const [packagedata, setPackageData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const { activeBranchId, setShowMessage, fetchPackageBills, branchData, deletePackagebill } = useMain();
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDateRangeSelected = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setIsModalOpen(false);
    const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}${start ? `&startDate=${start}` : ''}${end ? `&endDate=${end}` : ''}`;
    getPackageBillsData(queryParams);
    setClear(true);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    setEndDate('');
    setStartDate('');
    if (clear) {
      const queryParams = `?page=1&limit=10`;
      getPackageBillsData(queryParams);
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
    getPackageBillsData(queryParams);
    setClear(true);
  };

  useEffect(() => {
    if (activeBranchId) {
      const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}`;
      getPackageBillsData(queryParams);
      setClear(true);
    }
    return () => {
      setPackageData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters(packagedata, limit, genderBy, page, search, startDate, endDate);
  }, [packagedata, limit, genderBy, page, search, startDate, endDate]);

  const getPackageBillsData = async (queryParams) => {
    try {
      setShowLoader(true);
      const res = await fetchPackageBills(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setPackageData(res?.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const applyFilters = (data, limit, gender, page, search, startDate, endDate) => {
    let filteredData = data.filter(item => {
      let isGenderMatch = true;
      let isNameMatch = true;

      if (gender) {
        isGenderMatch = item.gender === gender;
      }

      if (search) {
        isNameMatch = item.clientName.toLowerCase().includes(search.toLowerCase()) ||
          item.clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const itemDate = new Date(item.dateOfBilling);
        const start = new Date(startDate);
        const end = new Date(endDate);
        isNameMatch = isNameMatch && itemDate >= start && itemDate <= end;
      }

      return isGenderMatch && isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  // new code 
  const handleDeleteField = async (id) => {
    try {
      const res = await deletePackagebill(id, activeBranchId);

      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Bill deleted successfully',
          messageType: 'success',
        });
        const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
        getPackageBillsData(queryParams);
      } else {
        setShowMessage({
          message: res.message || 'Bill not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    }
  };

  return (
    <PageContainer title="All Package Bills" description="this contains all products">
      <DashboardCard2 title="All Package Bills">

        <MuiGrid container spacing={2} alignItems="center">
          <MuiGrid container justifyContent="space-around">
            <MuiGrid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                {[10, 20, 50].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </MuiGrid>

            <MuiGrid item xs={2}>
              <InputLabel>Date Range</InputLabel>
              <Button
                variant="outlined"
                sx={{ color: 'black', height: '40px', width: '100%' }}
                onClick={openModal}
              // style={{ marginTop: '0.5rem' }}
              >
                {startDate && endDate ? `${startDate} to ${endDate}` : 'Choose Date'}
              </Button>
            </MuiGrid>

            <MuiGrid item xs={2}>
              <InputLabel>Gender</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '100%',
                }}
                value={genderBy}
                onChange={(e) => setGenderBy(e.target.value)}
              >
                {filterGenderWise.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </MuiGrid>

            <MuiGrid item xs={2}>
              <InputLabel>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                <img src={SearchImg} alt="search icon" style={{ marginRight: '8px' }} />
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ border: 'none', outline: 'none', flex: 1 }}
                />
              </div>
            </MuiGrid>

            <MuiGrid item xs={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  width: '100%',
                  background: '#ff4d4d',
                  '&:hover': { background: '#ff6666' },
                  marginTop: '0.5rem',
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>

        {showLoader ? (
          <ShowLoader />
        ) : filterData.length > 0 ? (
          <Box>
            <MuiGrid container spacing={3}>
              <MuiGrid item xs={12}>
                <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
                  <table className="table-container mt-3">
                    <thead className="table-thead">
                      <tr>
                        {['Date of Bill', 'Client Name', 'Contact', 'Gender', 'Paid', 'Mode of Payment', 'Actions'].map((h, index) => (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="table-tbody">
                      {filterData.map((item) => (
                        <tr key={item._id}>
                          <td style={{ width: '100%' }}>{item?.dateOfBilling}</td>
                          <td style={{ width: '100%' }}>{item?.clientName}</td>
                          <td style={{ width: '100%' }}>{maskData(item?.clientNumber, branchData?.isMasked)}</td>
                          <td style={{ width: '100%' }}>{item?.gender}</td>
                          <td style={{ width: '100%' }}>
                            {item?.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0)}
                          </td>
                          <td style={{ width: '100%' }}>
                            {item?.amountPaid?.map(({ paymentType }) => paymentType).join(',  ')}
                          </td>
                          <td style={{ width: '100%' }}><TableCells
                            type="button"
                            field={item?._id}
                            handleDeleteField={handleDeleteField}
                          /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </MuiGrid>
            </MuiGrid>
          </Box>
        ) : (
          'No data found'
        )}

        <Box display="flex" justifyContent="flex-end">
          <Pagination
            count={Math.ceil(packagedata.length / limit)}
            page={page}
            color="primary"
            onChange={handlePageChange}
          />
        </Box>

        <DateRangeInputs
          isOpen={isModalOpen}
          onClose={closeModal}
          onDateRangeSelected={handleDateRangeSelected}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
        />
      </DashboardCard2>
    </PageContainer>
  );
};

export default PackageAllBill;