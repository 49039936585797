import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { ReqField } from '../CustomComponents/CustomLoader';

const RadioInputs = ({
  title,
  name,
  value,
  handleChange,
  options,
  required,
  disabled,
  fieldValidation,
}) => {
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={600} component="label" htmlFor={name} mb="5px">
        {title} {required !== false ? <ReqField /> : <br />}
      </Typography>
      <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
        <Grid container direction={'row'} spacing={2}>
          {options?.map((item, index) => (
            <Grid item key={index}>
              <FormControlLabel
                value={item.value}
                control={<Radio />}
                label={item.title}
                disabled={disabled ? true : false}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
      {!!fieldValidation && <Typography color="error" fontSize='12px' fontWeight={100} ml='15px'>{`${title} is required`}</Typography>}
    </Box>
  );
};

export default RadioInputs;
