import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, Button } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import EditIcon from '@mui/icons-material/Edit';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';

const ClientLeads = ({ checkedClients }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const handleAllCheckboxChange = () => {};
  const handleSignleCheckboxChange = () => {};
  const editButtonStyle = {
    backgroundColor: '#1976D2',
    color: '#fff',
    marginRight: '8px',
    borderRadius: '5px',
    border: 'none',

    '&:hover': {
      backgroundColor: '#1565C0',
    },
  };

  const deleteButtonStyle = {
    backgroundColor: '#E53935',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    '&:hover': {
      backgroundColor: '#C62828',
    },
  };
  return (
    <PageContainer title="Client History" description="This contains all products">
      <DashboardCard2 title="Client Leads Management">
        {/* <CardContent sx={{ textAlign: 'center', fontSize: '16px' }}>
          <h2>Client Lead Management</h2>
        </CardContent> */}

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Box>
              {/* <InputLabel>Show per page</InputLabel> */}
              {/* <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '100px',
                }}

                // onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select> */}
            </Box>
          </Grid>
          {/* <Grid item xs={6} container justifyContent="flex-end">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0px 5px 0px 10px',
                  minWidth: '56px',
                }}
              >
                <span>Sort By : </span>
              </div>
              <div>
                <Box>
                  <Select
                    style={{
                      borderRadius: '7px',
                      border: '0.5px solid #6174DD',
                      padding: '4px 12px',
                      background: '#FFF',
                      height: '40px',
                    }}
                    // value={sortBy}
                    // onChange={handleFilter}
                  >
                    <MenuItem value="createdAt">Newest</MenuItem>
                  </Select>
                </Box>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0px 5px 0px 10px',
                  minWidth: '74px',
                }}
              >
                <span>Search By : </span>
              </div>
              <div>
                <Box>
                  <Select
                    style={{
                      borderRadius: '7px',
                      border: '0.5px solid #6174DD',
                      padding: '4px 12px',
                      background: '#FFF',
                      height: '40px',
                      minWidth: '190px',
                    }}
                    value={''}
                    // onChange={handleSearchKey}
                  ></Select>
                </Box>
              </div>
              <div className="search-input">
                <img src={''} alt="" />
                <input
                  type="text"
                  placeholder={''}
                  value={''}
                  //   onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div>
                <Button
                  disabled={''}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  //   onClick={onApplyHandler}
                >
                  Apply
                </Button>
              </div>
              <div>
                <Button
                  disabled={''}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#ff4d4d',
                    '&:hover': {
                      background: '#ff6666',
                    },
                  }}
                  //   onClick={clearDataHandler}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={12} container justifyContent="flex-start">
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              sx={{
                background: '#25D366',
                marginRight: '20px',
                float: 'left',
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              WhatsaApp
            </Button>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              sx={{
                background: '#25D366',
                marginRight: '20px',
                float: 'left',
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              IVR
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
          <table className="table-container mt-3">
            <thead className="table-thead">
              <tr>
                <th style={{ width: '30%' }}>
                  <CheckboxInputs
                    type={'Heading'}
                    isAllChecked={isAllChecked}
                    handleAllCheckboxChange={handleAllCheckboxChange}
                  />
                </th>
                {[
                  'Name',
                  'Contact Number',
                  'Gender',
                  'Email',
                  'Birthday',
                  'Anniversary',
                  'First Visit',
                  'Actions',
                ].map((h, index) => {
                  return (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="table-tbody">
              <tr>
                <td style={{ width: '30%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedClients}
                  />
                </td>
                <td style={{ width: '100%' }}>{'clientName'}</td>
                <td style={{ width: '100%' }}>{'clientNumber'}</td>
                <td style={{ width: '100%' }}>{'gender'}</td>
                <td style={{ width: '100%' }}>{'clientEmail'}</td>
                <td style={{ width: '100%' }}>{'birthday'}</td>
                <td style={{ width: '100%' }}>{'anniversary'}</td>
                <td style={{ width: '100%' }}></td>
                <td style={{ width: '100%' }}>
                  <button variant="contained" style={editButtonStyle}>
                    {<EditIcon />}
                    Edit
                  </button>

                  <button variant="contained" style={deleteButtonStyle}>
                    {<DeleteIcon />}
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default ClientLeads;
