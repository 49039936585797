import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useMain } from '../../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import './RemainingAmountCard.css';
const initialFormValidation = {
  paymentTypeValidation: false,
};

const RemainingAmountCard = ({
  data,
  clientNumber,
  setClientMembership,
  setSelectedClient,
  setClientCoupons,
  allCouponsData,
  selectedClient,
}) => {
  const { clearRemainingAmount, activeBranchId, fetchClientByNumber, setShowMessage } = useMain();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [ContinueBill, setContinueBill] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [remainingAmountBillLoading, setRemainingAmountBillLoading] = useState(false);
  const [formValidation, setFormValidation] = useState(initialFormValidation);

  const [formData, setFormData] = useState({
    amount: '',
    paymentType: '',
  });

  const paymentTypeValidation = !formData.paymentType;

  const handleOpenDialogue = (bill) => {
    setContinueBill(bill);
    setFormData((pre) => ({ ...pre, amount: bill.newPaidDues }));
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setContinueBill({});
    setFormValidation((pre) => ({ ...pre, paymentTypeValidation: false }));
    setFormData((pre) => ({ ...pre, amount: '', paymentType: '' }));
    setOpenDialogue(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const handlePaymentSubmit = async () => {
    const { paymentType } = formData;

    // Check if paymentType is Wallet and advanceGiven is less than newPaidDues
    if (paymentType === 'Wallet' && selectedClient?.advancedGiven < ContinueBill?.newPaidDues) {
      setShowMessage({
        message: 'Insufficient wallet balance to pay the dues.',
        messageType: 'error',
      });
      return;
    }
    
    const validation = {
      paymentTypeValidation: !paymentType,
    };
    
    setFormValidation((pre) => ({ ...pre, ...validation }));
    
    if (Object.values(validation).some((item) => item)) return;
    
    setPaymentLoading(true);
    
    try {
      const res = await clearRemainingAmount(ContinueBill.billId, `?branchId=${activeBranchId}`, {
        remainingAmount: formData,
      });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Payment Status Success',
          messageType: 'success',
        });
        handleCloseDialogue();
        getClinet(clientNumber);
      } else {
        setShowMessage({
          message: res.message || 'Payment Status Failed',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: 'Something went wrong please after some time',
        messageType: 'error',
      });
    } finally {
      setPaymentLoading(false);
    }
  }

  const getClinet = async (clientNumber) => {
    setRemainingAmountBillLoading(true);
    try {
      const res = await fetchClientByNumber(clientNumber, activeBranchId);
      if (res.statusCode === 200) {
        const client = res?.data;
        setSelectedClient(client);
        setClientMembership(checkMembershipValidation(client.membershipDetails));
        setClientCoupons(filterClinetsCoupons(allCouponsData, res?.data?.coupons));
      } else {
      }
    } catch (error) {
    } finally {
      setRemainingAmountBillLoading(false);
    }
  };

  // Compare newPaidDues with selectedClient?.advancedGiven
  const isWalletDisabled = formData.paymentType === 'Wallet' && selectedClient?.advancedGiven < ContinueBill?.newPaidDues;

  return (
    <div className="container">
      {remainingAmountBillLoading ? (
        <ShowLoader />
      ) : (
        <table className="table-container mt-3">
          <thead className="table-thead">
            <tr>
              {['Date of Bill', 'Total', 'Paid', 'Pending', ''].map((h, index) => {
                return (
                  <th key={index} style={{ width: '100%' }}>
                    {h}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="table-tbody">
            {data.map((bill) => (
              <tr key={bill.billId}>
                <td style={{ width: '100%' }}>{bill?.dateOfBilling}</td>
                <td style={{ width: '100%' }}>{bill?.payableAmount}</td>
                <td style={{ width: '100%' }}>{bill?.amountPaid}</td>
                <td style={{ width: '100%', color: 'red' }}>{bill?.newPaidDues}</td>
                <td
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <button
                    style={{
                      background: '#6174DD',
                      color: 'white',
                      padding: '4px 16px',
                      border: 'none',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontSize: '17px',
                      transition: 'background-color 0.3s ease',
                    }}
                    onClick={() => handleOpenDialogue(bill)}
                  >
                    Pay
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Continue to Pay</DialogTitle>
        <DialogContent>
          <div className="container2">
            {paymentLoading ? (
              <ShowLoader />
            ) : (
              <div className="paymentBox">
                <div>
                  <label htmlFor="amount">Amount: </label>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    value={formData?.amount}
                    disabled
                  />
                </div>
                <div>
                  <label htmlFor="paymentType">Payment Method: </label>
                  <select
                    className={`paymentTypeSelect ${formValidation.paymentTypeValidation && paymentTypeValidation ? 'border border-danger' : ''}`}
                    id="paymentType"
                    name="paymentType"
                    onChange={handleChange}
                    value={formData.paymentType}
                  >
                    <option value="" disabled>
                      --Select--
                    </option>
                    <option value="Cash">Cash</option>
                    <option value="Credit/Debit Card">Credit/Debit card</option>
                    <option value="Paytm">Paytm</option>
                    <option value="PhonePe">PhonePe</option>
                    <option value="Gpay">Gpay</option>
                    <option value="Wallet">Wallet</option>
                  </select>
                  {formValidation.paymentTypeValidation && paymentTypeValidation && (
                    <div className="text-danger">Please select a payment method</div>
                  )}
                  {isWalletDisabled && (
                    <div className="text-danger">Your wallet amount is insufficient,<br></br> please select another method</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}
        >
          <Button
            variant="contained"
            sx={{
              width: '14%',
            }}
            onClick={handleCloseDialogue}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '18%',
              background: '#6174DD',
              cursor: isWalletDisabled ? 'not-allowed' : 'pointer', // Add this line
              opacity: isWalletDisabled ? 0.5 : 1, // Add this line
            }}
            onClick={handlePaymentSubmit}
            disabled={isWalletDisabled}
          >
            Pay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemainingAmountCard;

function isValidMembershipDetails(membershipDetails) {
  // Check if membershipDetails is an object and has the required properties
  if (
    typeof membershipDetails === 'object' &&
    membershipDetails.startDate &&
    membershipDetails.endDate
  ) {
    const startDate = new Date(membershipDetails.startDate);
    const endDate = new Date(membershipDetails.endDate);
    const currentDate = new Date();

    // Check if startDate and endDate are valid Date objects and if the current date is within the range
    if (
      !isNaN(startDate.getTime()) &&
      !isNaN(endDate.getTime()) &&
      currentDate >= startDate &&
      currentDate <= endDate
    ) {
      return true;
    }
  }

  return false;
}

function checkMembershipValidation(membershipDetails) {
  if (isValidMembershipDetails(membershipDetails)) {
    // Valid membership details, so you can use them
    return membershipDetails;
  } else {
    // Membership details are not valid, return an empty object
    return {};
  }
}

function filterClinetsCoupons(coupons, clientCoupons) {
  // Create an object to keep track of how many times each coupon has been used
  const couponUsage = clientCoupons.reduce((acc, couponId) => {
    acc[couponId] = (acc[couponId] || 0) + 1;
    return acc;
  }, {});

  // Filter coupons based on couponPerUser and usage
  return coupons.filter((coupon) => {
    const usedCount = couponUsage[coupon._id] || 0;
    return coupon.couponPerUser > usedCount;
  });
}
