// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import Appointments from './Appointments';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import SearchImg from '../../assets/search.svg';

// const filterMenu = [
//   { value: 'Walk In', label: 'Walk In' },
//   { value: 'Existing', label: 'Existing' },
//   { value: 'Phone Call', label: 'Phone Call' },
//   { value: 'Facebook', label: 'Facebook' },
//   { value: 'Web Appointment', label: 'Web Appointment' },
// ];

// const filterStatusMenu = [
//   { value: 'RESERVED', label: 'RESERVED' },
//   { value: 'COMPLETE', label: 'COMPLETE' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllAppointments = () => {
//   const [data, setData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [sourceOfAppointment, setSourceOfAppointment] = useState('');
//   const [appointmentStatus, setAppointmentStatus] = useState('');
//   const [clear, setClear] = useState(false);

//   const [filterData, setFilterData] = useState(null)
//   console.log(filterData);

//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);

//   const { fetchAllAppointments, activeBranchId } = useMain();

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSourceOfAppointment('');
//     setAppointmentStatus('');
//     setGenderBy('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     let queryParams = `?page=${page}&limit=${limit}${
//       sourceOfAppointment ? `&sourceOfAppointment=${sourceOfAppointment}` : ''
//     }${appointmentStatus ? `&appointmentStatus=${appointmentStatus}` : ''}${
//       genderBy ? `&gender=${genderBy}` : ''
//     }${search ? `&search=${search}` : ''}`;

//     if (key) queryParams = `?page=${page}&limit=${limit}`;
//     try {
//       setShowLoader(true);
//       const res = await fetchAllAppointments(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//       }
//     } catch (error) {
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     applyFilters()
//   }, [limit, page, sourceOfAppointment, appointmentStatus, genderBy, data, search]);

//   const applyFilters = () => {

//     console.log(data);
//     console.log(search);
//     let filteredData = data.filter(item => {
//       let isGenderMatch = true;
//       let isSourceMatch = true;
//       let isStatusMatch = true;
//       let isNameMatch = true;

//       if (genderBy) {
//         isGenderMatch = item.gender === genderBy;
//       }

//       if (sourceOfAppointment) {
//         isSourceMatch = item.sourceOfAppointment == sourceOfAppointment;
//       }

//       if (appointmentStatus) {
//         isStatusMatch = item.appointmentStatus === appointmentStatus;
//       }

//       if (search) {
//         isNameMatch = item.clientName.toLowerCase().includes(search.toLowerCase()) ||
//         item.clientNumber.includes(search) 
//       }

//       return isGenderMatch && isSourceMatch && isStatusMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData)
//   };
//   console.log(filterData,"appDataaaaaa")
//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="Appointments History" description="this contains all Appointments">
//       <DashboardCard2 title="Appointments History">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={1.8}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={1.8}>
//               <InputLabel>Appointment Status</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={appointmentStatus}
//                 onChange={(e) => setAppointmentStatus(e.target.value)}
//               >
//                 {filterStatusMenu.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.8}>
//               <InputLabel>Source Of Appointment</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={sourceOfAppointment}
//                 onChange={(e) => setSourceOfAppointment(e.target.value)}
//               >
//                 {filterMenu.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.8}>
//               <div>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={genderBy}
//                   onChange={(e) => setGenderBy(e.target.value)}
//                 >
//                   {filterGenderWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>
//             <Grid item xs={1.8}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={1.8} display="flex" gap="20px" alignItems="flex-end">
//               {/* <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   background: '#6174DD',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 onClick={onApplyHandler}
//               >
//                 Apply
//               </Button> */}

//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>

//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData && filterData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <Appointments data={filterData} getData={getData} setData={setData} />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             // count={data.length < limit ? page : page + 1}
//             count={ filterData && filterData?.length < limit ? page  : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllAppointments;

//new filter code
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import Appointments from './Appointments';
import { MenuItem, Select, InputLabel, Box, Button, Grid, Pagination } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import BigFilter from 'src/components/inputs/BigFilter';

const filterMenu = [
  { value: 'Walk In', label: 'Walk In' },
  { value: 'Existing', label: 'Existing' },
  { value: 'Phone Call', label: 'Phone Call' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Web Appointment', label: 'Web Appointment' },
];

const filterStatusMenu = [
  { value: 'RESERVED', label: 'RESERVED' },
  { value: 'COMPLETE', label: 'COMPLETE' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllAppointments = () => {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState([]);
  const [sourceOfAppointment, setSourceOfAppointment] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);
  const [clear, setClear] = useState(false);

  const [filterData, setFilterData] = useState(null);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const { fetchAllAppointments, activeBranchId } = useMain();

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setSourceOfAppointment([]);
    setAppointmentStatus([]);
    setGenderBy([]);
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    let queryParams = `?page=${page}&limit=${limit}${sourceOfAppointment.length ? `&sourceOfAppointment=${sourceOfAppointment.join(',')}` : ''
      }${appointmentStatus.length ? `&appointmentStatus=${appointmentStatus.join(',')}` : ''}${genderBy.length ? `&gender=${genderBy.join(',')}` : ''
      }${search ? `&search=${search}` : ''}`;

    if (key) queryParams = `?page=${page}&limit=${limit}`;
    try {
      setShowLoader(true);
      const res = await fetchAllAppointments(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      setClear(true);
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters()
  }, [limit, page, sourceOfAppointment, appointmentStatus, genderBy, data, search]);

  const applyFilters = () => {
    let filteredData = data.filter(item => {
      let isGenderMatch = true;
      let isSourceMatch = true;
      let isStatusMatch = true;
      let isNameMatch = true;

      if (genderBy.length) {
        isGenderMatch = genderBy.includes(item.gender);
      }

      if (sourceOfAppointment.length) {
        isSourceMatch = sourceOfAppointment.includes(item.sourceOfAppointment);
      }

      if (appointmentStatus.length) {
        isStatusMatch = appointmentStatus.includes(item.appointmentStatus);
      }

      if (search) {
        isNameMatch = item.clientName.toLowerCase().includes(search.toLowerCase()) ||
          item.clientNumber.includes(search);
      }

      return isGenderMatch && isSourceMatch && isStatusMatch && isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <PageContainer title="Appointments History" description="this contains all products">
      <DashboardCard2 title="Appointments History">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {/* <InputLabel>Filters</InputLabel> */}
              <BigFilter
                options={[
                  { title: 'Source of Appointment', items: filterMenu },
                  { title: 'Appointment Status', items: filterStatusMenu },
                  { title: 'Gender', items: filterGenderWise }
                ]}
                value={[...sourceOfAppointment, ...appointmentStatus, ...genderBy]}
                onChange={(selectedValues) => {
                  setSourceOfAppointment(selectedValues.filter(val => filterMenu.some(opt => opt.value === val)));
                  setAppointmentStatus(selectedValues.filter(val => filterStatusMenu.some(opt => opt.value === val)));
                  setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
                }}
                placeholder="Filters"
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel>Search</InputLabel>
              <div className="search-input">
                <img src={SearchImg} alt="" />
                <input
                  type="text"
                  placeholder=""
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={clearDataHandler}
                // style={{ width: '100%', backgroundColor: 'red', color: 'white', marginTop: '1.5rem' }}
                style={{ width: '100%', backgroundColor: '#ff4d4d', color: 'white', marginTop: '1.5rem', padding: '0.5rem' }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {showLoader ? (
          <ShowLoader />
        ) : filterData && filterData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Appointments data={filterData} getData={getData} setData={setData} />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={filterData && filterData?.length < limit ? page : page + 1}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllAppointments;