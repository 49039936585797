import { baseUrl, headers, jwtHeaders, logoutUtil } from '../common/data';

export const fetchAllBranch = async (ownerId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/branch/getallbranches/${ownerId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      // headers,
      headers:jwtHeaders(),
    },
  );

  const resData = await response?.json();

  return resData;
};

export const fetchParentBranch = async (id) => {
  const response = await fetch(`${baseUrl}/api/owner/getparentbranch/${id}`, {
    method: 'GET',
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};

export const createBranch = async (data) => {
  const response = await fetch(`${baseUrl}/api/branch/createbranch`, {
    method: 'POST',
    body: JSON.stringify(data),
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const getBranch = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/branch/getsinglebranch/${branchId}`, {
    method: 'GET',
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const deleteBranch = async (data) => {
  const response = await fetch(`${baseUrl}/api/branch/deletebranch/${data}`, {
    method: 'DELETE',
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const updateBranch = async (data, id) => {
  const response = await fetch(`${baseUrl}/api/branch/updatebranch/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = response?.json();

  return resData;
};

export const getFeedbackUrl = async (billId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/branch/feedbackpageurl/${billId}${queryParams ? queryParams : ''}`,
    // { method: 'GET', headers },
    { method: 'GET', headers:jwtHeaders() },
  );
  const resData = response?.json();
  return resData;
};

export const getBillUrl = async (billId) => {
  const response = await fetch(
      `${baseUrl}/api/billing/billpageurl/${billId}`,
      { method: 'GET', headers: jwtHeaders() },
  );
  const resData = response?.json();
  console.log(resData,"resssssData")
  return resData;
};
