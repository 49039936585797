// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import './GstBillsData.css';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import { useMain } from 'src/views/hooks/useMain';
// import { getGstData } from 'src/context/contextPages/BillingContexts';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import CloseIcon from '@mui/icons-material/Close';
// import { Grid } from 'rsuite';
// import { Button, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import { Box } from '@mui/system';
// import SearchImg from '../../../assets/search.svg';

// const GstBillsPage = () => {
//   const [tableData, setTableData] = useState([]);
//   const [isTableVisible, setIsTableVisible] = useState();
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);

//   const openModal = () => setIsModalOpen(true);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   useEffect(() => {
//     applyFilters(tableData, limit, genderBy, page, search, startDate, endDate);
//   }, [tableData, limit, genderBy, page, search, startDate, endDate]);

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''
//       }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
//     getGstBillDetails(queryParams);
//     setClear(true);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setGenderBy('');
//     setEndDate('');
//     setStartDate('');
//     if (clear) {
//       const queryParams = `?page=${page}&limit=${limit}`;
//       getGstBillDetails(queryParams);
//       setClear(false);
//     }
//   };

//   const pdfRef = useRef();
//   const { activeBranchId, getGstData } = useMain();

//   const getGstBillDetails = async (activeBranchId) => {
//     setLoading(true); // Show loader while fetching data
//     try {
//       const res = await getGstData(activeBranchId);
//       if (res && res.data && res.data.bills) {
//         setTableData(res.data.bills);
//         setIsTableVisible(true);
//       } else {
//         console.error('Invalid response structure:', res);
//       }
//     } catch (error) {
//       console.error('Error fetching API:', error);
//     } finally {
//       setLoading(false); // Hide loader after fetching data
//     }
//   };

//   const downloadPdf = () => {
//     const input = pdfRef.current;
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgWidth = 190; // Adjust as needed
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
//       pdf.save('GST_Bill_Details.pdf');
//     });
//   };

//   const closeTable = () => {
//     setIsTableVisible(false);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const applyFilters = (tableData, limit, gender, page, search, startDate, endDate) => {
//     let filteredData = tableData.filter(item => {
//       let isGenderMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       if (gender) {
//         isGenderMatch = item.gender === gender;
//       }

//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch = (clientName.toLowerCase().includes(search.toLowerCase()) ||
//           clientNumber.includes(search));
//       }

//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isNameMatch && isDateMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//     setIsTableVisible(true);
//   };

//   return (
//     <>
//       {loading ? (
//         <ShowLoader />
//       ) : (
//         <div>
//           {/* <div className="gstbill-btn">
//             <button onClick={() => getGstBillDetails(activeBranchId)}>Get Gst Bill Details</button>
//           </div> */}

//           {isTableVisible && (
//             <>
//               <div className='dateInput-container'>

//                 <Grid item xs={2}>
//                   <InputLabel>Show per page</InputLabel>
//                   <Select
//                     style={{
//                       borderRadius: '7px',
//                       border: '0.5px solid #6174DD',
//                       padding: '4px 12px',
//                       background: '#FFF',
//                       height: '40px',
//                     }}
//                     value={limit}
//                     onChange={handleLimitChange}
//                   >
//                     {[10, 20, 50].map((value) => (
//                       <MenuItem key={value} value={value}>
//                         {value}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </Grid>

//                 <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
//                   <Typography variant="subtitle2" fontWeight={500} component="label">
//                     {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//                   </Typography>
//                   <Button
//                     variant="outlined"
//                     target="_blank"
//                     sx={{ color: 'black', height: '40px', width: '100%' }}
//                     onClick={openModal}
//                     style={{ width: '15rem', marginTop: '23px' }}
//                   >
//                     Choose Date
//                   </Button>
//                 </Grid>

//                 {/* Search */}
//                 <Grid item xs={2} style={{ width: '15rem' }}>
//                   <div>
//                     <InputLabel>Search</InputLabel>
//                     <div className="search-input">
//                       <img src={SearchImg} alt="" />
//                       <input
//                         type="text"
//                         placeholder=""
//                         value={search}
//                         onChange={(e) => setSearch(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </Grid>

//                 <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       height: '40px',
//                       width: '100%',
//                       background: '#ff4d4d',
//                       '&:hover': { background: '#ff6666' },
//                     }}
//                     onClick={clearDataHandler}
//                   >
//                     Clear
//                   </Button>
//                 </Grid>
//               </div>

//               <DateRangeInputs
//                 isOpen={isModalOpen}
//                 onClose={closeModal}
//                 onDateRangeSelected={handleDateRangeSelected}
//                 selectedEndDate={endDate}
//                 selectedStartDate={startDate}
//               />
//               <div className="gst-table-container" ref={pdfRef}>
//                 <table>
//                   <thead>
//                     <tr>
//                       <th colSpan="7" style={{ textAlign: 'right' }}>
//                         <CloseIcon onClick={closeTable} style={{ cursor: 'pointer' }} />
//                       </th>
//                     </tr>
//                     <tr>
//                       <th>Date</th>
//                       <th>Client Name</th>
//                       <th>Invoice No</th>
//                       <th>Amount</th>
//                       <th>Payable Amount</th>
//                       <th>Gst Fair Amount</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {filterData?.map((item, index) => (
//                       <tr key={item._id}>
//                         <td>{item.dateOfBilling}</td>
//                         <td>{item.clientName}</td>
//                         <td>{item.invoice}</td>
//                         <td>{item.subTotal}</td>
//                         <td>{item.amountPayable}</td>
//                         <td>{item.amountPayable - item.subTotal}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//               <div className="download-pdf">
//                 <button onClick={downloadPdf}>Download PDF</button>
//               </div>

//               <Box display={'flex'} justifyContent={'flex-end'}>
//                 <Pagination
//                   count={filterData && filterData?.length < limit ? page : page + 1}
//                   page={page}
//                   color="primary"
//                   onChange={(e, page) => setPage(page)}
//                 />
//               </Box>
//             </>
//           )}


//         </div>
//       )}
//     </>
//   );
// };

// export default GstBillsPage;

// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import './GstBillsData.css';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import { useMain } from 'src/views/hooks/useMain';
// import { getGstData } from 'src/context/contextPages/BillingContexts';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import CloseIcon from '@mui/icons-material/Close';
// import { Grid } from 'rsuite';
// import { Button, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import { Box } from '@mui/system';
// import SearchImg from '../../../assets/search.svg';

// const GstBillsPage = () => {
//   const [tableData, setTableData] = useState([]);
//   const [isTableVisible, setIsTableVisible] = useState(false);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);

//   const openModal = () => setIsModalOpen(true);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   useEffect(() => {
//     applyFilters(tableData, limit, genderBy, page, search, startDate, endDate);
//   }, [tableData, limit, genderBy, page, search, startDate, endDate]);

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${
//       search ? `&search=${search}` : ''
//     }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
//     getGstBillDetails(queryParams);
//     setClear(true);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setGenderBy('');
//     setEndDate('');
//     setStartDate('');
//     if (clear) {
//       const queryParams = `?page=${page}&limit=${limit}`;
//       getGstBillDetails(queryParams);
//       setClear(false);
//     }
//   };

//   const pdfRef = useRef();
//   const { activeBranchId, getGstData } = useMain();

//   const getGstBillDetails = async (activeBranchId) => {
//     setLoading(true); // Show loader while fetching data
//     try {
//       const res = await getGstData(activeBranchId);
//       if (res && res.data && res?.data?.bills) {
//         setTableData(res.data.bills);
//         setIsTableVisible(true);
//       } else {
//         console.error('Invalid response structure:', res);
//       }
//     } catch (error) {
//       console.error('Error fetching API:', error);
//     } finally {
//       setLoading(false); // Hide loader after fetching data
//     }
//   };

//   const downloadPdf = () => {
//     const input = pdfRef.current;
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgWidth = 190; // Adjust as needed
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
//       pdf.save('GST_Bill_Details.pdf');
//     });
//   };

//   const closeTable = () => {
//     setIsTableVisible(false);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const applyFilters = (tableData, limit, gender, page, search, startDate, endDate) => {
//     let filteredData = tableData.filter((item) => {
//       let isGenderMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       if (gender) {
//         isGenderMatch = item.gender === gender;
//       }

//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch =
//           clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isNameMatch && isDateMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//     getGstBillDetails(activeBranchId)
//   };

//   return (
//     <>
//         <div>
//           {/* <div className="gstbill-btn">
//             <button onClick={() => getGstBillDetails(activeBranchId)}>Get Gst Bill Details</button>
//           </div> */}

//           {isTableVisible && (
//             <>
//               <div className="dateInput-container">
//                 <Grid item xs={2}>
//                   <InputLabel>Show per page</InputLabel>
//                   <Select
//                     style={{
//                       borderRadius: '7px',
//                       border: '0.5px solid #6174DD',
//                       padding: '4px 12px',
//                       background: '#FFF',
//                       height: '40px',
//                     }}
//                     value={limit}
//                     onChange={handleLimitChange}
//                   >
//                     {[10, 20, 50].map((value) => (
//                       <MenuItem key={value} value={value}>
//                         {value}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </Grid>

//                 <Grid
//                   item
//                   xs={2}
//                   display={'flex'}
//                   flexDirection={'column'}
//                   justifyContent={'flex-end'}
//                 >
//                   <Typography variant="subtitle2" fontWeight={500} component="label">
//                     {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//                   </Typography>
//                   <Button
//                     variant="outlined"
//                     target="_blank"
//                     sx={{ color: 'black', height: '40px', width: '100%' }}
//                     onClick={openModal}
//                     style={{ width: '15rem', marginTop: '23px' }}
//                   >
//                     Choose Date
//                   </Button>
//                 </Grid>

//                 {/* Search */}
//                 <Grid item xs={2} style={{ width: '15rem' }}>
//                   <div>
//                     <InputLabel>Search</InputLabel>
//                     <div className="search-input">
//                       <img src={SearchImg} alt="" />
//                       <input
//                         type="text"
//                         placeholder=""
//                         value={search}
//                         onChange={(e) => setSearch(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </Grid>

//                 <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       height: '40px',
//                       width: '100%',
//                       background: '#ff4d4d',
//                       '&:hover': { background: '#ff6666' },
//                     }}
//                     onClick={clearDataHandler}
//                   >
//                     Clear
//                   </Button>
//                 </Grid>
//               </div>

//               <DateRangeInputs
//                 isOpen={isModalOpen}
//                 onClose={closeModal}
//                 onDateRangeSelected={handleDateRangeSelected}
//                 selectedEndDate={endDate}
//                 selectedStartDate={startDate}
//               />
//               <div className="gst-table-container" ref={pdfRef}>
//                 <table>
//                   <thead>
//                     <tr>
//                       <th colSpan="7" style={{ textAlign: 'right' }}>
//                         <CloseIcon onClick={closeTable} style={{ cursor: 'pointer' }} />
//                       </th>
//                     </tr>
//                     <tr>
//                       <th>Date</th>
//                       <th>Client Name</th>
//                       <th>Invoice No</th>
//                       <th>Amount</th>
//                       <th>Payable Amount</th>
//                       <th>Gst Fair Amount</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {filterData?.map((item, index) => {
//                       const originalDate = item?.dateOfBilling;

//                       // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
//                       let formattedBillDate = '';
//                       if (originalDate) {
//                         const [year, month, day] = originalDate.split('-');
//                         formattedBillDate = `${day}-${month}-${year}`;
//                       }
//                       return (
//                         <tr key={item._id}>
//                           <td>{formattedBillDate}</td>
//                           <td>{item.clientName}</td>
//                           <td>{item.invoice}</td>
//                           <td>{item.subTotal}</td>
//                           <td>{item.amountPayable}</td>
//                           <td>{item.amountPayable - item.subTotal}</td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </table>
//               </div>
//               <div className="download-pdf">
//                 <button onClick={downloadPdf}>Download PDF</button>
//               </div>

//               <Box display={'flex'} justifyContent={'flex-end'}>
//                 <Pagination
//                   count={filterData && filterData?.length < limit ? page : page + 1}
//                   page={page}
//                   color="primary"
//                   onChange={(e, page) => setPage(page)}
//                 />
//               </Box>
//             </>
//           )}
//         </div>
//     </>
//   );
// };

// export default GstBillsPage;

//loader added to gstbill button
import React, { useEffect, useRef, useState } from 'react';
import './GstBillsData.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from 'rsuite';
import { Button, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import { Box } from '@mui/system';
import SearchImg from '../../../assets/search.svg';

const GstBillsPage = () => {
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeBranchId, getGstData } = useMain();
  const pdfRef = useRef();

  // Fetch GST Bill Details
  const fetchGstBillDetails = async () => {
    setLoading(true);
    try {
      const res = await getGstData(activeBranchId);
      if (res && res.data && res.data.bills) {
        setTableData(res.data.bills);
        setFilterData(res.data.bills); // Set initial filterData
        setIsTableVisible(true);
      } else {
        console.error('Invalid response structure:', res);
      }
    } catch (error) {
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  // Apply filters whenever relevant states change
  useEffect(() => {
    applyFilters();
  }, [tableData, limit, genderBy, page, search, startDate, endDate]);

  const applyFilters = () => {
    let filteredData = tableData.filter((item) => {
      let isGenderMatch = true;
      let isNameMatch = true;
      let isDateMatch = true;

      if (genderBy) {
        isGenderMatch = item.gender === genderBy;
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch =
          clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const billDate = new Date(item.dateOfBilling);
        isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
      }

      return isGenderMatch && isNameMatch && isDateMatch;
    });

    // Apply pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleDateRangeSelected = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsModalOpen(false);
    setClear(true);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    setEndDate('');
    setStartDate('');
    setClear(false);
    fetchGstBillDetails(); // Re-fetch all data when cleared
  };

  const downloadPdf = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('GST_Bill_Details.pdf');
    });
  };

  // const closeTable = () => {
  //   setIsTableVisible(false);
  // };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // Only fetch data on component mount
  useEffect(() => {
    fetchGstBillDetails();
  }, [activeBranchId]);

  return (
    <>
      <div>
        {loading ? (
          <ShowLoader />
        ) : (
          <>
            {isTableVisible && (
              <>
                <div className="dateInput-container">
                  <Grid item xs={2}>
                    <InputLabel>Show per page</InputLabel>
                    <Select
                      style={{
                        borderRadius: '7px',
                        border: '0.5px solid #6174DD',
                        padding: '4px 12px',
                        background: '#FFF',
                        height: '40px',
                      }}
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      {[10, 20, 50].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-end'}
                  >
                    <Typography variant="subtitle2" fontWeight={500} component="label">
                      {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
                    </Typography>
                    <Button
                      variant="outlined"
                      target="_blank"
                      sx={{ color: 'black', height: '40px', width: '100%' }}
                      onClick={() => setIsModalOpen(true)}
                      style={{ width: '15rem', marginTop: '23px' }}
                    >
                      Choose Date
                    </Button>
                  </Grid>

                  <Grid item xs={2} style={{ width: '15rem' }}>
                    <div>
                      <InputLabel>Search</InputLabel>
                      <div className="search-input">
                        <img src={SearchImg} alt="" />
                        <input
                          type="text"
                          placeholder=""
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        height: '40px',
                        marginTop: '1.7rem',
                        width: '100%',
                        background: '#ff4d4d',
                        '&:hover': { background: '#ff6666' },
                      }}
                      onClick={clearDataHandler}
                    >
                      Clear
                    </Button>
                  </Grid>
                </div>

                <DateRangeInputs
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  onDateRangeSelected={handleDateRangeSelected}
                  selectedEndDate={endDate}
                  selectedStartDate={startDate}
                />

                <div className="gst-table-container" ref={pdfRef}>
                  <table>
                    <thead>
                      <tr>
                        {/* <th colSpan="7" style={{ textAlign: 'right' }}>
                          <CloseIcon onClick={closeTable} style={{ cursor: 'pointer' }} />
                        </th> */}
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Client Name</th>
                        <th>Invoice No</th>
                        <th>Amount</th>
                        <th>Payable Amount</th>
                        <th>Gst Fair Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterData.map((item) => {
                        const originalDate = item?.dateOfBilling;
                        let formattedBillDate = '';
                        if (originalDate) {
                          const [year, month, day] = originalDate.split('-');
                          formattedBillDate = `${day}-${month}-${year}`;
                        }
                        return (
                          <tr key={item._id}>
                            <td>{formattedBillDate}</td>
                            <td>{item.clientName}</td>
                            <td>{item.invoice}</td>
                            <td>{item.subTotal}</td>
                            <td>{item.amountPayable}</td>
                            <td>{item.amountPayable - item.subTotal}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="download-pdf">
                  <button onClick={downloadPdf}>Download PDF</button>
                </div>

                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Pagination
                    count={filterData && filterData?.length < limit ? page : page + 1}
                    page={page}
                    color="primary"
                    onChange={(e, page) => setPage(page)}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GstBillsPage;
