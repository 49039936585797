import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import TableCells from 'src/components/Tables/TableCells';
import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useNavigate } from 'react-router';
import TextInputs from 'src/components/inputs/TextInputs';
import RadioInputs from 'src/components/inputs/RadioInputs';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const initialUpatedData = {
  clientName: '',
  clientNumber: '',
  clientAddress: '',
  clientEmail: '',
  gender: '',
  birthday: '',
  anniversary: '',
};

const FieldData = ({ data, setData, getData, setCheckedClients, checkedClients }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [updatedClientId, setUpdateClientId] = useState('');
  const [loading, setLoading] = useState(false);
  const [resLoading, setResLoading] = useState(false);
  const [connectingId, setConnectingId] = useState(null);

  const [updateMask, setUpdateMask] = useState(false)

  const navigate = useNavigate();
  const { deleteClient, updateClient, setShowMessage, branchData, activeBranchId, connectToCall } =
    useMain();

  useEffect(() => {
    setUpdateMask(!updateMask)
  }, [branchData?.isMasked])


  const [updatedClientData, setUpdatedClientData] = useState(initialUpatedData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'address') {
      setUpdatedClientData((prevData) => ({
        ...prevData,
        clientAddress: { [name]: value },
      }));
    } else {
      setUpdatedClientData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedClients([]);
    } else {
      setCheckedClients(data);
    }
    setIsAllChecked(!isAllChecked);
  };

  const handleSignleCheckboxChange = (clientId) => {
    if (checkedClients.length && checkedClients.some((check) => check._id === clientId)) {
      setCheckedClients((prev) => prev.filter((check) => check._id !== clientId));
    } else {
      const checked = data.filter((item) => item._id === clientId);
      setCheckedClients((prev) => [...prev, ...checked]); // Create a new array
    }
  };

  const handleUpdate = async (item) => {
    setUpdateClientId(item._id);
    setUpdatedClientData({
      ...updatedClientData,
      clientName: item?.clientName,
      clientNumber: item?.clientNumber,
      clientAddress: item?.clientAddress,
      gender: item?.gender,
      clientEmail: item?.clientEmail,
      birthday: item?.birthday,
      anniversary: item?.anniversary,
    });
    setOpenDialogue(true);
  };

  const hadleupdateClientData = async () => {
    try {
      setLoading(true);
      const res = await updateClient(
        updatedClientId,
        updatedClientData,
        `?branchId=${activeBranchId}`,
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Client Details updated successfully',
          messageType: 'success',
        });
        setData([]);
        getData();
      } else {
        setShowMessage({
          message: res.message || 'Client Details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  const handleDeleteField = async (id) => {
    try {
      const res = await deleteClient(id, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setData(data?.filter((data) => data?._id !== id));
        setShowMessage({
          message: res.message || 'Data deleted successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Data not deleted', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    }
  };

  const connectToCallHanlder = async (connectingId, number) => {
    setConnectingId(connectingId);
    setResLoading(true);
    try {
      const res = await connectToCall({ number });
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res?.message || 'Successfully connected',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res?.message || 'Some error occures',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Some error occures', messageType: 'error' });
    } finally {
      setResLoading(false);
      setConnectingId(null);
    }
  };

  const handleDetails = (id) => {
    navigate(`/client/${id}`);
  };

  useEffect(() => {
    setIsAllChecked((data?.length && checkedClients?.length === data?.length) || false);
  }, [checkedClients.length, data?.length]);

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            <th style={{ width: '2%' }}>
              <CheckboxInputs
                type={'Heading'}
                isAllChecked={isAllChecked}
                handleAllCheckboxChange={handleAllCheckboxChange}
              />
            </th>
            {[
              'Name',
              'Contact Number',
              'Gender',
              'Birthday',
              'Anniversary',
              'Bills Amount',
              'Most Visit',
              'Connect',
              'WhatsApp',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '11%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            return (
              <tr key={item._id}>
                <td style={{ width: '2%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedClients}
                    fieldId={item?._id}
                  />
                </td>
                <td style={{ width: '11%' }}>{TrimData(item?.clientName)}</td>
                <td style={{ width: '11%' }}>
                  {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                    ? 'loading...'
                    : maskData(item?.clientNumber, branchData?.isMasked)}
                </td>
                <td style={{ width: '11%' }}>{item?.gender}</td>
                <td style={{ width: '11%' }}>{item?.birthday}</td>
                <td style={{ width: '11%' }}>{item?.anniversary}</td>
                <td style={{ width: '11%' }}>{item?.totalBillsAmount}</td>
                <td style={{ width: '11%' }}>{item?.billsFrequency}</td>
                <td style={{ width: '11%' }}>
                  {' '}
                  {connectingId && connectingId === item?._id && resLoading ? (
                    <span>connecting...</span>
                  ) : (
                    <AddIcCallIcon
                      sx={{ color: '#6174DD', '&:hover': { cursor: 'pointer' } }}
                      onClick={() => connectToCallHanlder(item?._id, item?.clientNumber)}
                    />
                  )}
                </td>
                <td style={{ width: '11%' }}>
                  <WhatsAppIcon
                    sx={{
                      color: '#25d366',
                      '&:hover': { cursor: 'pointer' },
                    }}
                    onClick={() =>
                      window.open(
                        `https://app.interakt.ai/inbox?channelPhoneNumber=91${item?.clientNumber}`,
                        '_blank',
                      )
                    }
                  />
                </td>

                <td style={{ width: '11%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    handleDetails={handleDetails}
                    field={item?._id}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* ---------------------- Client Details Dialog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Client Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update client details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Client Name'}
                  name={'clientName'}
                  value={updatedClientData?.clientName}
                  handleChange={handleInputChange}
                  type={'text'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Contact No'}
                  name={'clientNumber'}
                  value={updatedClientData?.clientNumber}
                  handleChange={handleInputChange}
                  type={'number'}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RadioInputs
                  title={'Gender'}
                  name={'gender'}
                  value={updatedClientData?.gender}
                  handleChange={handleInputChange}
                  options={[
                    { title: 'M', value: 'Male' },
                    { title: 'F', value: 'Female' },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Email'}
                  name={'clientEmail'}
                  value={updatedClientData?.clientEmail}
                  handleChange={handleInputChange}
                  type={'email'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Address'}
                  name={'address'}
                  value={updatedClientData?.clientAddress?.address}
                  handleChange={handleInputChange}
                  type={'text'}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'BirthDay'}
                  required={false}
                  name={'birthday'}
                  value={updatedClientData?.birthday}
                  handleChange={handleInputChange}
                  type={'date'}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Anniversary'}
                  name={'anniversary'}
                  value={updatedClientData?.anniversary}
                  handleChange={handleInputChange}
                  type={'date'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader value={loading} />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={hadleupdateClientData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FieldData;



