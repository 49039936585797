import React, { useState } from 'react';
import { Box, Button, Grid, MenuItem, Select } from '@mui/material';
import TextInputs from 'src/components/inputs/TextInputs';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const initialValue = {
  membershipName: '',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  discount: {
    discountOnService: '',
    serviceDiscountType: '%',
    discountOnProduct: '',
    productDiscountType: '%',
    // discountOnPackages: '',
    // packageDiscountType: '%',
    minBillAmount: '',
    availableCount: '',
  },
};

const initialFormValidation = {
  membershipNameValidation: false,
  membershipPriceValidation: false,
  durationValidation: false,
  discountOnServiceValidation: false,
  discountOnProductValidation: false,
  minBillAmountValidation: false,
  availableCountValidation: false,
  membershipTypeValidation: false,
};

const DiscountType = ['%', 'INR'];

const DiscountMembership = ({ membershipType = "discountType" }) => {

  const { createMembership, activeBranchId, setShowMessage } = useMain();

  const [formData, setFormData] = useState({ ...initialValue, membershipType });
  const [formValidation, setFormValidation] = useState(initialFormValidation);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormValidation((prevFormValidation) => ({
      ...prevFormValidation,
      [`${name}Validation`]: !value.trim(),
    }));
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      discount: {
        ...prevFormData.discount,
        [name]: value,
      },
    }));
    setFormValidation((prevFormValidation) => ({
      ...prevFormValidation,
      [`${name}Validation`]: !(Number(value) > 0),
    }));
  };

  const handleSubmit = async () => {
    const { membershipName,
      membershipPrice,
      duration,
      rewardPointsOnPurchase,
      discount,
      membershipType,
    } = formData;

    const validation = {
      membershipNameValidation: !membershipName.trim(),
      membershipPriceValidation: !membershipPrice || !(Number(membershipPrice) > 0),
      durationValidation: !duration || !(Number(duration) > 0),
      discountOnServiceValidation: !(Number(discount.discountOnService) > 0),
      discountOnProductValidation: !(Number(discount.discountOnProduct) > 0),
      minBillAmountValidation: !(Number(discount.minBillAmount) > 0),
      availableCountValidation: !(Number(discount.availableCount) > 0),
      membershipTypeValidation: !membershipType.trim(),
    };

    setFormValidation((prev) => ({ ...prev, ...validation }));

    if (Object.values(validation).some((item) => item)) {
      setShowMessage({
        message: 'Please fill out all required fields correctly.',
        messageType: 'error',
      });
      return;
    }
    try {
      setLoading(true);
      const res = await createMembership(
        JSON.stringify({
          membershipName,
          membershipPrice: Number(membershipPrice),
          duration: Number(duration),
          rewardPointsOnPurchase: rewardPointsOnPurchase ? Number(rewardPointsOnPurchase) : 0,
          discount: {
            ...discount,
            discountOnService: Number(discount.discountOnService),
            discountOnProduct: Number(discount.discountOnProduct),
            minBillAmount: Number(discount.minBillAmount),
            availableCount: Number(discount.availableCount),
          },
          membershipType,
          branchDetails: activeBranchId,
        })
      );

      if (res?.statusCode === 200) {
        setFormData({ ...initialValue, membershipType });
        setFormValidation({ ...initialFormValidation });
        setShowMessage({
          message: res.message || 'Discount membership created successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res.message || 'Membership not created',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Error creating membership',
        messageType: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Membership Name'}
              name={'membershipName'}
              value={formData?.membershipName}
              handleChange={handleChange}
              type={'text'}
              fieldValidation={formValidation.membershipNameValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Duration (in Months)'}
              name={'duration'}
              placeholder="0"
              value={formData?.duration}
              handleChange={handleChange}
              type={'number'}
              fieldValidation={formValidation.durationValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Membership Price'}
              name={'membershipPrice'}
              placeholder="0"
              value={formData?.membershipPrice}
              handleChange={handleChange}
              type={'number'}
              fieldValidation={formValidation.membershipPriceValidation}
            />
          </Grid>
        </Grid>
      </Box>

      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Discount on Service'}
                  name={'discountOnService'}
                  placeholder="0"
                  value={formData?.discount?.discountOnService}
                  handleChange={handleDiscountChange}
                  type={'number'}
                  min={1}
                  fieldValidation={formValidation.discountOnServiceValidation}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ marginBottom: '7px', fontWeight: 'bold', color:'black' }}>
                  Discount Type
                </div>
                <Select
                  name="serviceDiscountType"
                  value={formData?.discount?.serviceDiscountType}
                  onChange={handleDiscountChange}
                  fullWidth
                >
                  {DiscountType.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2} >
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Discount on Product'}
                  name={'discountOnProduct'}
                  placeholder="0"
                  value={formData?.discount?.discountOnProduct}
                  handleChange={handleDiscountChange}
                  type={'number'}
                  fieldValidation={formValidation.discountOnProductValidation}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ marginBottom: '7px', fontWeight: 'bold',color:'black'}}>
                  Discount Type
                </div>
                <Select
                  name="productDiscountType"
                  value={formData?.discount?.productDiscountType}
                  onChange={handleDiscountChange}
                  fullWidth
                >
                  {DiscountType.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Minimum Bill Amount'}
              name={'minBillAmount'}
              placeholder="0"
              value={formData?.discount?.minBillAmount}
              handleChange={handleDiscountChange}
              type={'number'}
              fieldValidation={formValidation.minBillAmountValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Available Count'}
              name={'availableCount'}
              placeholder="0"
              value={formData?.discount?.availableCount}
              handleChange={handleDiscountChange}
              type={'number'}
              fieldValidation={formValidation.availableCountValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              required={false}
              title={'Reward Points'}
              name={'rewardPointsOnPurchase'}
              // placeholder="0"
              value={formData?.rewardPointsOnPurchase}
              handleChange={handleChange}
              type={'number'}
            />
          </Grid>
        </Grid>
      </Box>

      <Box style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Create Membership
        </Button>
      </Box>
    </div>
  );
};

export default DiscountMembership;
