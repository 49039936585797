import React, { useState, useEffect } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid, Select, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import Suggestion from './Suggestion';

const initialValue = {
  productName: '',
  mrp: '',
  volume: '',
  unit: '',
  productCount: '',
  sellPrice: '',
  // barcode: '',
  rewardPoints: '',
  staffIncentive: '',
  incentiveType: '%',
};

const initialFormValidation = {
  productNameValidation: false,
  mrpValidation: false,
  sellPriceValidation: false,
  productCountValidation: false,
};

const AddProduct = () => {
  const { createProduct, activeBranchId, getBranch, setShowMessage } = useMain();

  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [productData, setProductData] = useState([]);
  const [suggested, setSuggested] = useState([]);
  const [activeField, setActiveField] = useState('');
  const [loading, setLoading] = useState(false);

  const productNameValidation = !formData.productName.trim();
  const mrpValidation = !formData.mrp || !(Number(formData.mrp) > 0);
  const sellPriceValidation = !formData.sellPrice || !(Number(formData.sellPrice) > 0);
  const productCountValidation = !formData.productCount || !(Number(formData.productCount) > 0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'productName' || name === 'vendorName') {
      updateSuggested(name, value);
    }
  };

  const updateSuggested = (fieldName, inputValue) => {
    if (inputValue === '') {
      setSuggested([]);
    } else {
      const suggestions = productData
        .filter((product) =>
          product[fieldName === 'productName' ? 'productName' : 'vendorName']
            ?.toLowerCase()
            .includes(inputValue.toLowerCase()),
        )
        .map((product) => product[fieldName === 'productName' ? 'productName' : 'vendorName']);
      setSuggested(suggestions);
    }
  };

  const handleSuggestedClick = (suggestedName) => {
    const updatedFormData = { ...formData };
    if (activeField === 'productName' || activeField === 'vendorName') {
      updatedFormData[activeField] = suggestedName;
    }
    setFormData(updatedFormData);
    setSuggested([]);
  };

  const handleSubmit = async () => {
    const { productName, mrp, productCount, sellPrice } = formData;

    const validation = {
      productNameValidation: !productName.trim(),
      mrpValidation: !mrp || !(Number(mrp) > 0),
      sellPriceValidation: !sellPrice || !(Number(sellPrice) > 0),
      productCountValidation: !productCount || !(Number(productCount) > 0),
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;
    try {
      setLoading(true);
      const res = await createProduct(
        JSON.stringify({ ...formData, branchDetails: activeBranchId }),
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Product has been created successfully',
          messageType: 'success',
        });
        setFormData(initialValue);
        setFormValidation((pre) => ({ ...initialFormValidation }));
      } else {
        setShowMessage({ message: res.message || 'Something went wrong', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleInputFocus = () => {
    setSuggested([]);
    const activeElement = document.activeElement;
    if (activeElement && activeElement.getAttribute('name')) {
      setActiveField(activeElement.getAttribute('name'));
    } else {
      setActiveField('');
    }
  };

  useEffect(() => {
    document.addEventListener('focus', handleInputFocus, true);
    return () => {
      document.removeEventListener('focus', handleInputFocus, true);
    };
  }, [activeField]);

  const getData = async () => {
    try {
      const res = await getBranch(activeBranchId);
      if (res.statusCode === 200) {
        const data = res?.data;
        setProductData(data?.products || []);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (activeBranchId) getData();
  }, [activeBranchId]);

  return (
    <PageContainer title="Add New Product" description="This is add Product">
      <DashboardCard title="Add New Product">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3} justify="center">
            {/* <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Vendor Name'}
                name={'vendorName'}
                value={formData?.vendorName}
                handleChange={handleChange}
                type={'text'}
              />
              {suggested.length > 0 && activeField === 'vendorName' && (
                <Suggestion data={suggested} handleSuggestedClick={handleSuggestedClick} />
              )}
            </Grid> */}
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Product Name'}
                name={'productName'}
                value={formData?.productName}
                handleChange={handleChange}
                type={'text'}
                fieldValidation={productNameValidation && formValidation.productNameValidation}
              />
              {suggested.length > 0 && activeField === 'productName' && (
                <Suggestion data={suggested} handleSuggestedClick={handleSuggestedClick} />
              )}
            </Grid>
            <Grid item xs={12} md={4} container>
              <Grid item xs={12} container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                  <TextInputs
                    required={false}
                    title={'Volume'}
                    name={'volume'}
                    // placeholder="0"
                    value={formData?.volume}
                    handleChange={handleChange}
                    type={'number'}
                    min={1}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>Unit</div>
                  <Select name="unit" value={formData?.unit} onChange={handleChange} fullWidth>
                    {['ml', 'l', 'gm', 'kg']?.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <TextInputs
                title={'MRP'}
                name={'mrp'}
                // placeholder="0"
                value={formData?.mrp}
                handleChange={handleChange}
                type={'number'}
                fieldValidation={mrpValidation && formValidation.mrpValidation}
              />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <TextInputs
                required={false}
                title={'Purchase Price'}
                name={'purchasePrice'}
                placeholder="0"
                value={formData?.purchasePrice}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <TextInputs
                title={'Sell Price'}
                name={'sellPrice'}
                // placeholder="0"
                value={formData?.sellPrice}
                handleChange={handleChange}
                type={'number'}
                fieldValidation={sellPriceValidation && formValidation.sellPriceValidation}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInputs
                title={'Product Count'}
                name={'productCount'}
                // placeholder="0"
                value={formData?.productCount}
                handleChange={handleChange}
                type={'number'}
                fieldValidation={productCountValidation && formValidation.productCountValidation}
              />
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={3}>
              <TextInputs
                required={false}
                title={'Bar Code'}
                name={'barcode'}
                // placeholder="0"
                value={formData?.barcode}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <TextInputs
                required={false}
                title={'Reward Points'}
                name={'rewardPoints'}
                // placeholder="0"
                value={formData?.rewardPoints}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInputs
                required={false}
                title={'Staff Incentive'}
                name={'staffIncentive'}
                // placeholder="0"
                value={formData?.staffIncentive}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>Unit</div>
              <Select
                name="incentiveType"
                value={formData?.incentiveType}
                onChange={handleChange}
                fullWidth
              >
                {['%', 'INR']?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
        <div style={{ width: '200px', margin: '3rem auto' }}>
          {loading ? (
            <ShowLoader />
          ) : (
            <Button
              variant="contained"
              fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleSubmit}
            >
              Add Product
            </Button>
          )}
        </div>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddProduct;
