import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

const DiscountMembershipDetail = ({ data }) => {
  return (
    <Grid container xs={12}>
      <Grid container xs={12} spacing={4}>
      <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Start Date: <strong>{data.startDate}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              End Date: <strong>{data.endDate}</strong>
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Membership duration: <strong>{data.duration} month</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Reward Points: <strong>{data.rewardPointsOnPurchase}</strong>
            </Typography>
          </Grid>
        </Grid> */}
        <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Available Count: <strong>{data.discount.availableCount}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Min Bill Amount: <strong>{data.discount.minBillAmount}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <table className="table-container mt-3">
          <thead className="table-thead">
            <tr>
              {['Discount on Service', 'Discount on Products'].map(
                (h, index) => {
                  return (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  );
                },
              )}
            </tr>
          </thead>
          <tbody className="table-tbody">
            <tr>
              <td
                style={{ width: '100%' }}
              >{`${data?.discount?.discountOnService}   ${data?.discount?.serviceDiscountType}`}</td>
              <td
                style={{ width: '100%' }}
              >{`${data?.discount?.discountOnProduct}   ${data?.discount?.productDiscountType}`}</td>
              {/* <td
                style={{ width: '100%' }}
              >{`${data?.discount?.discountOnPackages}   ${data?.discount?.packageDiscountType}`}</td> */}
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default DiscountMembershipDetail;
