import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableCells from 'src/components/Tables/TableCells';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useNavigate } from 'react-router';
// import SelectInputs from 'src/components/inputs/SelectInputs';

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const initialValue = {
  membershipName: '',
  // membershipType: 'serviceType',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  servicesOffered: [
    {
      service: '',
      availableCount: 0,
    },
  ],
};

const ServiceTypeFieldData = ({ data, setData, getData, state }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [updateFormData, setUpdateFormData] = useState(initialValue);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const { deleteMembership, activeBranchId, getBranch, updateMembership, setShowMessage } = useMain();

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleUpdate = (item) => {
    setSelectedItemId(item._id);
    let { membershipName, membershipPrice, duration, rewardPointsOnPurchase, servicesOffered } =
      item;
    servicesOffered = servicesOffered.map((item) => ({
      service: item.service._id,
      availableCount: item.availableCount,
    }));
    setUpdateFormData((pre) => ({
      ...pre,
      membershipName,
      membershipPrice,
      duration,
      rewardPointsOnPurchase,
      servicesOffered: [...servicesOffered],
    }));
    setOpenDialogue(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((pre) => ({ ...pre, [name]: value }));
  };

  const addServiceRow = () => {
    const hasUnselectedService = updateFormData.servicesOffered.some(
      (item) => item.service === '' || item.availableCount == 0,
    );

    if (!hasUnselectedService) {
      setUpdateFormData((prevData) => ({
        ...prevData,
        servicesOffered: [
          ...prevData.servicesOffered,
          {
            service: '',
            availableCount: 0,
          },
        ],
      }));
    } else {
      setErrorMessage(
        'Select a service and count for the existing row(s) before adding a new row.',
      );
    }
  };

  // Remove a service row
  const removeServiceRow = (index) => {
    setUpdateFormData((prevData) => {
      const updatedServicesOffered = [...prevData.servicesOffered];
      updatedServicesOffered.splice(index, 1);
      return {
        ...prevData,
        servicesOffered: updatedServicesOffered,
      };
    });
  };

  // Handle change in service select and count
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServicesOffered = [...updateFormData.servicesOffered];
    updatedServicesOffered[index] = {
      ...updatedServicesOffered[index],
      [name]: name === 'availableCount' ? Number(value) : value,
    };
    setUpdateFormData((prevData) => ({
      ...prevData,
      servicesOffered: updatedServicesOffered,
    }));
  };

  const hadleUpdateServiceTypeMembership = async () => {
    try {
      setLoading(true)
      const res = await updateMembership(selectedItemId, updateFormData, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setShowMessage({ message: res.message || "Update Membership successfully", messageType: 'success' })
        setData([])
        getData();
        setUpdateFormData((pre) => ({
          ...pre,
          ...initialValue,
          servicesOffered: [...initialValue.servicesOffered],
        }));
      } else {
        setShowMessage({ message: res.message || 'Update Membership failed', messageType: 'error' })
      }
    } catch (error) {
      setShowMessage({ message: error.messag || 'Something went wrong', messageType: 'error' })
    } finally {
      setLoading(false)
      setOpenDialogue(false);
    }
  };

  const handleDetails = (id) => {
    navigate(`/membershipdetail/${id}`, { state })
  };

  const handleDeleteField = async (id) => {
    try {
      const res = await deleteMembership(id, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setData(data?.filter((data) => data?._id !== id));
        setShowMessage({ message: res.message || 'Membership deleted successfully', messageType: 'success' })
      } else {
        setShowMessage({ message: res.message || 'Membership not deleted', messageType: 'error' })
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' })
    } finally {
      setOpenDialogue(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getBranch(activeBranchId);
        setServices(res?.data.services || []);
      } catch (error) {
      }
    };
    if (activeBranchId) getData();
    return () => {};
  }, [activeBranchId]);

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <Snackbar
        open={errorMessage === '' ? false : true}
        autoHideDuration={6000}
        onClose={() => {
          setErrorMessage('');
        }}
        severity="error"
        message={errorMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Membership Name',
              'Membership Price',
              'Validity',
              'Rewards Points',
              // 'Details',
              'Actions',
            ].map((h, index) => {
              return <th key={index} style={{ width: '100%' }}>{h}</th>;
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            return (
              <tr key={item._id}>
                <td style={{ width: '100%' }}>{TrimData(item?.membershipName)}</td>
                <td style={{ width: '100%' }}>{item?.membershipPrice}</td>
                <td style={{ width: '100%' }}>{item?.duration}</td>
                <td style={{ width: '100%' }}>{item?.rewardPointsOnPurchase}</td>
                {/* <td style={{ width: '100%' }}>
                  <Button
                    onClick={() => handleUpdate(item)}
                    variant="contained"
                    sx={{
                      backgroundColor: 'primary',
                      color: '#fff',
                      align: 'center',
                    }}
                  >
                    Click Me
                  </Button>
                </td> */}
                <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    handleDetails={handleDetails}
                    field={item?._id}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <Table
        aria-label="simple table"
        sx={{
          whiteSpace: 'nowrap',
          mt: 2,
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              background: '#6174DD',
            }}
          >
            <TableCells
              borderRadius={'10px 0 0 10px'}
              color={'white'}
              type={'Heading'}
              data={'Membership Name'}
            />
            {['Membership Price', 'Validity', 'Rewards Points', 'Details'].map((item, index) => (
              <TableCells key={index} color={'white'} type={'Heading'} data={item} />
            ))}
            <TableCells
              borderRadius={'0px 10px 10px 0px'}
              color={'white'}
              type={'Heading'}
              data={'Actions'}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item?._id} sx={{}}>
              <TableCells type={'content'} data={TrimData(item?.membershipName)} />
              <TableCells
                borderRadius={'10px 0 0 10px'}
                type={'content'}
                data={item?.membershipPrice}
              />
              <TableCells type={'content'} data={item?.duration} />
              <TableCells type={'content'} data={item?.rewardPointsOnPurchase} />
              <TableCell
                sx={{
                  borderBottom: '0.5px solid #CFCFCF',
                  borderRadius: '0px',
                  my: '10px',
                }}
              >
                <Button
                  onClick={() => handleUpdate(item)}
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary',
                    color: '#fff',
                    align: 'center',
                  }}
                >
                  Click Me
                </Button>
              </TableCell>
              <TableCells type={'button'} handleDeleteField={handleDeleteField} field={item?._id} />
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
      {/* ---------------------- ServiceType membership details dailog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle> ServiceType membership Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update membership details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Membership Name'}
                  name={'membershipName'}
                  value={updateFormData?.membershipName}
                  handleChange={handleInputChange}
                  type={'text'}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Duration (in Months)'}
                  name={'duration'}
                  value={updateFormData?.duration}
                  handleChange={handleInputChange}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Membership Price'}
                  name={'membershipPrice'}
                  value={updateFormData?.membershipPrice}
                  handleChange={handleInputChange}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Reward Points'}
                  name={'rewardPointsOnPurchase'}
                  value={updateFormData?.rewardPointsOnPurchase}
                  handleChange={handleInputChange}
                  type={'number'}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {updateFormData.servicesOffered.map((item, index) => (
            <Box style={{ marginTop: '20px' }} key={index}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Services</div>
                    <Select
                      name={'service'} // Use a unique name for each select input
                      value={item.service}
                      onChange={(e) => handleChange(e, index)}
                    >
                      <MenuItem key={'default'} value={''}>
                        {'Select a Service'}
                      </MenuItem>
                      {services.map((service) => (
                        <MenuItem key={service._id} value={service._id}>
                          {`${service.serviceName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2} marginTop={'-5px'}>
                  <TextInputs
                    title={'Count'}
                    name={'availableCount'} // Use a unique name for each input
                    value={item.availableCount}
                    handleChange={(e) => handleChange(e, index)}
                    type={'number'}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '5px' }}
                >
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      sx={{
                        background: '#6174DD',
                        width: '64px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                      onClick={addServiceRow}
                    >
                      Add
                    </Button>
                  </Grid>
                  {index !== 0 ? (
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#ff0000',
                          width: '64px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          '&:hover': {
                            background: '#ff6666', // Change the background color on hover
                          },
                        }}
                        onClick={() => removeServiceRow(index)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Box>
          ))} */}

          {updateFormData.servicesOffered.map((item, index) => {
            // Get the list of already selected services except for the current one in this row
            const selectedServices = updateFormData.servicesOffered
              .filter((_, i) => i !== index)
              .map((serviceItem) => serviceItem.service);

            const availableServices = services.filter(
              (service) => !selectedServices.includes(service._id),
            );
            return (
              <Box style={{ marginTop: '20px' }} key={index}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Services</div>
                      <Select
                        name={'service'} 
                        value={item.service}
                        onChange={(e) => handleChange(e, index)}
                      >
                        <MenuItem key={'default'} value={''}>
                          {'Select a Service'}
                        </MenuItem>
                        {availableServices.map((service) => (
                          <MenuItem key={service._id} value={service._id}>
                            {`${service.serviceName}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2} marginTop={'-5px'}>
                    <TextInputs
                      title={'Count'}
                      name={'availableCount'} // Use a unique name for each input
                      value={item.availableCount}
                      handleChange={(e) => handleChange(e, index)}
                      type={'number'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '5px' }}
                  >
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#6174DD',
                          width: '64px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        onClick={addServiceRow}
                      >
                        Add
                      </Button>
                    </Grid>
                    {index !== 0 ? (
                      <Grid item xs={12} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          target="_blank"
                          sx={{
                            background: '#ff0000',
                            width: '64px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            '&:hover': {
                              background: '#ff6666', // Change the background color on hover
                            },
                          }}
                          onClick={() => removeServiceRow(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          {loading ? <ShowLoader /> : <>
            <div style={{ width: '200px', margin: '3rem auto' }}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                target="_blank"
                sx={{
                  background: '#6174DD',
                }}
                onClick={handleCloseDialogue}
              >
                Cancel
              </Button>
            </div>
            <div style={{ width: '200px', margin: '3rem auto' }}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                target="_blank"
                sx={{
                  background: '#6174DD',
                }}
                onClick={hadleUpdateServiceTypeMembership}
              >
                update
              </Button>
            </div>
          </>}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ServiceTypeFieldData;