import React from 'react';
import { Line } from 'react-chartjs-2';
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Today Client Visit Report',
    },
  },
};
const LineGraph = ({ graphData }) => {
  const data = {
    labels: graphData.labels,
    datasets: [
      {
        label: 'Dataset',
        data: graphData.data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default LineGraph;
