import React from 'react';
import { HANDLE_MEMBERSHIP_INPUT_CHANGE } from 'src/reducers/billReducer/billConstent';

const MembershipCard = ({ data, state, dispatch, onClose }) => {
  const container = {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    minHeight: '90vh',
    padding: '30px',
    gap: '10px',
  };

  const button = {
    background: '#6174DD',
    color: 'white',
    border: 'none',
    padding: '10px 30px',
    cursor: 'pointer',
    borderRadius: '5px',
  };

  const applyHandler = (data) => {
    dispatch({
      type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
      payload: { useMembership: true, item: data },
    });
    onClose();
  };
  const removeHandler = (data) => {
    dispatch({
      type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
      payload: { useMembership: false, item: data },
    });
    onClose();
  };

  return (
    <div style={{ ...container, overflow: 'auto' }}>
      {data.membershipType === 'discountType' ? (
        <div style={{ height: '100vh' }}>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {[
                  'Membership Type',
                  'Start Date',
                  'End Date',
                  'Remaining Count',
                  'Minimum Bill Amount',
                ].map((h, index) => {
                  return (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="table-tbody">
              <tr>
                <td style={{ width: '100%' }}>{data?.membershipType}</td>
                <td style={{ width: '100%' }}>{data?.startDate}</td>
                <td style={{ width: '100%' }}>{data?.endDate}</td>
                <td style={{ width: '100%' }}>{data?.discount.availableCount}</td>
                <td style={{ width: '100%' }}>{data?.discount.minBillAmount}</td>
              </tr>
            </tbody>
          </table>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {['Discount On Service', 'Discount On Product'].map(
                  (h, index) => {
                    return (
                      <th key={index} style={{ width: '100%' }}>
                        {h}
                      </th>
                    );
                  },
                )}
              </tr>
            </thead>
            <tbody className="table-tbody">
              <tr>
                <td
                  style={{ width: '100%' }}
                >{`${data?.discount?.discountOnService}   ${data?.discount?.serviceDiscountType}`}</td>
                <td
                  style={{ width: '100%' }}
                >{`${data?.discount?.discountOnProduct}   ${data?.discount?.productDiscountType}`}</td>
                {/* <td
                  style={{ width: '100%' }}
                >{`${data?.discount?.discountOnPackages}   ${data?.discount?.packageDiscountType}`}</td> */}
              </tr>
            </tbody>
          </table>
          {data?.discount.availableCount < 1 ? (
            <span style={{ color: 'red' }}>Your Available Count is not valid</span>
          ) : calculatePriceSubTotal([...state.services, ...state.products]) <
            data?.discount.minBillAmount ? (
            <span style={{ color: 'red' }}>
              Add items worth ₹
              {`${
                data?.discount.minBillAmount -
                calculatePriceSubTotal([...state.services, ...state.products])
              }`}{' '}
              more to unlock
            </span>
          ) : (
            ''
          )}
          {calculatePriceSubTotal([...state.services, ...state.products]) >=
            data?.discount.minBillAmount &&
          data?.discount.availableCount > 0 &&
          state.useMembership ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
              <button style={{ ...button }} onClick={() => removeHandler(data)}>
                Remove
              </button>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
              <button style={{ ...button }} onClick={() => applyHandler(data)}>
                apply
              </button>
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: '100vh' }}>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {['Membership Type', 'Start Date', 'End Date'].map((h, index) => {
                  return (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="table-tbody">
              <tr>
                <td style={{ width: '100%' }}>{data?.membershipType}</td>
                <td style={{ width: '100%' }}>{data?.startDate}</td>
                <td style={{ width: '100%' }}>{data?.endDate}</td>
              </tr>
            </tbody>
          </table>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {['Service Name', 'Category', 'Remaining Count'].map((h, index) => {
                  return (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="table-tbody">
              {data?.services.map((item) => {
                return (
                  <tr key={item.id}>
                    <td style={{ width: '100%' }}>{item?.serviceName}</td>
                    <td style={{ width: '100%' }}>{item?.category}</td>
                    <td style={{ width: '100%' }}>{item?.count}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {hasMatchingServiceId(state.services, data.services) && hasNonZeroCount(data.services) ? (
            state.useMembership ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <button style={{ ...button }} onClick={() => removeHandler(data)}>
                  Remove
                </button>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <button style={{ ...button }} onClick={() => applyHandler(data)}>
                  apply
                </button>
              </div>
            )
          ) : (
            <span style={{ color: 'red' }}>
              Your Services count zero or please select a Matched services
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default MembershipCard;

function hasMatchingServiceId(array1, array2) {
  const serviceIdsSet = new Set(array1?.map((item) => item.service));
  for (const item of array2) {
    if (serviceIdsSet.has(item.id)) {
      return true;
    }
  }
  return false;
}

function hasNonZeroCount(services) {
  for (const service of services) {
    if (service.count !== 0) {
      return true; // Found a service with a non-zero count
    }
  }
  return false; // All counts are zero
}

function calculatePriceSubTotal(items) {
  return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
}
