// import React, { useEffect, useState } from 'react';
// import './analytics.css';
// import PageContainer from 'src/components/container/PageContainer';

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Button, FormControl, MenuItem, Select, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import { useMain } from 'src/views/hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import LineGraph from './LineGraph';
// import BarGraph from './BarGraph';

// import * as XLSX from 'xlsx';
// import { useRef } from 'react';
// import GstBillsPage from '../../Settings/GstbillsData/GstBillsPage';
// import { baseUrl, jwtHeaders } from 'src/context/common/data';
// import PackageBillData from 'src/views/Settings/GstbillsData/PackageBillData';
// import MembershipBillData from 'src/views/Settings/GstbillsData/MembershipBillData';
// import { Grid } from 'rsuite';
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const filterOptions = [
//   { label: 'Current Year', value: 'Current Year' },
//   { label: 'Current Month', value: 'Current Month' },
//   { label: 'Current Week (Sun-Sat)', value: 'Current Week' },
//   { label: 'Today', value: 'Today' },
//   { label: 'Custom Date', value: 'customDate' },
// ];

// export const maskData = (value, isMasked) => {
//   if (isMasked) {
//     const maskLength = Math.ceil(value.length / 2);
//     return '*'.repeat(maskLength) + value.substring(maskLength);
//   } else {
//     return value;
//   }
// };

// function SalesReport() {
//   const { activeBranchId, fetchBillAnalytics, branchData, staffCollection, fetchMemberAnalytixs } =
//     useMain();

//   const [filterBy, setFilterBy] = useState('Today');
//   const [from, setFrom] = useState('');
//   const [to, setTo] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [tableData, setTableData] = useState([]);
//   const [graphData, setGraphData] = useState({ labels: [], data: [] });
//   const [staffGraphData, setStaffGraphData] = useState({ categories: [], series: [] });
//   const [cashData, setCashData] = useState([]);
//   const [packagedata, setPackagedata] = useState(null);
//   const [memberdata, setMemberdata] = useState(null);
//   const [advance, setAdvance] = useState(0);
//   const [totalWithoutWallet, setTotalWithoutWallet] = useState(0);
//   // const [activeComponent, setActiveComponent] = useState(null);

//   // const handleButtonClick = (component) => {
//   //   setActiveComponent(component);
//   // };
//   const [selectedView, setSelectedView] = useState('GstBillsPage'); // Default view

//   const handleButtonClick = (view) => {
//     setSelectedView(view);
//   };

//   useEffect(() => {
//     // Fetch data here based on selectedView if necessary
//   }, [selectedView]);

//   const [total, setTotal] = useState({
//     totalAmountPaid: 0,
//     extraPay: 0,
//     paidDues: 0,
//     taxAmount: 0,
//   });

//   const tableRef = useRef();
//   console.log(tableData);

//   const getTotal = () => {
//     let extraPay = 0;
//     let totalAmountPaid = 0;
//     let paidDues = 0;
//     let taxAmount = 0;
//     let totalWalletAmount = 0;
//     let totalWithoutWallet = 0; // Initialize a variable for the amount without wallet payments

//     const updatedTableData =
//       tableData &&
//       tableData.length > 0 &&
//       tableData.map((data) => {
//         const amountPaidArray = data?.amountPaid || [];
//         const totalAmount = Number(data?.totalAmount) || 0;
//         const subTotal = Number(data?.subTotal) || 0;

//         // Filter out wallet payments and calculate total wallet amount
//         const walletPayments = amountPaidArray.filter(payment => payment.paymentType === 'Wallet');
//         const nonWalletPayments = amountPaidArray.filter(payment => payment.paymentType !== 'Wallet');

//         const amountPaidWithoutWallet = nonWalletPayments.reduce((acc, payment) => acc + Number(payment.amount || 0), 0);
//         const walletAmount = walletPayments.reduce((acc, payment) => acc + Number(payment.amount || 0), 0);
//         totalWalletAmount += walletAmount;
//         totalWithoutWallet += amountPaidWithoutWallet; // Add to the total without wallet

//         // Calculate dues
//         const duesAmount = totalAmount - (amountPaidWithoutWallet + walletAmount);

//         if (duesAmount > 0) {
//           paidDues += duesAmount;
//         } else {
//           extraPay += Math.abs(duesAmount);
//         }
//         totalAmountPaid += subTotal;
//         taxAmount += Number(data.taxes?.taxType === 'Exclusive' ? subTotal * 0.18 : 0);
//       });

//     // Update states
//     setTotal({ totalAmountPaid, taxAmount, paidDues, extraPay, totalWalletAmount });
//     setTotalWithoutWallet(totalWithoutWallet); // Set the new total without wallet payments
//   };

//   useEffect(() => {
//     getTotal();
//   }, [tableData]);

//   // console.log(total);
//   const getData2 = async (activeBranchId) => {
//     const queryParams = `&filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
//       }`;
//     try {
//       setLoading(true);
//       const result = await fetch(
//         `${baseUrl}/api/billing/packagebilloverview?branchId=${activeBranchId}${queryParams ? queryParams : ''
//         }`,
//         {
//           method: 'GET',
//           headers: jwtHeaders(),
//           redirect: 'follow',
//         },
//       );
//       const res = await result.json();
//       console.log(res);
//       if (res.statusCode === 200) {
//         setPackagedata(res?.data?.packageBills);
//         setMemberdata(res?.data?.membershipBills);
//       }
//     } catch (error) { }
//     setLoading(false);
//   };

//   const getData = async (activeBranchId) => {
//     const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
//       }`;
//     try {
//       setLoading(true);
//       const res = await fetchBillAnalytics(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setTableData(res.data.salesData.billDetails);
//         setCashData(res.data.paymentTypeWiseAmount);
//         setAdvance(res.data.totalAdvanceAmount)
//         setGraphData((pre) => ({
//           ...pre,
//           labels: res.data.salesData.labels,
//           data: res.data.salesData.data,
//         }));
//       } else {
//       }
//       // const res2 = await fetchMemberAnalytixs(activeBranchId, queryParams);
//       // if (res2.statusCode === 200) {
//       //   console.log(res2);
//       // }
//     } catch (error) {
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getStaffGraphData = async (activeBranchId) => {
//     const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
//       }`;
//     try {
//       const res = await staffCollection(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         const categories = res?.data?.staffName.map((item) => {
//           return item == null ? '' : item;
//         });
//         const totalStaffSales = res?.data?.totalStaffSales;
//         setStaffGraphData((pre) => ({
//           ...pre,
//           categories: categories,
//           series: totalStaffSales,
//         }));
//       }
//     } catch (error) { }
//   };

//   const handleApply = () => {
//     getData(activeBranchId);
//     getStaffGraphData(activeBranchId);
//     getData2(activeBranchId); // Call package bill overview API
//   };

//   const handleOnExport = () => {
//     // Check if data is defined and is an array
//     if (!Array.isArray(tableData)) {
//       console.error('Data is not an array or is undefined');
//       return;
//     }

//     // Flatten the data and extract fields from the services array
//     const flattenedData = tableData.map((item) => {
//       const flattenedItem = { ...item };

//       if (Array.isArray(item.services)) {
//         flattenedItem.serviceName = item.services
//           .map((serviceItem) => serviceItem.serviceName)
//           .join(', ');
//         flattenedItem.serviceProviders = item.services
//           .map((serviceItem) => serviceItem.serviceProvider?.name)
//           .join(', ');
//       }
//       if (Array.isArray(item.products)) {
//         flattenedItem.productName = item.products
//           .map((productItem) => productItem.productName)
//           .join(', ');
//         flattenedItem.productProvider = item.products
//           .map((productItem) => productItem.providerStaff?.name)
//           .join(', ');
//       }
//       return flattenedItem;
//     });

//     // Convert the flattened data to a sheet
//     let wb = XLSX.utils.book_new();
//     let ws = XLSX.utils.json_to_sheet(flattenedData);
//     XLSX.utils.book_append_sheet(wb, ws, 'Bills');
//     XLSX.writeFile(wb, 'Bills.xlsx');
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getData2(activeBranchId);
//       getData(activeBranchId);
//       getStaffGraphData(activeBranchId);
//     }
//   }, [activeBranchId]);

//   return (
//     <>
//       <PageContainer title="Sales Report" description="This is Sales Analytics">
//         {/* <DashboardCard2 title="Sales Report"> */}
//         {/* <DashboardCard2> */}
//           {loading ? (
//             <ShowLoader />
//           ) : (
//             <>
//               <div className="buttons-container">
//                 <div className="row">
//                   <div className="col-2">
//                     <FormControl fullWidth>
//                       <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Filter By</div>
//                       <Select
//                         name="Filter Options"
//                         onChange={(e) => setFilterBy(e.target.value)}
//                         value={filterBy}
//                       >
//                         {filterOptions?.map((sourceOption) => (
//                           <MenuItem key={sourceOption.value} value={sourceOption.value}>
//                             {sourceOption.label}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </div>

//                   {filterBy === 'customDate' && (
//                     <>
//                       <div className="col-2">
//                         <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>From</div>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="exampleInputEmail1"
//                           style={{ padding: '16px' }}
//                           aria-describedby="emailHelp"
//                           name="from"
//                           onChange={(e) => setFrom(e.target.value)}
//                           value={from}
//                         />
//                       </div>
//                       <div className="col-2">
//                         <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>To</div>
//                         <input
//                           type="date"
//                           max={new Date().toISOString().split('T')[0]}
//                           className="form-control"
//                           id="exampleInputEmail1"
//                           style={{ padding: '16px' }}
//                           aria-describedby="emailHelp"
//                           name="to"
//                           onChange={(e) => setTo(e.target.value)}
//                           value={to}
//                         />
//                       </div>
//                     </>
//                   )}
//                   <div className="col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
//                     <Button
//                       disabled={!!(filterBy === 'customDate' && (from === '' || to === ''))}
//                       variant="contained"
//                       // fullWidth
//                       color="primary"
//                       target="_blank"
//                       sx={{
//                         background: '#6174DD',
//                         padding: '14px 30px',
//                       }}
//                       onClick={handleApply}
//                     >
//                       Apply
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//               {tableData.length !== 0 ? (
//                 <>
//                   <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
//                     <button className="view-btn" onClick={handleOnExport}>
//                       Download Excel File
//                     </button>
//                   </div>
//                   {/* <div style={{display:"flex",justifyContent:"space-evenly", gap:"10rem"}}> */}
//                   <div style={{ display: "flex", justifyContent: "space-evenly", gap: "6rem" }}>
//                     <div>
//                       <LineGraph graphData={graphData} />
//                     </div>
//                     <div className="w-50" style={{ height: "50px" }}>
//                       <h6 style={{ fontWeight: 700, fontSize: '18px', textAlign: 'center' }}>
//                         Cash Collection Report
//                       </h6>
//                       <table style={{ width: "90%" }}>
//                         <thead className="table-thead">
//                           {/* <tr style={{height:"20px", fontSize:"12px"}}> */}
//                           <tr>
//                             {['Payment Mode', 'Amount'].map((h, index) => {
//                               return (
//                                 <th key={index} style={{ width: '100%' }}>
//                                   {h}
//                                 </th>
//                               );
//                             })}
//                           </tr>
//                         </thead>
//                         <tbody className="table-tbody">
//                           {cashData?.map((item, key) => (
//                             // <tr style={{height:"20px", fontSize:"12px"}} key={key}>
//                             <tr key={key}>
//                               <td style={{ width: '100%' }}>{item.paymentType}</td>
//                               <td style={{ width: '100%' }}>{item.totalAmounts}</td>
//                             </tr>
//                           ))}
//                           <hr />
//                           {/* <tr style={{ background: '#ebebe0', height:"20px", fontSize:"12px" }}> */}
//                           <tr style={{ background: '#ebebe0' }}>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>Total</td>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>
//                               {/* {Math.round(total.totalAmountPaid + total.taxAmount)} */}
//                               {/* {
//                                 Math.round(
//                                   total.totalAmountPaid + total.taxAmount + packagedata?.totalAmount + memberdata?.totalAmount -
//                                   cashData.filter(item => item.paymentType === 'Wallet').reduce((acc, item) => acc + item.totalAmounts, 0)
//                                 )
//                               } */}
//                               {/* {Math.round(totalWithoutWallet + packagedata?.totalAmount + memberdata?.totalAmount)} */}
//                               {Math.round(totalWithoutWallet)}
//                             </td>
//                           </tr>
//                           {/* <tr style={{ background: '#ebebe0',height:"20px", fontSize:"12px" }}> */}
//                           <tr style={{ background: '#ebebe0' }}>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>GST Collected</td>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>
//                               {Math.round(total.taxAmount)}
//                             </td>
//                           </tr>
//                           <hr />
//                           {/* <tr style={{ background: 'pink',height:"20px", fontSize:"12px" }}> */}
//                           <tr style={{ background: 'pink' }}>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>Dues</td>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>
//                               {Math.round(total.paidDues)}
//                             </td>
//                           </tr>
//                           {/* <tr style={{ background: 'lightgreen',height:"20px", fontSize:"12px" }}> */}
//                           <tr style={{ background: 'lightgreen' }}>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>Access Pay</td>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>
//                               {/* {Math.round(total.extraPay)} */}
//                               {Math.round(advance)}
//                             </td>
//                           </tr>
//                           <hr />
//                           {/* <tr style={{ background: '#ebebe0',height:"20px", fontSize:"12px" }}> */}
//                           <tr style={{ background: '#ebebe0' }}>
//                             {/* <td style={{ width: '100%', fontWeight: 'bold' }}>Package Sell</td> */}
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>Package</td>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>
//                               {Math.round(packagedata?.totalAmount)}
//                             </td>
//                           </tr>
//                           {/* <tr style={{ background: '#ebebe0',height:"20px", fontSize:"12px" }}> */}
//                           <tr style={{ background: '#ebebe0' }}>
//                             {/* <td style={{ width: '100%', fontWeight: 'bold' }}>Membership Sell</td> */}
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>Membership</td>
//                             <td style={{ width: '100%', fontWeight: 'bold' }}>
//                               {Math.round(memberdata?.totalAmount)}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>

//                   <div className="chart-container">
//                     <div>
//                       <h4
//                         style={{
//                           textAlign: 'center',
//                           fontSize: '16px',
//                           color: 'green',
//                           fontWeight: 'bold',
//                           marginTop: '50px',
//                         }}
//                       >
//                         Staff Collection Report{' '}
//                       </h4>
//                       <BarGraph data={staffGraphData} />
//                     </div>
//                   </div>

//                   <table className="table-container mt-3" ref={tableRef}>
//                     <thead className="table-thead">
//                       <tr>
//                         {[
//                           'Date of Bill',
//                           'Time of Bill',
//                           'Client Name',
//                           'Contact',
//                           'Total',
//                           'Paid',
//                           'Pending',
//                         ].map((h, index) => {
//                           return (
//                             <th key={index} style={{ width: '100%' }}>
//                               {h}
//                             </th>
//                           );
//                         })}
//                       </tr>
//                     </thead>
//                     <tbody className="table-tbody">
//                       {tableData.map((item) => {
//                         return (
//                           <tr key={item._id}>
//                             <td style={{ width: '100%' }}>{item?.dateOfBilling}</td>
//                             <td style={{ width: '100%' }}>{item?.timeOfBilling}</td>
//                             <td style={{ width: '100%' }}>{item?.clientName}</td>
//                             <td style={{ width: '100%' }}>
//                               {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
//                                 ? 'loading...'
//                                 : maskData(item?.clientNumber, branchData?.isMasked)}
//                             </td>
//                             <td style={{ width: '100%' }}>{item?.amountPayable}</td>
//                             <td style={{ width: '100%' }}>
//                               {item?.amountPaid
//                                 .reduce((acc, payment) => acc + parseFloat(payment.amount), 0)
//                                 .toString()}
//                             </td>

//                             <td style={{ width: '100%' }}>{item?.paidDues}</td>
//                           </tr>
//                         );
//                       })}
//                     </tbody>
//                   </table>
//                 </>
//               ) : (
//                 <p>There is no Bills for analytics</p>
//               )}
//             </>
//           )}
//         {/* </DashboardCard2> */}
//       </PageContainer>
//     </>
//   );
// }

// export default SalesReport;


//loader added
import React, { useEffect, useState } from 'react';
import './analytics.css';
import PageContainer from 'src/components/container/PageContainer';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Button, FormControl, MenuItem, Select, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import LineGraph from './LineGraph';
import BarGraph from './BarGraph';

import * as XLSX from 'xlsx';
import { useRef } from 'react';
import { baseUrl, jwtHeaders } from 'src/context/common/data';
import { Grid } from 'rsuite';
// import PackageBillData from 'src/views/Settings/GstBillsData/PackageBillData';
// import MembershipBillData from 'src/views/Settings/GstBillsData/MembershipBillData';
// import GstBillsPage from 'src/views/Settings/GstBillsData/GstBillsPage';
import GstBillsPage from '../../Settings/GstbillsData/GstBillsPage';
import PackageBillData from 'src/views/Settings/GstbillsData/PackageBillData';
import MembershipBillData from 'src/views/Settings/GstbillsData/MembershipBillData';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const filterOptions = [
  { label: 'Current Year', value: 'Current Year' },
  { label: 'Current Month', value: 'Current Month' },
  { label: 'Current Week (Sun-Sat)', value: 'Current Week' },
  { label: 'Today', value: 'Today' },
  { label: 'Custom Date', value: 'customDate' },
];

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

function SalesReport() {
  const { activeBranchId, fetchBillAnalytics, branchData, staffCollection, fetchMemberAnalytixs } =
    useMain();

  const [filterBy, setFilterBy] = useState('Today');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState({ labels: [], data: [] });
  const [staffGraphData, setStaffGraphData] = useState({ categories: [], series: [] });
  const [cashData, setCashData] = useState([]);
  const [packagedata, setPackagedata] = useState(null);
  const [memberdata, setMemberdata] = useState(null);
  const [advance, setAdvance] = useState(0);
  const [totalWithoutWallet, setTotalWithoutWallet] = useState(0);
  // const [activeComponent, setActiveComponent] = useState(null);

  // const handleButtonClick = (component) => {
  //   setActiveComponent(component);
  // };
  const [selectedView, setSelectedView] = useState('GstBillsPage'); // Default view
  const [loadingCurrentYear, setLoadingCurrentYear] = useState(false);

  const handleButtonClick = (view) => {
    setSelectedView(view);
  };

  useEffect(() => {
    // Fetch data here based on selectedView if necessary
  }, [selectedView]);

  const [total, setTotal] = useState({
    totalAmountPaid: 0,
    extraPay: 0,
    paidDues: 0,
    taxAmount: 0,
  });

  const tableRef = useRef();
  console.log(tableData);

  const getTotal = () => {
    let extraPay = 0;
    let totalAmountPaid = 0;
    let paidDues = 0;
    let taxAmount = 0;
    let totalWalletAmount = 0;
    let totalWithoutWallet = 0; // Initialize a variable for the amount without wallet payments

    const updatedTableData =
      tableData &&
      tableData.length > 0 &&
      tableData.map((data) => {
        const amountPaidArray = data?.amountPaid || [];
        const totalAmount = Number(data?.totalAmount) || 0;
        const subTotal = Number(data?.subTotal) || 0;

        // Filter out wallet payments and calculate total wallet amount
        const walletPayments = amountPaidArray.filter(payment => payment.paymentType === 'Wallet');
        const nonWalletPayments = amountPaidArray.filter(payment => payment.paymentType !== 'Wallet');

        const amountPaidWithoutWallet = nonWalletPayments.reduce((acc, payment) => acc + Number(payment.amount || 0), 0);
        const walletAmount = walletPayments.reduce((acc, payment) => acc + Number(payment.amount || 0), 0);
        totalWalletAmount += walletAmount;
        totalWithoutWallet += amountPaidWithoutWallet; // Add to the total without wallet

        // Calculate dues
        const duesAmount = totalAmount - (amountPaidWithoutWallet + walletAmount);

        if (duesAmount > 0) {
          paidDues += duesAmount;
        } else {
          extraPay += Math.abs(duesAmount);
        }
        totalAmountPaid += subTotal;
        taxAmount += Number(data.taxes?.taxType === 'Exclusive' ? subTotal * 0.18 : 0);
      });

    // Update states
    setTotal({ totalAmountPaid, taxAmount, paidDues, extraPay, totalWalletAmount });
    setTotalWithoutWallet(totalWithoutWallet); // Set the new total without wallet payments
  };

  useEffect(() => {
    getTotal();
  }, [tableData]);

  // console.log(total);
  const getData2 = async (activeBranchId) => {
    const queryParams = `&filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
      }`;
    try {
      setLoading(true);
      const result = await fetch(
        `${baseUrl}/api/billing/packagebilloverview?branchId=${activeBranchId}${queryParams ? queryParams : ''
        }`,
        {
          method: 'GET',
          headers: jwtHeaders(),
          redirect: 'follow',
        },
      );
      const res = await result.json();
      console.log(res);
      if (res.statusCode === 200) {
        setPackagedata(res?.data?.packageBills);
        setMemberdata(res?.data?.membershipBills);
      }
    } catch (error) { }
    setLoading(false);
  };

  // const getData = async (activeBranchId) => {
  //   const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
  //     }`;
  //   try {
  //     setLoading(true);
  //     const res = await fetchBillAnalytics(activeBranchId, queryParams);
  //     if (res.statusCode === 200) {
  //       setTableData(res.data.salesData.billDetails);
  //       setCashData(res.data.paymentTypeWiseAmount);
  //       setAdvance(res.data.totalAdvanceAmount)
  //       setGraphData((pre) => ({
  //         ...pre,
  //         labels: res.data.salesData.labels,
  //         data: res.data.salesData.data,
  //       }));
  //     } else {
  //     }
  //     // const res2 = await fetchMemberAnalytixs(activeBranchId, queryParams);
  //     // if (res2.statusCode === 200) {
  //     //   console.log(res2);
  //     // }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getData = async (activeBranchId) => {
    const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''}`;
    try {
      if (filterBy === 'Current Year') {
        setLoadingCurrentYear(true);
      } else {
        setLoading(true); // Generic loading state for other filters
      }

      const res = await fetchBillAnalytics(activeBranchId, queryParams);

      if (res.statusCode === 200) {
        setTableData(res.data.salesData.billDetails);
        setCashData(res.data.paymentTypeWiseAmount);
        setAdvance(res.data.totalAdvanceAmount);
        setGraphData((pre) => ({
          ...pre,
          labels: res.data.salesData.labels,
          data: res.data.salesData.data,
        }));
      } else {
        // Handle error or unsuccessful response
      }
    } catch (error) {
      // Handle error
    } finally {
      if (filterBy === 'Current Year') {
        setLoadingCurrentYear(false);
      } else {
        setLoading(false); // Clear the generic loading state
      }
    }
  };

  const getStaffGraphData = async (activeBranchId) => {
    const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
      }`;
    try {
      const res = await staffCollection(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        const categories = res?.data?.staffName.map((item) => {
          return item == null ? '' : item;
        });
        const totalStaffSales = res?.data?.totalStaffSales;
        setStaffGraphData((pre) => ({
          ...pre,
          categories: categories,
          series: totalStaffSales,
        }));
      }
    } catch (error) { }
  };

  // const handleApply = () => {
  //   getData(activeBranchId);
  //   getStaffGraphData(activeBranchId);
  //   getData2(activeBranchId); // Call package bill overview API
  // };

  // const handleApply = () => {
  //   setLoading(true);
  //   getData(activeBranchId);
  //   getStaffGraphData(activeBranchId);
  //   getData2(activeBranchId); // Call package bill overview API
  // };

  const handleApply = () => {
    if (filterBy === 'Current Year') {
      setLoadingCurrentYear(true);
    } else {
      setLoading(true);
    }

    getData(activeBranchId)
      .then(() => {
        if (filterBy === 'Current Year') {
          setLoadingCurrentYear(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        if (filterBy === 'Current Year') {
          setLoadingCurrentYear(false);
        } else {
          setLoading(false);
        }
      });

    getStaffGraphData(activeBranchId);
    getData2(activeBranchId); // Package bill overview API
  };


  const handleOnExport = () => {
    // Check if data is defined and is an array
    if (!Array.isArray(tableData)) {
      console.error('Data is not an array or is undefined');
      return;
    }

    // Flatten the data and extract fields from the services array
    const flattenedData = tableData.map((item) => {
      const flattenedItem = { ...item };

      if (Array.isArray(item.services)) {
        flattenedItem.serviceName = item.services
          .map((serviceItem) => serviceItem.serviceName)
          .join(', ');
        flattenedItem.serviceProviders = item.services
          .map((serviceItem) => serviceItem.serviceProvider?.name)
          .join(', ');
      }
      if (Array.isArray(item.products)) {
        flattenedItem.productName = item.products
          .map((productItem) => productItem.productName)
          .join(', ');
        flattenedItem.productProvider = item.products
          .map((productItem) => productItem.providerStaff?.name)
          .join(', ');
      }
      return flattenedItem;
    });

    // Convert the flattened data to a sheet
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, 'Bills');
    XLSX.writeFile(wb, 'Bills.xlsx');
  };

  useEffect(() => {
    if (activeBranchId) {
      getData2(activeBranchId);
      getData(activeBranchId);
      getStaffGraphData(activeBranchId);
    }
  }, [activeBranchId]);

  return (
    <>
      <PageContainer title="Sales Report" description="This is Sales Analytics">
        {/* <DashboardCard2 title="Sales Report"> */}
        {/* <DashboardCard2> */}
        {loading || (filterBy === 'Current Year' && loadingCurrentYear) ? (
          <ShowLoader />
        ) : (
          <>
            <div className="buttons-container">
              <div className="row">
                <div className="col-2">
                  <FormControl fullWidth>
                    <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Filter By</div>
                    <Select
                      name="Filter Options"
                      onChange={(e) => setFilterBy(e.target.value)}
                      value={filterBy}
                    >
                      {filterOptions?.map((sourceOption) => (
                        <MenuItem key={sourceOption.value} value={sourceOption.value}>
                          {sourceOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {filterBy === 'customDate' && (
                  <>
                    <div className="col-2">
                      <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>From</div>
                      <input
                        type="date"
                        className="form-control"
                        id="exampleInputEmail1"
                        style={{ padding: '16px' }}
                        aria-describedby="emailHelp"
                        name="from"
                        onChange={(e) => setFrom(e.target.value)}
                        value={from}
                      />
                    </div>
                    <div className="col-2">
                      <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>To</div>
                      <input
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        className="form-control"
                        id="exampleInputEmail1"
                        style={{ padding: '16px' }}
                        aria-describedby="emailHelp"
                        name="to"
                        onChange={(e) => setTo(e.target.value)}
                        value={to}
                      />
                    </div>
                  </>
                )}
                <div className="col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Button
                    disabled={!!(filterBy === 'customDate' && (from === '' || to === ''))}
                    variant="contained"
                    // fullWidth
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                      padding: '14px 30px',
                    }}
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
            {tableData.length !== 0 ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                  <button className="view-btn" onClick={handleOnExport}>
                    Download Excel File
                  </button>
                </div>
                {/* <div style={{display:"flex",justifyContent:"space-evenly", gap:"10rem"}}> */}
                <div style={{ display: "flex", justifyContent: "space-evenly", gap: "6rem" }}>
                  <div>
                    <LineGraph graphData={graphData} />
                  </div>
                  <div className="w-50" style={{ height: "50px" }}>
                    <h6 style={{ fontWeight: 700, fontSize: '18px', textAlign: 'center' }}>
                      Cash Collection Report
                    </h6>
                    <table style={{ width: "90%" }}>
                      <thead className="table-thead">
                        {/* <tr style={{height:"20px", fontSize:"12px"}}> */}
                        <tr>
                          {['Payment Mode', 'Amount'].map((h, index) => {
                            return (
                              <th key={index} style={{ width: '100%' }}>
                                {h}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="table-tbody">
                        {cashData?.map((item, key) => (
                          // <tr style={{height:"20px", fontSize:"12px"}} key={key}>
                          <tr key={key}>
                            <td style={{ width: '100%' }}>{item.paymentType}</td>
                            <td style={{ width: '100%' }}>{item.totalAmounts}</td>
                          </tr>
                        ))}
                        <hr />
                        {/* <tr style={{ background: '#ebebe0', height:"20px", fontSize:"12px" }}> */}
                        <tr style={{ background: '#ebebe0' }}>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>Total</td>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>
                            {/* {Math.round(total.totalAmountPaid + total.taxAmount)} */}
                            {/* {
                                Math.round(
                                  total.totalAmountPaid + total.taxAmount + packagedata?.totalAmount + memberdata?.totalAmount -
                                  cashData.filter(item => item.paymentType === 'Wallet').reduce((acc, item) => acc + item.totalAmounts, 0)
                                )
                              } */}
                            {/*{Math.round(totalWithoutWallet + packagedata?.totalAmount + memberdata?.totalAmount)}*/}
                            {Math.round(totalWithoutWallet)}
                          </td>
                        </tr>
                        {/* <tr style={{ background: '#ebebe0',height:"20px", fontSize:"12px" }}> */}
                        <tr style={{ background: '#ebebe0' }}>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>GST Collected</td>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>
                            {Math.round(total.taxAmount)}
                          </td>
                        </tr>
                        <hr />
                        {/* <tr style={{ background: 'pink',height:"20px", fontSize:"12px" }}> */}
                        <tr style={{ background: 'pink' }}>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>Dues</td>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>
                            {Math.round(total.paidDues)}
                          </td>
                        </tr>
                        {/* <tr style={{ background: 'lightgreen',height:"20px", fontSize:"12px" }}> */}
                        <tr style={{ background: 'lightgreen' }}>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>Access Pay</td>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>
                            {/* {Math.round(total.extraPay)} */}
                            {Math.round(advance)}
                          </td>
                        </tr>
                        <hr />
                        {/* <tr style={{ background: '#ebebe0',height:"20px", fontSize:"12px" }}> */}
                        <tr style={{ background: '#ebebe0' }}>
                          {/* <td style={{ width: '100%', fontWeight: 'bold' }}>Package Sell</td> */}
                          <td style={{ width: '100%', fontWeight: 'bold' }}>Package</td>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>
                            {Math.round(packagedata?.totalAmount)}
                          </td>
                        </tr>
                        {/* <tr style={{ background: '#ebebe0',height:"20px", fontSize:"12px" }}> */}
                        <tr style={{ background: '#ebebe0' }}>
                          {/* <td style={{ width: '100%', fontWeight: 'bold' }}>Membership Sell</td> */}
                          <td style={{ width: '100%', fontWeight: 'bold' }}>Membership</td>
                          <td style={{ width: '100%', fontWeight: 'bold' }}>
                            {Math.round(memberdata?.totalAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="chart-container">
                  <div>
                    <h4
                      style={{
                        textAlign: 'center',
                        fontSize: '16px',
                        color: 'green',
                        fontWeight: 'bold',
                        marginTop: '50px',
                      }}
                    >
                      Staff Collection Report{' '}
                    </h4>
                    <BarGraph data={staffGraphData} />
                  </div>
                </div>

                <table className="table-container mt-3" ref={tableRef}>
                  <thead className="table-thead">
                    <tr>
                      {[
                        'Date of Bill',
                        'Time of Bill',
                        'Client Name',
                        'Contact',
                        'Total',
                        'Paid',
                        'Pending',
                      ].map((h, index) => {
                        return (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="table-tbody">
                    {tableData.map((item) => {
                      return (
                        <tr key={item._id}>
                          <td style={{ width: '100%' }}>{item?.dateOfBilling}</td>
                          <td style={{ width: '100%' }}>{item?.timeOfBilling}</td>
                          <td style={{ width: '100%' }}>{item?.clientName}</td>
                          <td style={{ width: '100%' }}>
                            {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                              ? 'loading...'
                              : maskData(item?.clientNumber, branchData?.isMasked)}
                          </td>
                          <td style={{ width: '100%' }}>{item?.amountPayable}</td>
                          <td style={{ width: '100%' }}>
                            {item?.amountPaid
                              .reduce((acc, payment) => acc + parseFloat(payment.amount), 0)
                              .toString()}
                          </td>

                          <td style={{ width: '100%' }}>{item?.paidDues}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <p>There is no Bills for analytics</p>
            )}
          </>
        )}
        {/* </DashboardCard2> */}
      </PageContainer>
    </>
  );
}

export default SalesReport;