import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllBillings = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/getbranchwisebill/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();

  return resData;
};

export const fetchBillAnalytics = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/combinedbillapi/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchMemberAnalytixs = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/packagebilloverview/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchBillById = async (billId, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/getparticularbill/${billId}${branchId ? branchId : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchTotalSales = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/billing/gettotalsalesbranchwise/${branchId}`, {
    method: 'GET',
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

export const createBilling = async (data) => {
  const response = await fetch(`${baseUrl}/api/billing/create`, {
    method: 'POST',
    body: data,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

// export const deleteBilling = async (id, branchId) => {
//   const response = await fetch(
//     `${baseUrl}/api/billing/deletebill/${id}${branchId ? branchId : ''}`,
//     {
//       method: 'DELETE',
//       headers: jwtHeaders(),
//     },
//   );
//   const resData = await response?.json();

//   return resData;
// };

//new code
export const deleteBilling = async (id, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/deletebill?branchId=${branchId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...jwtHeaders(),
      },
      body: JSON.stringify({
        ids: [id],
        status: false,
      }),
    },
  );

  const resData = await response.json();
  return resData;
};

export const updateBill = async (billId, branchId, data) => {
  const response = await fetch(
    `${baseUrl}/api/billing/updatebill/${billId}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const clearRemainingAmount = async (billId, branchId, data) => {
  const response = await fetch(
    `${baseUrl}/api/billing/updateprevious/${billId}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const fetchPackageBills = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/packagebills/${branchId}${queryParams ? queryParams : ''}`, {
    method: 'GET',
    headers: jwtHeaders(),
  });

  const resData = await response.json();

  return resData;
};
// new code 
export const deletePackagebill = async(id,branchId) =>{
  const response = await fetch(    
    `${baseUrl}/api/billing/deletepackagebills?branchId=${branchId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...jwtHeaders(),
      },
      body: JSON.stringify({
        ids: [id],
        status: false,
      }),
    },
  );
  const resData = await response.json();
  return resData;
};

export const getGstData = async (activeBranchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/gstbilldetails/${activeBranchId}`, {
    method: 'GET',
    headers: jwtHeaders(),
  });

  const resData = await response.json();

  return resData;
};
