import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DiscountMembershipDetail from './DiscountMembershipDetail';
import ServiceMembershipDetail from './ServiceMembershipDetail';

const MembershipDetail = () => {
  const [data, setData] = useState({});
  const [allClinetsData, setAllClinetsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { membershipId } = useParams();

  const { activeBranchId, fetchMembershipDetail, setShowMessage, getBranch } = useMain();

  const getMembershipData = async (membershipId, activeBranchId) => {
    try {
      setLoading(true);
      const res = await fetchMembershipDetail(membershipId,`?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setData(res.data);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeBranchId && membershipId) {
      getMembershipData(membershipId, activeBranchId);
    }
    return () => {
      setData({});
    };
  }, [activeBranchId, membershipId]);

  const getBranchData = async (activeBranchId) => {
    try {
      const res = await getBranch(activeBranchId);
      if (res.statusCode === 200) {
        setAllClinetsData(res?.data?.client || []);
        
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (activeBranchId) {
      getBranchData(activeBranchId);
    }
    return () => {
      setAllClinetsData([]);
    };
  }, [activeBranchId]);
  return (
    <>
      {loading ? (
        <ShowLoader />
      ) : Object.keys(data).length === 0 ? (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'red',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          No Data Found
        </div>
      ) : data.membershipType === 'discountType' ? (
        <DiscountMembershipDetail data={data} allClinetsData={allClinetsData} />
      ) : (
        <ServiceMembershipDetail data={data} allClinetsData={allClinetsData} />
      )}
    </>
  );
};

export default MembershipDetail;
