import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        color: 'red',
        fontWeight: 'bold',
        fontSize: '18px',
      }}
    >
      No Data Found
    </div>
  );
};

export default NotFound;
