import React, { useState } from 'react';
import { Box } from '@mui/material';

const Subscription = ({ data }) => {

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container">
        <thead className="table-thead">
          <tr>
            {[
              'BranchName',
              'branchEmail',
              'Create Date',
              'activeStatus'
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          <tr>
            <td style={{ width: '100%' }}>{data?.branchName}</td>
            <td style={{ width: '100%' }}>{data?.branchEmail}</td>
            <td style={{ width: '100%' }}>{convertDateFormat(data?.createdAt)}</td>
            <td style={{ width: '100%' }}>{data?.activeStatus}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default Subscription;

function convertDateFormat(originalDate) {
  // Parse the original date string
  const dateObject = new Date(originalDate);

  // Extract day, month, and year components
  const day = dateObject.getUTCDate();
  const month = dateObject.getUTCMonth() + 1; // Months are zero-based, so add 1
  const year = dateObject.getUTCFullYear();

  // Format the components as dd-mm-yyyy
  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

  return formattedDate;
}