import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useMain } from 'src/views/hooks/useMain';
import CustomShowLoader from 'src/components/CustomComponents/CustomShowLoader';

const useStyles = makeStyles({
  container: {
    padding: '20px',
    backgroundColor: '#FAFAFA',
    borderRadius: '10px',
    border: '0.5px solid #CFCFCF',
  },
  linkText: {
    wordWrap: 'break-word',
    cursor: 'pointer',
    textDecoration: 'underline',
    maxWidth: '50%',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const WebAppointmentBookingLink = () => {
  const { setShowMessage, createWebAppointmentBookingLink, activeBranchId } = useMain();

  const classes = useStyles();
  const [bookingLink, setBookingLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGenerateLink = async () => {
    setLoading(true);
    try {
      const res = await createWebAppointmentBookingLink(activeBranchId);
      if (res.statusCode === 200) {
        setBookingLink(res.data);
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(bookingLink);
      setShowMessage({ message: 'Copied', messageType: 'success' });
    } catch (err) {
      // Handle error
    }
  };

  useEffect(() => {
    return () => {
      setBookingLink(null);
    };
  }, [activeBranchId]);

  return (
    <div className={classes.container}>
      <Typography variant="body1">Web Appointment Booking Link</Typography>
      {bookingLink ? (
        <Box display="flex" alignItems="center" mt={2}>
          <a
            href={bookingLink}
            className={classes.linkText}
            target="_blank"
            rel="noopener noreferrer"
          >
            {bookingLink}
          </a>
          <Button
            variant="contained"
            color="primary"
            onClick={copyToClipboard}
            sx={{ marginLeft: '10px' }}
          >
            Copy Link
          </Button>
        </Box>
      ) : (
        <Box mt={2}>
          {loading ? (
            <CustomShowLoader size={24} color={'#4caf50'} />
          ) : (
            <Button variant="contained" color="primary" onClick={handleGenerateLink}>
              Generate Link
            </Button>
          )}
        </Box>
      )}
    </div>
  );
};

export default WebAppointmentBookingLink;
