

import React, { useState, useEffect, useRef } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { useMain } from 'src/views/hooks/useMain';

const filterOptions = [
  { label: 'Current Year', value: 'Current Year' },
  { label: 'Current Month', value: 'Current Month' },
  { label: 'Current Week (Sun-Sat)', value: 'Current Week' },
  { label: 'Today', value: 'Today' },
  { label: 'Custom Date', value: 'customDate' },
];

const AppointmentAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [filterBy, setFilterBy] = useState('Today');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [staffFilter, setStaffFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef();

  const { fetchAppointmentAnalytics, activeBranchId } = useMain();

  useEffect(() => {
    getData(); // Call getData whenever filter options change
  }, [filterBy, from, to, staffFilter]);

  const getData = async () => {
  let queryParams = '';

  if (from && to) {
    queryParams += `?startDate=${from}&endDate=${to}`;
  }

  if (staffFilter) {
    queryParams += `&staff=${staffFilter}`;
  }

  try {
    setLoading(true);
    const res = await fetchAppointmentAnalytics(activeBranchId, queryParams);
    if (res.statusCode === 200) {
      let filteredData = res.data.flatMap(item =>
        item.appointments
          .filter(appointment => {
            if (filterBy === 'Today') {
              const todayDate = new Date().toISOString().split('T')[0];
              return appointment.dateOfAppointment === todayDate;
            } else if (filterBy === 'Current Month') {
              const currentDate = new Date();
              const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString().split('T')[0];
              const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString().split('T')[0];
              return appointment.dateOfAppointment >= firstDayOfMonth && appointment.dateOfAppointment <= lastDayOfMonth;
            } else if (filterBy === 'Current Week') {
              const currentDate = new Date();
              const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())).toISOString().split('T')[0];
              const lastDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6)).toISOString().split('T')[0];
              return appointment.dateOfAppointment >= firstDayOfWeek && appointment.dateOfAppointment <= lastDayOfWeek;
            } else if (filterBy === 'Current Year') {
              const currentDate = new Date();
              const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1).toISOString().split('T')[0];
              const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31).toISOString().split('T')[0];
              return appointment.dateOfAppointment >= firstDayOfYear && appointment.dateOfAppointment <= lastDayOfYear;
            }
            else if (filterBy === 'customDate') {
              // Handle custom date filter
              const startDate = new Date(from).toISOString().split('T')[0];
              const endDate = new Date(to).toISOString().split('T')[0];
              return appointment.dateOfAppointment >= startDate && appointment.dateOfAppointment <= endDate;
            } 
             else {
              // Handle other filter options if needed
              return true; // Return true for now to include all appointments
            }
          })
          .map(appointment => ({
            ...appointment,
            staffName: item.staff.name
          }))
      );
      setTableData(filteredData || []);
    } else {
      setTableData([]);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    setTableData([]);
  } finally {
    setLoading(false);
  }
};

  const handleApply = () => {
    getData(); // Call getData when the "Apply" button is clicked
  };

  return (
    <PageContainer
    title="Appointment Analytics"
    description="This page contains Appointment Analytics"
  >
    <DashboardCard2 title="Appointment Analytics">
      {loading ? (
        <ShowLoader />
      ) : (
        <>
          <div className="buttons-container">
            <div className="row">
              <div className="col-3">
                <FormControl fullWidth>
                  <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Filter</div>
                  <Select
                    name="sourceOfAppointment"
                    value={filterBy}
                    onChange={(e) => setFilterBy(e.target.value)}
                  >
                    {filterOptions.map((sourceOption) => (
                      <MenuItem key={sourceOption.value} value={sourceOption.value}>
                        {sourceOption.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {filterBy === 'customDate' && (
                <>
                  <div className="col-3">
                    <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>From</div>
                    <input
                      type="date"
                      className="form-control"
                      style={{ padding: '16px' }}
                      name="from"
                      onChange={(e) => setFrom(e.target.value)}
                      value={from}
                    />
                  </div>
                  <div className="col-3">
                    <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>To</div>
                    <input
                      type="date"
                      max={new Date().toISOString().split('T')[0]}
                      className="form-control"
                      style={{ padding: '16px' }}
                      name="to"
                      onChange={(e) => setTo(e.target.value)}
                      value={to}
                    />
                  </div>
                  <div className="col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button
                      disabled={from === '' || to === ''}
                      variant="contained"
                      fullWidth
                      color="primary"
                      target="_blank"
                      sx={{ background: '#6174DD' }}
                      onClick={handleApply} // Call handleApply when "Apply" button is clicked
                    >
                      Apply
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {tableData.length !== 0 ? (
            <table className="table-container" ref={tableRef}>
              <thead className="table-thead">
                <tr style={{display:'flex',justifyContent:'space-between'}}>
                  <th>Client Name</th>
                  <th>Client Number</th>
                  <th>Date of Appointment</th>
                  <th>Time of Appointment</th>
                  <th>Gender</th>
                  <th>Staff Name</th> {/* Added Staff Name column */}
                </tr>
              </thead>
              <tbody className="table-tbody">
                {tableData.map((item) => (
                  <tr key={item._id} style = {{display: 'flex',
                    justifyContent: 'space-between'}}>
                    <td>{item.clientName}</td>
                    <td>{item.clientNumber}</td>
                    <td>{item.dateOfAppointment}</td>
                    <td>{item.timeOfAppointment}</td>
                    <td>{item.gender}</td>
                    <td>{item.staffName}</td> {/* Display Staff Name */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </>
      )}
    </DashboardCard2>
  </PageContainer>
  );
};

export default AppointmentAnalytics;