import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchNotifications = async (id) => {
  const response = await fetch(`${baseUrl}/api/client/getnotifications/${id}`, {
    // headers,
    headers:jwtHeaders(),
  });
  const respData = response.json();
  return respData;
};
