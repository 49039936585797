import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import TextInputs from 'src/components/inputs/TextInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const initialValue = {
  membershipName: '',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  // membershipType: 'discountType',
  discount: {
    discountOnService: '',
    serviceDiscountType: '%',
    discountOnProduct: '',
    productDiscountType: '%',
    // discountOnPackages: '',
    // packageDiscountType: '%',
    minBillAmount: '',
  },
};

const DiscountType = ['%', 'INR'];

const DiscountTypeFieldData = ({ data, setData, getData, state }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [updateFormData, setUpdateFormData] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { deleteMembership, updateMembership, setShowMessage, activeBranchId } = useMain();

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleDetails = (id) => {
    navigate(`/membershipdetail/${id}`, { state });
  };

  const handleUpdate = (item) => {
    setSelectedItemId(item._id);
    const { membershipName, membershipPrice, duration, rewardPointsOnPurchase, discount } = item;
    setUpdateFormData({
      ...initialValue,
      membershipName,
      membershipPrice,
      duration,
      rewardPointsOnPurchase,
      discount: {
        ...initialValue.discount,
        ...discount,
      },
    });
    setOpenDialogue(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({
      ...prev,
      discount: {
        ...prev.discount,
        [name]: value,
      },
    }));
  };

  const handleUpdateDiscountTypeMembership = async () => {
    try {
      setLoading(true);
      const formData = {
        ...updateFormData,
        discount: {
          ...updateFormData.discount,
          discountOnService: updateFormData.discount.discountOnService || '',
          discountOnProduct: updateFormData.discount.discountOnProduct || '',
          // discountOnPackages: updateFormData.discount.discountOnPackages || '',
          minBillAmount: updateFormData.discount.minBillAmount || '',
        },
      };
      const res = await updateMembership(selectedItemId, updateFormData, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Membership Details updated successfully',
          messageType: 'success',
        });
        getData();
        setUpdateFormData(initialValue);
      } else {
        setShowMessage({
          message: res.message || 'Membership Details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  const handleDeleteField = async (id) => {
    try {
      const res = await deleteMembership(id, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setData(data.filter((item) => item._id !== id));
        setShowMessage({
          message: res.message || 'Membership deleted successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Membership not deleted', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setOpenDialogue(false);
    }
  };

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {/* <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Membership Name</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Membership Price</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Validity</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Discount on Services</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Discount on Products</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Discount on Packages</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Min Billed Amount</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Reward Points</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell> */}
            {[
              'Membership Name',
              'Membership Price',
              'Validity',
              'Discount on Services',
              'Discount on Products',
              'Min Billed Amount',
              'Rewards Points',
              'Actions',
            ].map((h,index)=>(
              <th key={index} style={{ width: '15%' }}>
              {h}
            </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => (
              <tr key={item._id}>
              <td style={{ width: '100%' }}>{TrimData(item?.membershipName)}</td>
              <td style={{ width: '100%' }}>{item?.membershipPrice}</td>
              <td style={{ width: '100%' }}>{item?.duration}</td>
              <td style={{ width: '100%' }}>{`${item?.discount?.discountOnService}   ${item?.discount?.serviceDiscountType}`}</td>
              <td style={{ width: '100%' }}>{`${item?.discount?.discountOnProduct}   ${item?.discount?.productDiscountType}`}</td>
              <td style={{ width: '100%' }}>{item?.discount?.minBillAmount}</td>
              <td style={{ width: '100%' }}>{item?.rewardPointsOnPurchase}</td>
              <td style={{ width: '100%' }}>
                <TableCells
                  type="button"
                  handleDeleteField={() => handleDeleteField(item._id)}
                  handleUpdate={() => handleUpdate(item)}
                  handleDetails={() => handleDetails(item._id)}
                  field={item?._id}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>DiscountType Membership Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update membership details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title="Membership Name"
                  name="membershipName"
                  value={updateFormData?.membershipName}
                  handleChange={handleChange}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title="Duration (in Months)"
                  name="duration"
                  value={updateFormData?.duration}
                  handleChange={handleChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title="Membership Price"
                  name="membershipPrice"
                  value={updateFormData?.membershipPrice}
                  handleChange={handleChange}
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title="Discount Service"
                      name="discountOnService"
                      value={updateFormData?.discount?.discountOnService}
                      handleChange={handleDiscountChange}
                      type="number"
                      min={1}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '5px', fontWeight: 'bold',color:'black' }}>Discount Type</div>
                    <Select
                      name="serviceDiscountType"
                      value={updateFormData?.discount?.serviceDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title="Discount Product"
                      name="discountOnProduct"
                      value={updateFormData?.discount?.discountOnProduct}
                      handleChange={handleDiscountChange}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '5px', fontWeight: 'bold',color:'black' }}>Discount Type</div>
                    <Select
                      name="productDiscountType"
                      value={updateFormData?.discount?.productDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title="Discount Package"
                      name="discountOnPackages"
                      value={updateFormData.discount.discountOnPackages}
                      handleChange={handleDiscountChange}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>Discount Type</div>
                    <Select
                      name="packageDiscountType"
                      value={updateFormData.discount.packageDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title="Reward Points"
                  name="rewardPointsOnPurchase"
                  value={updateFormData?.rewardPointsOnPurchase}
                  handleChange={handleChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title="Minimum Bill Amount"
                  name="minBillAmount"
                  value={updateFormData?.discount?.minBillAmount}
                  handleChange={handleDiscountChange}
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <Button variant="contained" onClick={handleCloseDialogue} sx={{ background: '#6174DD' }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleUpdateDiscountTypeMembership} sx={{ background: '#6174DD' }}>
                Update
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DiscountTypeFieldData;
