import { MoreVertRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  Typography,
} from '@mui/material';
import { IconDots } from '@tabler/icons';
import React, { useState } from 'react';
// import { Link } from 'react-router-dom'; // Import the Link component from React Router

const TableCells = ({
  type,
  data,
  background,
  borderRadius,
  color,
  handleDetails,
  handleDeleteField,
  handleUpdate,
  field,
  textAlign,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDialogue(true);
    handleCloseMenu();
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  return (
    <>
      {type === 'Heading' ? (
        <TableCell
          sx={{
            background,
            borderRadius: borderRadius ? borderRadius : '0px',
            color,
          }}
        >
          <Typography variant="subtitle2" fontSize={12} fontWeight={600}>
            {data}
          </Typography>
        </TableCell>
      ) : type === 'content' ? (
        <TableCell
          sx={{
            background,
            borderRadius: borderRadius ? borderRadius : '0px',
            color,
            padding: 0,
            my: '10px',
            textAlign,
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '500',
            }}
          >
            {data}
          </Typography>
        </TableCell>
      ) : (
        <TableCell
          sx={{
            background,
            padding: 0,
            borderRadius: borderRadius ? borderRadius : '0px',
            color,
            my: '10px',
          }}
        >
          <div>
            <IconButton
              aria-controls="dropdown-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              size="small"
            >
              <IconDots />
            </IconButton>
            <Menu
              id="dropdown-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {handleDetails && <MenuItem onClick={() => handleDetails(field)}>Details</MenuItem>}
              {handleUpdate && (
                <MenuItem
                  onClick={() => {
                    handleUpdate();
                    handleCloseMenu();
                  }}
                >
                  Update
                </MenuItem>
              )}
              {handleDeleteField && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
            </Menu>
          </div>
          <Dialog open={openDialogue} onClose={handleCloseDialogue}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to delete this data?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogue}>Cancel</Button>
              <Button
                onClick={() => {
                  handleDeleteField(field);
                  handleCloseDialogue();
                }}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      )}
    </>
  );
};

export default TableCells;
