import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';

import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import { Box } from '@mui/system';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import ClinetSuggestion from './ClinetSuggestion';
import { useLocation } from 'react-router';
import RadioInputs from 'src/components/inputs/RadioInputs';

const DiscountMembershipDetail = ({ data, allClinetsData }) => {
  const [formData, setFormData] = useState({ clientNumber: '', membershipId: data._id, paymentType: '', clientName: '', gender: '' });
  const [clientSuggested, setClientSuggested] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNewClientFields, setShowNewClientFields] = useState(false);
  const [clientExists, setClientExists] = useState(true);
  const { buyMembership, activeBranchId, setShowMessage } = useMain();

  const { state } = useLocation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
    if ((name === 'clientNumber' || name === 'clientName') && !showNewClientFields) {
      updateSuggested(name, value);
    }
  };

  const updateSuggested = (fieldName, inputValue) => {
    const suggestions = allClinetsData.filter((product) =>
      product[fieldName === 'clientName' ? 'clientName' : 'clientNumber']
        ?.toLowerCase()
        .includes(inputValue.toLowerCase())
    );
    setClientSuggested(suggestions);
    setClientExists(suggestions.length > 0);
  };

  const handleSuggestedClick = (client) => {
    setFormData((pre) => ({ ...pre, clientNumber: client.clientNumber }));
    setClientSuggested([]);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setFormData((pre) => ({ ...pre, clientNumber: '', membershipId: data._id, paymentType: '' }));
    setClientSuggested([]);
  };

  const handleOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const handleBuyMembership = async () => {
    const { clientNumber, paymentType } = formData;
    if (clientNumber === '' || clientNumber.length !== 10)
      return setShowMessage({
        message: 'Client Number is required or invalid',
        messageType: 'error',
      });
    if (paymentType === '')
      return setShowMessage({
        message: 'Payment Type is required',
        messageType: 'error',
      });
    let requestBody = { clientNumber, membershipId: formData.membershipId, paymentType };
    if (!clientExists) {
      requestBody = { ...formData };
    }

    try {
      setLoading(true);
      const res = await buyMembership(activeBranchId, requestBody);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'SuccessFully Bought Membership',
          messageType: 'success',
        });
        setFormData({ clientNumber: '', membershipId: '', paymentType: '' });
      } else {
        setShowMessage({
          message: res.message || 'Error Occurred while Buying Membership',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      handleCloseDialogue();
      setLoading(false);
    }
  };


  const handleAmountPaidInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  useEffect(() => {
    if (state) {
      setFormData((pre) => ({ ...pre, clientNumber: state?.clientNumber }));
    }
  }, [state]);
  // console.log(formData, "formDataa")
  const handleAddNew = () => {
    setShowNewClientFields(true);
    setClientExists(false);
    setFormData((prev) => ({ ...prev, clientName: '', gender: '' }));
  };

  return (
    <PageContainer title="Membership Details" description="This is service type membership details">
      <DashboardCard title="Membership Details">
        <Grid container xs={12}>
          <Grid container xs={8} spacing={4}>
            <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Membership Name: <strong>{data.membershipName}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Membership Price: <strong>{data.membershipPrice}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Membership duration: <strong>{data.duration} month</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Reward Points: <strong>{data.rewardPointsOnPurchase}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Available Count: <strong>{data.discount.availableCount}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Min Bill Amount: <strong>{data.discount.minBillAmount}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <button
              style={{
                float: 'right',
                backgroundColor: '#6174DD',
                padding: '10px 15px',
                color: '#fff',
                borderRadius: '5px',
                fontSize: '14px',
                border: 'none',
              }}
              onClick={handleOpenDialogue}
            >
              Buy Membership
            </button>
          </Grid>

          <Grid item xs={12}>
            <table className="table-container mt-3">
              <thead className="table-thead">
                <tr>
                  {['Discount on Service', 'Discount on Products'].map(
                    (h, index) => {
                      return (
                        <th key={index} style={{ width: '100%' }}>
                          {h}
                        </th>
                      );
                    },
                  )}
                </tr>
              </thead>
              <tbody className="table-tbody">
                <tr>
                  <td
                    style={{ width: '100%' }}
                  >{`${data?.discount?.discountOnService}   ${data?.discount?.serviceDiscountType}`}</td>
                  <td
                    style={{ width: '100%' }}
                  >{`${data?.discount?.discountOnProduct}   ${data?.discount?.productDiscountType}`}</td>
                  {/* <td
                    style={{ width: '100%' }}
                  >{`${data?.discount?.discountOnPackages}   ${data?.discount?.packageDiscountType}`}</td> */}
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Dialog
          open={openDialogue}
          onClose={handleCloseDialogue}
          maxWidth="md"
          sx={{
            '& .MuiDialog-paper': {
              width: '600px', // set the desired width
              minHeight: '76vh', // set the desired max height (in this case, 80% of the viewport height)
            },
          }}
        >
          <DialogTitle>Buy Membership</DialogTitle>
          <DialogContent>
            <DialogContentText>Here you can Buy membership.</DialogContentText>
            <DialogContentText>
              <Grid item xs={6} style={{ padding: '1rem 0rem' }}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Membership Name: <strong>{data.membershipName}</strong>
                </Typography>
              </Grid>
            </DialogContentText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box style={{ borderBottom: 'none' }}>
                    <TextInputs
                      title={'Client Number'}
                      name={'clientNumber'}
                      placeholder="client number"
                      value={formData?.clientNumber}
                      handleChange={handleChange}
                      type={'text'}
                    />
                  </Box>
                </Grid>
                {!clientExists && !showNewClientFields && (
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: '#6174DD' }}
                      onClick={handleAddNew}
                    >
                      Add New
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {clientExists && !showNewClientFields && (
                    <ClinetSuggestion
                      data={clientSuggested}
                      handleSuggestedClick={handleSuggestedClick}
                    />
                  )}
                  {showNewClientFields && !clientExists && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                      <Box sx={{ width: '15rem' }}>
                        <TextInputs
                          title={'Client Name'}
                          name={'clientName'}
                          value={formData?.clientName}
                          handleChange={handleChange}
                          type={'text'}
                        />
                      </Box>
                      <Box sx={{ width: '15rem' }}>
                        <RadioInputs
                          title={'Gender'}
                          name={'gender'}
                          value={formData?.gender}
                          handleChange={handleChange}
                          options={[
                            { title: 'Male', value: 'Male' },
                            { title: 'Female', value: 'Female' },
                          ]}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box sx={{ width: '15rem' }}>
                    <Typography component="label"><strong>Payment Type</strong></Typography>
                    <select

                      className="form-select"
                      aria-label="Default select example"
                      data-validation="required"
                      name="paymentType"
                      value={formData.paymentType || ''}
                      onChange={handleAmountPaidInputChange}
                      style={{
                        display: 'block',
                        width: '100%',
                        padding: '13px',
                        fontSize: '16px',
                        margin: '8px 0',
                        borderRadius: '4px',
                      }}
                    >
                      <option value="">
                        --Select--
                      </option>
                      <option value="Cash">Cash</option>
                      <option value="Credit/Debit Card">Credit/Debit card</option>
                      <option value="Paytm">Paytm</option>
                      <option value="PhonePe">PhonePe</option>
                      <option value="Gpay">Gpay</option>
                    </select>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextInputs
                    title={'Amount Paid'}
                    name={'amountPaid'}
                    placeholder="amount paid"
                    value={data?.membershipPrice}
                    handleChange={handleAmountPaidInputChange}
                    type={'text'}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialogue}>Cancel</Button>
            <Button style={{ backgroundColor: '#6174DD', color: 'white' }} onClick={handleBuyMembership} disabled={loading}>
              {loading ? 'Buying...' : 'Buy Membership'}
            </Button>
          </DialogActions>

        </Dialog>
        {loading && <ShowLoader />}
      </DashboardCard>
    </PageContainer >
  );
};

export default DiscountMembershipDetail;
