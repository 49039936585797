
import { baseUrl, headers, jwtHeaders, logoutUtil } from '../common/data';

export const fetchAllCoupon = async (branchId, queryParams) => {
    const response = await fetch(`${baseUrl}/api/coupon/couponByBranch/${branchId}${queryParams ? queryParams : ''}`, {
        method: "GET",
        headers: jwtHeaders(),
        redirect: "follow",
    });
    if (response?.status === 401) {
        logoutUtil();
    }
    const resData = await response?.json();

    return resData;
};

export const createCoupon = async (data) => {

    const response = await fetch(`${baseUrl}/api/coupon/create`, {
        method: 'POST',
        body: JSON.stringify(data),
        //   headers: headers,
        headers: jwtHeaders(),
    });
    const resData = await response?.json();

    return resData;

};

export const updateCoupon = async (id, data, branchId) => {

    const response = await fetch(`${baseUrl}/api/coupon/update/${id}${branchId ? branchId : ''}`, {
        method: "PUT",
        body: JSON.stringify(data),
        // headers: headers
        headers:jwtHeaders(),
    })
    const resData = response?.json()

    return resData

}


export const deleteCoupon = async (id, branchId) => {

    const response = await fetch(`${baseUrl}/api/coupon/delete/${id}${branchId ? branchId : ''}`, {
        method: 'DELETE',
        // headers: headers,
        headers:jwtHeaders(),
    })
    const resData = await response?.json();

    return resData;

};
