import React, { useState } from 'react';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Avatar,
} from '@mui/material';
import { useMain } from '../hooks/useMain';

// const banners = [
//     {
//         "id": "001",
//         "img": 'coming...',
//         "title": "10 Tips for Improving Your Website's SEO",
//         "description": "10 Tips for improving your website using seo s sare the si",
//         "author": "John Doe",
//         "createdAt": "2022-01-01",
//         "actions": {
//             "edit": "/admin/blogs/edit/001",
//             "delete": "/admin/blogs/delete/001",
//             "view": "/banner/10-tips-for-improving-your-websites-seo"
//         }
//     },
//     {
//         "id": "002",
//         "img": 'coming..,',
//         "title": "The Benefits of Social Media Marketing",
//         "description": "10 Tips for improving your website using seo s sare the si",
//         "author": "Jane Smith",
//         "category": "Marketing",
//         "tags": ["social media", "digital marketing"],
//         "createdAt": "2022-02-01",
//         "status": "Published",
//         "views": 250,
//         "actions": {
//             "edit": "/admin/blogs/edit/002",
//             "delete": "/admin/blogs/delete/002",
//             "view": "/banner/the-benefits-of-social-media-marketing"
//         }
//     },
//     {
//         "id": "003",
//         "img": 'coming...',
//         "title": "How to Create Engaging Content for Your Website",
//         "description": "10 Tips for improving your website using seo s sare the si",
//         "author": "Mike Johnson",
//         "category": "Content Creation",
//         "tags": ["content", "website"],
//         "createdAt": "2022-03-01",
//         "status": "Draft",
//         "views": 0,
//         "actions": {
//             "edit": "/admin/blogs/edit/003",
//             "delete": "/admin/blogs/delete/003",
//             "view": "/admin/blogs/view/003"
//         }
//     }
// ]

export const TrimData = (data) => {
    if (data.length < 25)
        return data;
    return data.substring(0, 15) + '...' + data.substring(data?.length - 10);
};


const Banners = ({ banners, setBanners }) => {

    const [openDialogue, setOpenDialogue] = useState(false);
    const [alert, setAlert] = useState('');

    const { deleteBanner } = useMain();

    const handleCloseDialogue = () => {
        setOpenDialogue(false);
    };

    const handleDeleteField = async (id) => {
        const res = await deleteBanner(id);

        if (res?.status === 'success') {
            setBanners(banners?.filter((banner) => banner?._id !== id));
            setOpenDialogue(false);
            setAlert('Banner deleted successfully')
        }
        else {
            setOpenDialogue(false);
            setAlert(res?.error?.message)
        }
    };

    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <Snackbar
                open={alert === '' ? false : true}
                autoHideDuration={6000}
                onClose={() => {
                    setAlert('');
                }}
                severity="error"
                message={alert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />
            <Table
                aria-label="simple table"
                sx={{
                    whiteSpace: "nowrap",
                    mt: 2
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Id
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Image
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Created At
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="subtitle2" fontWeight={600}>
                                Action
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {banners.map((banner) => (
                        <TableRow key={banner?._id}>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {banner?._id}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {/* {banner?.banner} */}
                                    <Avatar style={{ borderRadius: '5px', width: '150px', height: '100px' }} alt={banner.name} src={banner?.bannerImage?.url} />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {banner?.createdAt ? new Date(banner?.createdAt).toLocaleDateString() : ''}
                            </TableCell>
                            <TableCell align={'right'}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: '1rem'
                                    }}
                                >
                                    {/* <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "success.main",
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: 'success.dark',
                                            },
                                        }}
                                    >
                                        Edit
                                    </Button> */}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "error.main",
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: 'error.dark',
                                            },
                                        }}
                                        onClick={() => setOpenDialogue(banner?._id)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Dialog open={openDialogue} onClose={handleCloseDialogue}>
                <DialogTitle>Delete Banner</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this data?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogue}>Cancel</Button>
                    <Button onClick={() => handleDeleteField(openDialogue)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Banners;
