import React, { useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import TableCells from 'src/components/Tables/TableCells';
import { Search } from '@mui/icons-material';


export const TrimData = (data) => {
    if (data?.length < 25)
        return data;
    return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};


const FieldData = ({ data }) => {
    // const [isEdit, setIsEdit] = useState(false);
    // const [openDialogue, setOpenDialogue] = useState(false);
    // const [alert, setAlert] = useState('');

    // const { deleteProduct } = useMain();

    // const navigate = useNavigate();

    // const handleCloseDialogue = () => {
    //     setOpenDialogue(false);
    // };

    // const handleDeleteField = async (id) => {
    
    //     const res = await deleteProduct(id);

    //     if (res?.status === 'success') {
    //         setProducts(data?.filter((blog) => blog?._id !== id));
    //         setOpenDialogue(false);
    //         setAlert('Product deleted successfully')
    //     }
    //     else {
    //         setOpenDialogue(false);
    //         setAlert(res?.error?.message)
    //     }
    // };

    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            {/* <Snackbar
                open={alert === '' ? false : true}
                autoHideDuration={6000}
                onClose={() => {
                    // setAlert('');
                }}
                severity="error"
                message={alert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            /> */}

            <Table
                aria-label="simple table"
                sx={{
                    whiteSpace: "nowrap",
                    mt: 2
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            background: '#6174DD',
                        }}
                    >
                        <TableCells
                            borderRadius={'10px 0 0 10px'}
                            color={'white'}
                            type={'Heading'}
                            data={'AppointmentId'}
                        />
                        {
                            [
                                'Description',
                                // 'Rating',
                            ].map((item, index) =>
                                <TableCells
                                    key={index}
                                    color={'white'}
                                    type={'Heading'}
                                    data={item}
                                />
                            )
                        }
                        {/* <TableCells
                            borderRadius={'0px 10px 10px 0px'}
                            color={'white'}
                            type={'Heading'}
                            data={'Actions'}
                        /> */}
                        <TableCells
                            borderRadius={'0px 10px 10px 0px'}
                            color={'white'}
                            type={'Heading'}
                            data={'Rating'}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item) => (
                        <TableRow
                            key={item?._id}
                        >
                            <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={item?.appointmentId?._id}
                            />
                            <TableCells
                                borderRadius={'10px 0 0 10px'}
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={TrimData(item?.description)}
                            />
                            <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={item?.rating}
                            />
                            {/* <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'button'}
                            /> */}
                            {/* <TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                                    {
                                        item?.priceVarient?.map((item) =>
                                            <Chip
                                                key={item?._id}
                                                sx={{
                                                    px: "4px",
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                }}
                                                size="small"
                                                label={`${item?.varient} Inch x (Rs.${item?.price})`}
                                            ></Chip>
                                        )
                                    }
                                </div>
                            </TableCell>
                            <TableCell>
                                <Chip
                                    sx={{
                                        px: "4px",
                                        backgroundColor: item.status === 'available' ? 'primary.main' : 'error.main',
                                        color: "#fff",
                                    }}
                                    size="small"
                                    label={item?.availability > 0 ? 'available' : 'unavailable'}
                                ></Chip>
                            </TableCell>
                            <TableCell>
                                <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                    {new Date(item?.createdAt)?.toLocaleDateString()}
                                </Typography>
                            </TableCell> */}
                            {/* <TableCell>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: '1rem'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "success.main",
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: 'success.dark',
                                            },
                                        }}
                                    // onClick={() => navigate(`/editproduct/${item?._id}`)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "error.main",
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: 'error.dark',
                                            },
                                        }}
                                    // onClick={() => setOpenDialogue(item?._id)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* <Dialog open={openDialogue} onClose={handleCloseDialogue}>
                <DialogTitle>Delete item</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this data?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogue}>Cancel</Button>
                    <Button onClick={() => handleDeleteField(openDialogue)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}
        </Box>
    );
};

export default FieldData;