import { Box, Button, Typography } from '@mui/material';
import { useMain } from '../hooks/useMain';

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const FieldData = ({ data, setData, getData, page, setPage, hasNextPage }) => {
  const { branchData } = useMain();
  const onPrevButtonHandler = async (e) => {
    if (page !== 1) setPage((page) => page - 1);
  };

  const onNextButtonHandler = async (e) => {
    if (hasNextPage) setPage((page) => page + 1);
  };

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {['Name', 'Number', 'Email', 'CreatedAt'].map((h, index) => (
              <th key={index} style={{ width: '100%' }}>
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => (
            <tr key={item._id}>
              <td style={{ width: '100%' }}>{item?.traits?.name}</td>

              <td style={{ width: '100%' }}>
                {typeof branchData?.isMasked !== 'boolean' || !item?.phone_number
                  ? 'loading...'
                  : maskData(item?.phone_number, branchData?.isMasked)}
              </td>
              <td style={{ width: '100%' }}>{item?.traits?.email}</td>
              <td style={{ width: '100%' }}>{getCurrentDateInFormat(item?.created_at_utc)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center', // Align items in the center
            gap: '10px',
            margin: '10px 0px 0px 0px',
          }}
        >
          <div style={{ width: '80px' }}>
            <Button
              variant="contained"
              fullWidth
              target="_blank"
              onClick={onPrevButtonHandler}
              disabled={page === 1 ? true : false}
            >
              Previous
            </Button>
          </div>

          <Typography
            variant="subtitle1"
            fontWeight={550}
            component="label"
            alignContent="center"
            textAlign={'center'}
          >
            {page}
          </Typography>

          <div style={{ width: '80px' }}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={onNextButtonHandler}
              disabled={hasNextPage ? false : true}
            >
              Next
            </Button>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default FieldData;

function getCurrentDateInFormat(created_at_utc) {
  const date = new Date(created_at_utc);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
