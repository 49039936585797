import React, { useState } from 'react';
import { HANDLE_COUPON_INPUT_CHANGE } from 'src/reducers/billReducer/billConstent';

const container = {
  display: 'flex',
  flexDirection: 'column',
  width: '30vw',
  minHeight: '80vh',
  padding: '30px',
  gap: '10px',
};
const couponbox = {
  padding: '18px',
  // border: '1px solid black',
  borderRadius: '5px',
  transition: 'border-color 0.3s, transform 0.3s',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
};

const CouponCard = ({ data, state, dispatch, onClose }) => {
  const [onHover, setOnHover] = useState(null);

  const handleCouponClick = (item) => {
    dispatch({ type: HANDLE_COUPON_INPUT_CHANGE, payload: { item } });
    onClose();
  };

  return (
    <div style={{ ...container, overflow: 'auto' }}>
      {data?.map((item) => (
        <div
          key={item._id}
          style={{
            ...couponbox,
            ...(onHover === item._id && {
              border: '1px solid blue',
              transform: 'scale(1.05)',
            }),
          }}
          onMouseEnter={() => {
            setOnHover(item._id);
          }}
          onMouseLeave={() => {
            setOnHover(null);
          }}
        >
          <h1 style={{ fontSize: '18px' }}>
            {item.discountType === 'INR'
              ? `Save ${item.discount} ${item.discountType}`
              : `${item.discount} ${item.discountType} OFF up to ₹${item.maximumDiscountAmount}`}
          </h1>
          {calculatePriceSubTotal([...state.services, ...state.products]) <
          item.minimumBillAmount ? (
            <span
              style={{
                color: 'red',
                backgroundColor: 'whitesmoke',
                fontWeight: 'bold',
                padding: '8px 0',
                fontSize: '13px',
                borderRadius: '4px',
              }}
            >
              Add items worth ₹
              {`${
                item.minimumBillAmount -
                calculatePriceSubTotal([...state.services, ...state.products])
              }`}{' '}
              more to unlock
            </span>
          ) : item.discountType === '%' ? (
            <span style={{ fontWeight: 'bold' }}>
              save ₹
              {calaculatePercent(
                calculatePriceSubTotal([...state.services, ...state.products]),
                item.discount,
                item.maximumDiscountAmount,
              )}
            </span>
          ) : (
            <span style={{ fontWeight: 'bold' }}>save save ₹{item.discount}</span>
          )}
          {calculatePriceSubTotal([...state.services, ...state.products]) >
            item.minimumBillAmount &&
            (state.coupon.couponId === item._id ? (
              <h1 style={{ color: 'green', fontSize: '16px' }}>
                Congratulations! Your Coupons Have Been Applied.
              </h1>
            ) : (
              <button
                onClick={() => handleCouponClick(item)}
                style={{
                  width: '95%',
                  fontWeight: 'bold',
                  backgroundColor: '#6174DD',
                  color: '#fff',
                  borderRadius: '5px',
                  border: 'none',
                  fontSize: '12px',
                }}
              >
                TAP TO APPLY
              </button>
            ))}
        </div>
      ))}
    </div>
  );
};

export default CouponCard;

function calaculatePercent(amount, percent, maximumDiscountAmount) {
  let discount = (amount * percent) / 100;
  discount = discount > maximumDiscountAmount ? maximumDiscountAmount : discount;
  return discount;
}

function calculatePriceSubTotal(items) {
  return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
}
