import React from 'react';
import { HANDLE_PACKAGE_INPUT_CHANGE} from 'src/reducers/billReducer/billConstent';

const PackageCard = ({ data, state, dispatch, onClose }) => {
  
    const container = {
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
        minHeight: '90vh',
        padding: '30px',
        gap: '10px',
    };

    const button = {
        background: '#6174DD',
        color: 'white',
        border: 'none',
        padding: '10px 30px',
        cursor: 'pointer',
        borderRadius: '5px',
    };

    const applyHandler = (data) => {
        console.log(data,"dataaaaa")
        dispatch({
          type: HANDLE_PACKAGE_INPUT_CHANGE,
          payload: { usePackage: true, item: data },
        });
        onClose();
      };
      const removeHandler = (data) => {
        dispatch({
          type: HANDLE_PACKAGE_INPUT_CHANGE,
          payload: { usePackage: false, item: data },
        });
        onClose();
      };

    return (
        <div style={{ ...container, overflow: 'auto' }}>
            <table className="table-container">
                <thead className="table-thead">
                    <tr style={{  gap: '20px' }}>
                        {['Package Name', 'Price', 'Services', 'Validity'].map((h, index) => (
                            <th key={index} style={{ width: '100%' }}>
                                {h}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="table-tbody">
                    {data?.map((item, index) => (
                        <tr key={index}>
                            <td style={{ width: '100%' }}>{item?.packageName}</td>
                            <td style={{ width: '100%' }}>₹ {item?.price}</td>
                            <td style={{ width: '100%' }}>
                                {item?.services?.map((services, serviceIndex) => (
                                    <div key={serviceIndex}>{services.serviceName}</div>
                                ))}
                            </td>
                            <td style={{ width: '100%' }}>{item?.validity}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
            {hasMatchingServiceId(state.services, data[0].services) ? (
            state.usePackage ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <button style={{ ...button }} onClick={() => removeHandler(data[0])}>
                  Remove
                </button>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <button style={{ ...button }} onClick={() => applyHandler(data[0])}>
                  apply
                </button>
              </div>
            )
          ) : (
            <span style={{ color: 'red' }}>
              {/* Your Services count zero or please select a Matched services */}
              Please select a matched services.
            </span>
          )}
        </div>
    );
};

export default PackageCard;



function hasMatchingServiceId(array1, array2) {
    //console.log(array1, "array 1", array2, "array 2")
    const serviceIdsSet = new Set(array1?.map((item) => item.service));
    for (const item of array2) {
      if (serviceIdsSet.has(item._id)) {
        console.log()
        return true;
      }
    }
    return false;
  }

function calculatePriceSubTotal(items) {
    return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
}
