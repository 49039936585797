import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from 'src/views/hooks/useMain';

const AppointmentDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { fetchAppointmentById, activeBranchId } = useMain();

  const getData = async (id) => {
    try {
      setLoading(true);
      const res = await fetchAppointmentById(id, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setData(res.data);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);
    return () => {
      setData({});
    };
  }, [id, activeBranchId]);

  return (
    <PageContainer title="Appointment History" description="this contains all products">
      <DashboardCard2 title="Appointment History">
        {loading ? (
          <ShowLoader />
        ) : Object.keys(data).length > 0 ? (
          <div className="client-container">
            <div className="client-header">
              <div className="profile-img"></div>
              <div>
                <p className="client-text">Full Name</p>
                <h6 className="client-bold-title">{data?.clientName}</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="client-body">
                  <p className="client-text">Gender</p>
                  <h6 className="client-title">{data?.gender}</h6>
                </div>
                <div className="client-body">
                  <p className="client-text">Appointment Status</p>
                  <h6 className="client-title">{data?.appointmentStatus}</h6>
                </div>
              </div>
              <div className="col-6">
                <div className="client-body">
                  <p className="client-text">Mobile</p>
                  <h6 className="client-title">+91 {data?.clientNumber}</h6>
                </div>
              </div>
            </div>
            <table className="table-container mt-5">
              <thead className="table-thead">
                <tr>
                  {['Services Name', 'Service Provider', 'Price'].map((h, index) => {
                    return (
                      <th key={index} style={{ width: '100%' }}>
                        {h}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="table-tbody">
                {data?.serviceSelected?.map((e, index) => {
                  return (
                    <>
                      <tr key={e?.service?._id}>
                        <td style={{ width: '100%' }}>{e?.service?.serviceName}</td>
                        <td style={{ width: '100%' }}>{e?.serviceProvider?.name}</td>
                        <td style={{ width: '100%' }}>{e?.service?.price}</td>
                        {/* <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    // handleDeleteField={handleDeleteField}
                    // handleDetails={() => handleDetails(item)}
                    // field={item?._id}
                  />
                </td> */}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No Data Found</p>
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default AppointmentDetails;
