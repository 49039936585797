import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import {
  CalendarMonthOutlined,
  EastRounded,
  GraphicEqOutlined,
  PersonAddAlt1Outlined,
  SentimentVerySatisfiedRounded,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import CustomShowLoader from 'src/components/CustomComponents/CustomShowLoader';

const Overview = ({overviewData}) => {
  


  const data = [
    {
      no: overviewData?.monthlyAttendance,
      label: 'MONTHLY PRESENT',
      color: '#FF5E1B',
      icon: <GraphicEqOutlined sx={{ color: '#FF5E1B' }} />,
      title: 'Monthly Present',
    },
    {
      no: overviewData?.monthlyAppointments,
      label: 'TODAY APPOINTMENTS',
      color: '#03C4A9',
      icon: <CalendarMonthOutlined sx={{ color: '#03C4A9' }} />,
      title: 'Appointments',
    },
    {
      no: overviewData?.totalSalary,
      label: 'TOTAL SALARY',
      color: '#3071FE',
      icon: <PersonAddAlt1Outlined sx={{ color: '#3071FE' }} />,
      title: 'Salary',
    },
    {
      no: overviewData?.totalIncentive,
      label: 'TOTAL INCENTIVE',
      color: '#FBCA0E',
      icon: <SentimentVerySatisfiedRounded sx={{ color: '#FBCA0E' }} />,
      title: 'Incentive',
    },
  ];
  //console.log(monthlyAttendanceData);
  return (
    <Grid container spacing={6}>
      {data.map((item, index) => (
        <Grid item xs={6} md={3} key={index}>
          <Paper
            elevation={3}
            style={{
              backgroundColor: item.color,
              textAlign: 'center',
              height: '120px',
              padding: '20px 20px',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              flexDirection: 'column',
              borderRadius: '10px',
              gap: '10px',
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              width={'100%'}
              color={'white'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <IconButton
                sx={{
                  background: 'white',
                  '&:hover': {
                    background: 'white',
                  },
                }}
              >
                {item.icon}
              </IconButton>
            </Stack>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div style={{ color: '#fff', fontWeight: '900', fontSize: '25px' }}>
                {item.no}
              </div>
              <div style={{ color: '#fff', fontWeight: '600' }}>{item.label}</div>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Overview;
