
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Grid } from 'rsuite';
import PageContainer from 'src/components/container/PageContainer';
import TextInputs from 'src/components/inputs/TextInputs';
import DashboardCard from 'src/components/shared/DashboardCard';
import ClinetSuggestion from '../ClinetSuggestion';
import { Button } from 'reactstrap';
import RadioInputs from 'src/components/inputs/RadioInputs';
import { useMain } from 'src/views/hooks/useMain';
import { useParams } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const PackageDetails = () => {
  const [data, setData] = useState({});
  const [openDialogue, setOpenDialogue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ clientNumber: '', packageId: '', paymentType: '', gender: '', clientName: '' });
  const [clientSuggested, setClientSuggested] = useState([]);
  const [clientExists, setClientExists] = useState(true);
  const [allClinetsData, setAllClinetsData] = useState([]);
  const [showNewClientFields, setShowNewClientFields] = useState(false);

  const { packageId } = useParams();

  const { fetchSinglePackageDetail, buyPackage, activeBranchId, setShowMessage, getBranch } = useMain();

  const getSinglePackageData = async (packageId, activeBranchId) => {
    try {
      setLoading(true);
      const res = await fetchSinglePackageDetail(packageId, activeBranchId);
      if (res.statusCode === 200) {
        setData(res.data);
      } else {
        setShowMessage({ message: res.message || "Error Occurred while fetching package details", messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || "Something went wrong", messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };
  console.log(data,"packageBuyDataa")

  useEffect(() => {
    if (activeBranchId && packageId) {
      getSinglePackageData(packageId, activeBranchId);
    }
    return () => {
      setData({});
    };
  }, [activeBranchId, packageId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === 'clientNumber') {
      updateSuggested(value);
    }
  };

  const handleBuyPackage = async () => {
    const { clientNumber, paymentType, clientName } = formData;
    if (clientNumber === '' || clientNumber.length !== 10)
      return setShowMessage({ message: 'Client Number is required or invalid', messageType: 'error' });
    if (paymentType === '')
      return setShowMessage({ message: 'Payment Type is required', messageType: 'error' });

    let requestBody = { clientNumber, packageId, paymentType, clientName };
    if (!clientExists) {
      requestBody = { ...formData };
    }

    try {
      setLoading(true);
      const res = await buyPackage(activeBranchId, requestBody);
      if (res.statusCode === 200) {
        setShowMessage({ message: res.message || "Successfully Bought Package", messageType: 'success' });
        handleCloseDialogue();
      } else {
        setShowMessage({ message: res.message || "Error Occurred while Buying Package", messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || "Something went wrong", messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const updateSuggested = (inputValue) => {
    const suggestions = allClinetsData.filter((client) =>
      client.clientNumber.toLowerCase().includes(inputValue.toLowerCase()),
    );
    setClientSuggested(suggestions);
    setClientExists(suggestions.length > 0);
  };

  const handleSuggestedClick = (client) => {
    setFormData(prev => ({ ...prev, clientNumber: client.clientNumber }));
    setClientSuggested([]);
  };

  const handleOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setFormData({ clientNumber: '', packageId: data._id, paymentType: '', gender: '', clientName: '' });
    setClientSuggested([]);
    setShowNewClientFields(false);
  };

  const getBranchData = async (activeBranchId) => {
    try {
      const res = await getBranch(activeBranchId);
      if (res.statusCode === 200) {
        setAllClinetsData(res?.data?.client || []);
      }
    } catch (error) {
      setShowMessage({ message: error.message || "Something went wrong", messageType: 'error' });
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getBranchData(activeBranchId);
    }
    return () => {
      setAllClinetsData([]);
    };
  }, [activeBranchId]);

  const handleAddNew = () => {
    setFormData(prev => ({ ...prev, packageId: data._id, clientName: '', gender: '' }));
    setShowNewClientFields(true);
  };

  const handleAmountPaidInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <PageContainer title="Package Details" description="This is Package type membership details">
      <DashboardCard title="Package Details">
        <Grid container spacing={4}>
          <Grid container item xs={12} md={8} spacing={2} style={{ padding: '1rem' }}>
            <Grid container item xs={12} alignItems="center" style={{ display: 'flex', alignItems: 'center', gap: '30px', padding: '1rem' }}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Package Name: <strong>{data.packageName}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Package Price: <strong>{data.price}</strong>
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center" style={{ display: 'flex', alignItems: 'center', gap: '3.5rem', padding: '1rem' }}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Package services: {data.services?.map(service => <span key={service._id}><strong>{service.serviceName}<br /></strong></span>)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Valid Till: <strong>{data.validTill}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <button
              style={{
                float: 'right',
                backgroundColor: '#6174DD',
                padding: '10px 15px',
                color: '#fff',
                borderRadius: '5px',
                fontSize: '14px',
                border: 'none',
                marginBottom: '10px',
              }}
              onClick={handleOpenDialogue}
            >
              Buy Package
            </button>
          </Grid>

          <Grid item xs={12}>
            <table className="table-container mt-3">
              <thead className="table-thead">
                <tr>
                  {['Package Name', 'Package Services', 'Package Price'].map((h, index) => (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-tbody">
                <tr>
                  <td style={{ width: '100%' }}>{data?.packageName}</td>
                  <td style={{ width: '100%' }}>
                    {data?.services?.map(item => <span key={item._id}>{item?.serviceName}</span>)}
                  </td>
                  <td style={{ width: '100%' }}>{data?.price}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Dialog
          open={openDialogue}
          onClose={handleCloseDialogue}
          maxWidth="md"
          sx={{
            '& .MuiDialog-paper': {
              width: '600px',
              minHeight: '50vh',
            },
          }}
        >
          <DialogTitle> Buy Package</DialogTitle>
          <DialogContent>
            <DialogContentText>Here you can buy Package.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box style={{ borderBottom: 'none', width: '22.5rem' }}>
                <TextInputs
                  title={'Client Number'}
                  name={'clientNumber'}
                  placeholder='Enter client number'
                  value={formData?.clientNumber}
                  handleChange={handleChange}
                  type={'text'}
                />
              </Box>
            </Grid>
            {!clientExists && !showNewClientFields && (
              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                <Button
                  variant="contained"
                  onClick={handleAddNew}
                  style={{ backgroundColor: '#6174DD', borderColor: '#6174DD' }}
                >
                  Add New
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              {clientExists && (
                <ClinetSuggestion
                  data={clientSuggested}
                  handleSuggestedClick={handleSuggestedClick}
                />
              )}
              {showNewClientFields && !clientExists && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <Box style={{ borderBottom: 'none', width: '22.5rem' }}>
                    <TextInputs
                      title={'Client Name'}
                      name={'clientName'}
                      value={formData?.clientName}
                      handleChange={handleChange}
                      type={'text'}
                    />
                  </Box>
                  <Box style={{ borderBottom: 'none', width: '22.5rem' }}>
                    <RadioInputs
                      title={'Gender'}
                      name={'gender'}
                      value={formData?.gender}
                      handleChange={handleChange}
                      options={[
                        { title: 'Male', value: 'Male' },
                        { title: 'Female', value: 'Female' },
                      ]}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box style={{ borderBottom: 'none', width: '22.5rem' }}>
                <Typography component="label"><strong>Payment Type</strong></Typography>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  data-validation="required"
                  name="paymentType"
                  value={formData.paymentType || ''}
                  onChange={handleAmountPaidInputChange}
                  style={{
                    display: 'block',
                    width: '100%',
                    padding: '13px',
                    fontSize: '16px',
                    margin: '8px 0',
                    borderRadius: '4px',
                  }}
                >
                  <option value="">
                    --Select--
                  </option>
                  <option value="Cash">Cash</option>
                  <option value="Credit/Debit Card">Credit/Debit card</option>
                  <option value="Paytm">Paytm</option>
                  <option value="PhonePe">PhonePe</option>
                  <option value="Gpay">Gpay</option>
                </select>
              </Box>
            </Grid>
            <Grid item xs={6} style={{ borderBottom: 'none', width: '22.5rem' }}>
              <TextInputs
                title={'Amount Paid'}
                name={'amountPaid'}
                placeholder='amount paid'
                value={formData.amountPaid || data?.price}
                handleChange={handleAmountPaidInputChange}
                type={'text'}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button style={{ backgroundColor: '#FF474D', color: 'white' }} onClick={handleCloseDialogue}>Cancel</Button>
          <Button style={{ backgroundColor: '#6174DD', color: 'white' }} onClick={handleBuyPackage} disabled={loading}>
            {loading ? 'Buying...' : 'Buy Package'}
          </Button>
        </DialogActions>

      </Dialog>
      {loading && <ShowLoader />}
    </DashboardCard>
    </PageContainer >

  );
}

export default PackageDetails