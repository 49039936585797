import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Button } from 'reactstrap';
import { useMain } from 'src/views/hooks/useMain';
import { Box } from '@mui/system';
import TextInputs from 'src/components/inputs/TextInputs';

const ToggleMaskingPage = ({ data, refreshData }) => {
  const { updateOwnerSettings, setShowMessage } = useMain();

  const [isMasked, setIsMasked] = useState(null);
  const [password, setPassword] = useState('');
  const [openDialogue, setOpenDialogue] = useState(false);

  const sensitiveInformation = '0123456789';

  const handleToggleChange = (isMasked) => {
    setIsMasked(isMasked);
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleMaskToggle = async () => {
    if (password === '')
      return setShowMessage({ message: 'Please enter login password', messageType: 'error' });
    try {
      const res = await updateOwnerSettings(data._id, { isMasked: isMasked, password });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully Update Settings',
          messageType: 'success',
        });
        setIsMasked(null);
        setPassword('');
        setOpenDialogue(false);
        refreshData(data._id);
      } else {
        setShowMessage({ message: res.message || 'Update Settings Failed', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
    }
  };

  const maskData = (value) => {
    if (data?.isMasked) {
      const maskLength = Math.ceil(value.length / 2);
      return '*'.repeat(maskLength) + value.substring(maskLength);
    } else {
      return value;
    }
  };

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: '#FAFAFA',
        borderRadius: '10px',
        border: '0.5px solid #CFCFCF',
      }}
    >
      <FormControlLabel
        control={
          <Switch checked={data?.isMasked} onChange={() => handleToggleChange(!data?.isMasked)} />
        }
        label="Mask Information"
      />

      <Typography variant="h6" style={{ marginTop: '20px' }}>
        {data?.isMasked ? 'Masked Information' : 'Original Information'}
      </Typography>
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        {maskData(sensitiveInformation)}
      </Typography>
      <Dialog open={openDialogue} onClose={handleCloseDialogue}>
        <DialogTitle>Confirm Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Mashking is {isMasked ? 'ON' : 'OFF'}
          </DialogContentText>
          <Box style={{ marginTop: '10px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextInputs
                  title={'Login Password'}
                  name={'password'}
                  value={password}
                  handleChange={(e) => setPassword(e.target.value)}
                  type={'passwrod'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogue}>Cancel</Button>
          <Button onClick={() => handleMaskToggle()} autoFocus>
            Sure
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToggleMaskingPage;
