import React, { useState, useEffect, useRef } from 'react';
import './BigFilter.css'; // Ensure to create/update this CSS file

const BigFilter = ({ options, value, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false); // New state for focus
    const selectRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        setIsFocused(!isOpen);
    };

    const handleOptionChange = (option) => {
        const newValues = value.includes(option)
            ? value.filter((val) => val !== option)
            : [...value, option];

        onChange(newValues);
    };

    const handleClear = () => {
        onChange([]); // Clear all selected values
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsFocused(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="customSelect" ref={selectRef}>
            <div className={`custom-select__header ${isFocused ? 'focused' : ''}`} onClick={handleToggle}>
                {/* {value.length > 0 ? value.join(', ') : placeholder} */}
                {placeholder}
            </div>
            {isOpen && (
                <div className="custom-select__dropdown">
                    <div className="custom-select__options">
                        {options.map((section) => (
                            <div key={section.title} className="custom-select__section">
                                <div className="custom-select__section-title">{section.title}</div>
                                {section.items.map((option) => (
                                    <div key={option.value} className="custom-select__option">
                                        <input
                                            type="checkbox"
                                            id={option.value}
                                            checked={value.includes(option.value)}
                                            onChange={() => handleOptionChange(option.value)}
                                        />
                                        <label style={{cursor:"pointer"}} htmlFor={option.value}>{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="custom-select__clear">
                        <button className="custom-select__clear-btn" onClick={handleClear}>
                            Clear
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BigFilter;