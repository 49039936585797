import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogTitle,
  Pagination,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
} from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import FieldData from './FieldData';
import SearchImg from '../../assets/search.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import * as XLSX from 'xlsx';
import Book1Client from '../../assets/excel/Book1Client.xlsx';
import TextInputs from 'src/components/inputs/TextInputs';
import ClinetSuggestion from '../Billings/ClinetSuggestion';
import RadioInputs from 'src/components/inputs/RadioInputs';
import { width } from '@mui/system';
import { useLocation } from 'react-router';

const filterMenu = [
  { value: 'billsFrequency', label: 'Most Frequent' },
  { value: 'totalBillsAmount', label: 'Higest billed' },
];
const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllClients = () => {
  const [formData, setFormData] = useState({
    clientNumber: '', clientName: '', amountPaid: [
      {
        paymentType: 'Cash',
        amount: '',
      },
    ],
    gender: ''
  });
  const [clientSuggested, setClientSuggested] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNewClientFields, setShowNewClientFields] = useState(false);
  const [clientExists, setClientExists] = useState(true);
  const [allClientsData, setAllClientsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [data, setData] = useState([]);
  const [checkedClients, setCheckedClients] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortValue, setSortValue] = useState(-1);
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const { fetchAllClients, activeBranchId, createManyClient, sendBulkSms, setShowMessage, walletPayment, getBranch } =
    useMain();

  const { state } = useLocation();

  // Fetch all clients data on initial load
  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoader(true);
        const res = await fetchAllClients(activeBranchId, '?page=1&limit=999999'); // Fetch all data
        if (res.statusCode === 200) {
          setAllClientsData(res?.data);
          setData(res?.data); // Initialize with full data
        }
      } finally {
        setShowLoader(false);
      }
    };

    if (activeBranchId) {
      fetchData();
    }

    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters(data, limit, genderBy, page, search)
  }, [data, limit, genderBy, page, search])

  useEffect(() => {
    applyFilters(data, limit, genderBy, page, search)
  }, [data, limit, genderBy, page, search])

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Read the file using XLSX
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet contains the data
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        // Set the converted data in state
        setUploadedData(jsonData);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSortBy('');
    setSortValue(-1);
    setSearch('');
    setGenderBy('');
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    let queryParams = `?page=${page}&limit=${limit}${sortBy ? `&sortKey=${sortBy}&sortValue=${sortValue}` : ''
      }${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}`;

    if (key) queryParams = `?page=${page}&limit=${limit}`;
    try {
      setShowLoader(true);
      const res = await fetchAllClients(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res?.data);
      } else {
      }
    } finally {
      setShowLoader(false);
    }
  };

  // useEffect(() => {
  //   if (activeBranchId) {
  //     setClear(true);
  //     getData();
  //   }
  //   return () => {
  //     setData([]);
  //   };
  // }, [activeBranchId, limit, page, sortBy, genderBy]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!uploadedData)
      return setShowMessage({ message: 'Please enter a valid file', messageType: 'error' });
    try {
      setShowLoader(true);
      const res = await createManyClient(
        activeBranchId,
        JSON.stringify({ bulkData: uploadedData }),
      );
      if (res?.statusCode === 200) {
        getData();
        setShowMessage({
          message: res.message || 'Bulk client upload successful',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res.message || 'Bulk client upload failed',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Bulk client upload failed',
        messageType: 'error',
      });
    } finally {
      setShowLoader(false);
      closeModal();
    }
  };

  const downloadExcelTemplate = () => {
    const templateFileName = Book1Client;

    // Create an anchor element to trigger the download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = templateFileName;
    a.download = 'client_template.xlsx';

    // Trigger the click event to download the file
    document.body.appendChild(a);
    a.click();

    // Remove the anchor element
    document.body.removeChild(a);
  };

  const sendBulkSmsHandler = async () => {
    const bulkNumber = checkedClients.map((client) => client.clientNumber);
    try {
      const res = await sendBulkSms({ bulkNumber });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Bulk SMS sent successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Bulk SMS not sent', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    }
  };

  const applyFilters = (data, limit, gender, page, searchName) => {

    let filteredData = data.filter(item => {
      let isGenderMatch = true;
      let isNameMatch = true;

      if (gender) {
        isGenderMatch = item.gender === gender;
      }

      if (searchName) {
        isNameMatch = (item.clientName.toLowerCase().includes(searchName.toLowerCase()) || item.clientNumber.includes(searchName));
      }

      return isGenderMatch && isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData)
  };

  // ====
  // const applyFilters = (data, limit, gender, page, searchName) => {
  //   let filteredData = data.filter(item => {
  //     let isGenderMatch = true;
  //     let isNameMatch = true;

  //     if (gender) {
  //       isGenderMatch = item.gender === gender;
  //     }

  //     if (searchName) {
  //       isNameMatch = (item.clientName.toLowerCase().includes(searchName.toLowerCase()) || item.clientNumber.includes(searchName));
  //     }

  //     return isGenderMatch && isNameMatch;
  //   });

  //   // Applying pagination
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilterData(filteredData);
  // };

  // const applyFilters = (data, limit, gender, page, searchName) => {
  //   let filteredData = data.filter(item => {
  //     let isGenderMatch = true;
  //     let isNameMatch = true;

  //     if (gender) {
  //       isGenderMatch = item.gender === gender;
  //     }

  //     if (searchName) {
  //       isNameMatch = (item.clientName.toLowerCase().includes(searchName.toLowerCase()) || item.clientNumber.includes(searchName));
  //     }

  //     return isGenderMatch && isNameMatch;
  //   });

  //   // Applying pagination
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilterData(filteredData)
  // };



  // const getBranchData = async (activeBranchId) => {
  //   try {
  //     const res = await getBranch(activeBranchId);
  //     if (res.statusCode === 200) {
  //       setAllClientsData(res?.data?.client || []);
  //     }
  //   } catch (error) {
  //     console.error(error); // Log errors for debugging
  //   }
  // };

  useEffect(() => {
    if (activeBranchId) {
      // getBranchData(activeBranchId);
    }
    return () => {
      setAllClientsData([]);
    };
  }, [activeBranchId]);

  const handleWalletPayment = async () => {
    const { clientNumber, amountPaid } = formData;

    // Validate clientNumber and paymentType
    if (clientNumber === '' || clientNumber.length !== 10) {
      return setShowMessage({ message: 'Client Number is required or invalid', messageType: 'error' });
    }

    // Check if client exists in allClientsData to get their current wallet amount
    const existingClient = allClientsData.find(client => client.clientNumber === clientNumber);
    let updatedAmountPaid = amountPaid;

    if (existingClient) {
      updatedAmountPaid = amountPaid.map(payment => {
        const existingAmount = parseFloat(existingClient.walletAmount || 0); // Assuming 'walletAmount' holds the current wallet balance
        return {
          paymentType: payment.paymentType || '', // Ensure paymentType is set
          amount: existingAmount + (parseFloat(payment.amount) || 0), // Add existing amount to new amount
        };
      });
    }

    let requestBody = {
      clientNumber,
      amountPaid: updatedAmountPaid,
    };

    if (!clientExists) {
      requestBody = { ...formData, amountPaid: updatedAmountPaid };
    }

    try {
      setLoading(true);
      const res = await walletPayment(activeBranchId, requestBody);
      if (res.statusCode === 200) {
        setShowMessage({ message: res.message || 'Successfully Submitted Payment', messageType: 'success' });
        setFormData({
          clientNumber: '',
          paymentType: '',
          amountPaid: [{ paymentType: 'Cash', amount: '' }], // Reset amountPaid to initial state
        });
      } else {
        setShowMessage({ message: res.message || 'Error Occurred while Submitting Payment', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      handleCloseDialogue();
      setLoading(false);
    }
  };


  const handleOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const updateSuggested = (fieldName, inputValue) => {
    if (fieldName === 'clientNumber' && !showNewClientFields) {
      const suggestions = allClientsData.filter((client) =>
        client[fieldName === 'clientName' ? 'clientName' : 'clientNumber']
          ?.toLowerCase()
          .includes(inputValue.toLowerCase())
      );
      setClientSuggested(suggestions);
      setClientExists(suggestions.length > 0);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.includes('amountPaid')) {
      const index = parseInt(name.split('-')[1], 10); // Extract index from name
      const field = name.split('-')[2]; // Extract field (paymentType/amount)
      const updatedAmountPaid = [...formData.amountPaid];
      updatedAmountPaid[index] = {
        ...updatedAmountPaid[index],
        [field]: value,
      };
      setFormData((prev) => ({ ...prev, amountPaid: updatedAmountPaid }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    if (name === 'clientNumber' && !showNewClientFields) {
      updateSuggested(name, value);
    } else if (name === 'clientName' && !clientExists) {
      setClientSuggested([]);
    }
  };

  const handleSuggestedClick = (client) => {
    setFormData((prev) => ({
      ...prev,
      clientNumber: client.clientNumber,
      clientName: client.clientName,
    }));
    setClientSuggested([]);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setFormData((prev) => ({ ...prev, clientNumber: '', paymentType: '' }));
    setClientSuggested([]);
  };

  const handleAmountPaidInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('amountPaid')) {
      const index = parseInt(name.split('-')[1], 10); // Extract index from name
      const field = name.split('-')[2]; // Extract field (paymentType/amount)
      const updatedAmountPaid = [...formData.amountPaid];
      updatedAmountPaid[index] = {
        ...updatedAmountPaid[index],
        [field]: field === 'amount' ? parseFloat(value) || 0 : value, // Ensure amount is a number
      };
      setFormData((prev) => ({ ...prev, amountPaid: updatedAmountPaid }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };


  useEffect(() => {
    if (state) {
      setFormData((prev) => ({ ...prev, clientNumber: state?.clientNumber }));
    }
  }, [state]);

  return (
    <PageContainer title="Client History" description="This contains all products">
      <DashboardCard2 title="Client Management">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '80px'
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <InputLabel>Sort By</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '80%',
                }}
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                {filterMenu.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <div>
                <InputLabel>Gender</InputLabel>
                <Select
                  style={{
                    borderRadius: '7px',
                    border: '0.5px solid #6174DD',
                    padding: '4px 12px',
                    background: '#FFF',
                    height: '40px',
                    width: '80%',
                  }}
                  value={genderBy}
                  onChange={(e) => setGenderBy(e.target.value)}
                >
                  {filterGenderWise.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{ width: '80%' }}>
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2} display="flex" gap="10px" alignItems="flex-end">
              {/* <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  background: '#6174DD',
                  height: '40px',
                  width: '100%',
                }}
                onClick={onApplyHandler}
              >
                Apply
              </Button> */}

              <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  height: '40px',
                  width: '80%',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={2}>
              <button
                style={{
                  backgroundColor: '#6174DD',
                  padding: '10px 15px',
                  color: '#fff',
                  borderRadius: '5px',
                  fontSize: '14px',
                  border: 'none',
                  cursor: 'pointer',
                  width: '70%',
                  height: '40px',
                  marginTop: '25px',
                  marginLeft: '40px'
                }}
                onClick={handleOpenDialogue}
              >
                Wallet
              </button>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  background: '#6174DD',
                }}
                onClick={openModal}
              >
                Bulk Client Upload
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* ********************* Client-Wallet-Added****************** */}
        <Dialog
          open={openDialogue}
          onClose={handleCloseDialogue}
          maxWidth="sm"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              padding: '20px',
              minHeight: 'auto',
            },
          }}
        >
          <DialogTitle>Wallet Amount</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here you can add an advance amount
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 'none' }}>
                  <TextInputs
                    title={'Client Number'}
                    name={'clientNumber'}
                    placeholder="Enter client number"
                    value={formData.clientNumber}
                    handleChange={handleChange}
                    type={'text'}
                  />
                </Box>
              </Grid>
              {clientExists && (
                <Grid item xs={12}>
                  <ClinetSuggestion
                    data={clientSuggested}
                    handleSuggestedClick={handleSuggestedClick}
                  />
                </Grid>
              )}
            </Grid>

            {!clientExists && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Box sx={{ borderBottom: 'none', width: '100%' }}>
                      <TextInputs
                        title={'Client Name'}
                        name={'clientName'}
                        value={formData.clientName}
                        handleChange={handleChange}
                        type={'text'}
                      />
                    </Box>
                    <Box sx={{ borderBottom: 'none' }}>
                      <RadioInputs
                        title={'Gender'}
                        name={'gender'}
                        value={formData.gender}
                        handleChange={handleChange}
                        options={[
                          { title: 'Male', value: 'Male' },
                          { title: 'Female', value: 'Female' },
                        ]}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3}>
              {formData.amountPaid.map((payment, index) => (
                <Grid item xs={12} key={index}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Box sx={{ borderBottom: 'none' }}>
                      <Typography component="label">
                        <strong>Payment Type</strong>
                      </Typography>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        data-validation="required"
                        name={`amountPaid-${index}-paymentType`}
                        value={payment.paymentType}
                        onChange={handleAmountPaidInputChange}
                        style={{
                          display: 'block',
                          width: '100%',
                          padding: '13px',
                          fontSize: '16px',
                          margin: '8px 0',
                          borderRadius: '4px',
                        }}
                      >
                        <option value="Cash">Cash</option>
                        <option value="Credit/Debit Card">Credit/Debit Card</option>
                        <option value="Paytm">Paytm</option>
                        <option value="PhonePe">PhonePe</option>
                        <option value="Gpay">Gpay</option>
                      </select>
                    </Box>
                    <Box sx={{ borderBottom: 'none' }}>
                      <TextInputs
                        title={'Amount'}
                        name={`amountPaid-${index}-amount`}
                        value={payment.amount}
                        handleChange={handleAmountPaidInputChange}
                        type={'number'}
                        placeholder="Amount"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogue} color="primary" style={{ backgroundColor: '#FF6666', color: 'white' }}>
              Cancel
            </Button>
            <Button onClick={handleWalletPayment} color="primary" style={{ backgroundColor: '#6174DD', color: 'white' }}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>

        {/*----------------------Modal Dialog-------------------*/}
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Create Many Client</DialogTitle>
          <form onSubmit={handleFormSubmit}>
            <Box p={2}>
              <input
                type="file"
                accept=".xlsx, .xls" // Set accepted file types
                onChange={handleFileChange}
              />
            </Box>
            {showLoader && <ShowLoader value={showLoader} />}
            <Box p={2}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ ml: 2 }}
                onClick={downloadExcelTemplate}
              >
                <ArrowCircleDownIcon sx={{ margin: '0px 2px 0px 0px' }} />
                Sample Download
              </Button>
            </Box>
          </form>
        </Dialog>

        {/* -------------------------tsble start-------------------------- */}

        {showLoader ? (
          <ShowLoader />
        ) : filterData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  {/* Use the fetched client data instead of the static data */}
                  <FieldData
                    data={filterData}
                    setData={setData}
                    getData={getData}
                    checkedClients={checkedClients}
                    setCheckedClients={setCheckedClients}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            // count={data.length < limit ? page : page + 1}
            // count={filterData && filterData?.length < limit ? page : page + 1}
            count={Math.ceil(data.length / limit)}
            page={page}
            color="primary"
            // onChange={(e, page) => setPage(page)}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
        {checkedClients.length !== 0 && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                margin: '10px 0px 0px 0px',
              }}
            >
              <div style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  target="_blank"
                  sx={{
                    background: '#25d366',
                    '&:hover': { background: '#25d366' },
                  }}
                >
                  <WhatsAppIcon sx={{ margin: '0 5px' }} />
                  Bulk WhatsApp
                </Button>
              </div>
              <div style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={sendBulkSmsHandler}
                >
                  Send Bulk Sms
                </Button>
              </div>
            </div>
          </Box>
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllClients;
