import React, { useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button } from '@mui/material';
import ServiceMembership from './ServiceMembership';
import DiscountMembership from './DiscountMembership';
import { useNavigate } from 'react-router-dom';

const AddMembership = () => {
  const [membershipType, setMembershipType] = useState('serviceType');

  const navigate = useNavigate();
  const handleMembershipTypeChange = (type) => {
    setMembershipType(type);
  };

  return (
    <PageContainer title="Create Membership" description="This is add Membership">
      <DashboardCard title="Create Membership">
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            // color={membershipType ? 'primary' : 'secondary'}
            onClick={() => handleMembershipTypeChange('discountType')}
            style={{ ...(membershipType === 'discountType' && { border: '3px solid #FFD700' }) }}
          >
            Discount Membership
          </Button>
          <Button
            variant="contained"
            // color={membershipType ? 'primary' : 'secondary'}
            onClick={() => handleMembershipTypeChange('serviceType')}
            style={{ ...(membershipType === 'serviceType' && { border: '3px solid #FFD700' }) }}
          >
            Service Membership
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/PackagePage');
            }}
            style={{ marginLeft: '2px' }}
          >
            Packages
          </Button>
        </div>
        {membershipType === 'serviceType' ? (
          <ServiceMembership />
        ) : (
          <DiscountMembership />
        )}
      </DashboardCard>
    </PageContainer>
  );
};

export default AddMembership;
