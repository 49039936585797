export const HANDLE_CLIENT_INPUT_CHANGE = 'HANDLE_CLIENT_INPUT_CHANGE';
export const HANDLE_CLIENT_SUGGESTED_CLICK = 'HANDLE_CLIENT_SUGGESTED_CLICK';
export const HANDLE_CLIENT_NUMBER_INPUT_CHANGE = 'HANDLE_CLIENT_NUMBER_INPUT_CHANGE'
export const HANDLE_PRODUCT_INPUT_CHANGE = 'HANDLE_PRODUCT_INPUT_CHANGE';
export const HANDLE_PRODUCT_SUGGESTED_CLICK = 'HANDLE_PRODUCT_SUGGESTED_CLICK';
export const ADD_PRODUCT_ROW = 'ADD_PRODUCT_ROW';
export const DELETE_PRODUCT_ROW = 'DELETE_PRODUCT_ROW';
export const HANDLE_SERVICE_INPUT_CHANGE = 'HANDLE_SERVICE_INPUT_CHANGE';
export const HANDLE_SERVICE_SUGGESTED_CLICK = 'HANDLE_SERVICE_SUGGESTED_CLICK';
export const ADD_SERVICE_ROW = 'ADD_SERVICE_ROW';
export const DELETE_SERVICE_ROW = 'DELETE_SERVICE_ROW';
export const HANDLE_DISCOUNT_INPUT_CHANGE = 'HANDLE_DISCOUNT_INPUT_CHANGE';
export const HANDLE_TAXE_INPUT_CHANGE = 'HANDLE_TAXE_INPUT_CHANGE';
export const HANDLE_AMOUNTPAID_INPUT_CHANGE = 'HANDLE_AMOUNTPAID_INPUT_CHANGE';
export const ADD_AMOUNTPAID_ROW = 'ADD_AMOUNTPAID_ROW';
export const DELETE_AMOUNTPAID_ROW = 'DELETE_AMOUNTPAID';
export const REST_BILL_STATE = 'REST_BILL_STATE';
export const HANDLE_COUPON_INPUT_CHANGE = 'HANDLE_COUPON_INPUT_CHANGE';
export const HANDLE_MEMBERSHIP_INPUT_CHANGE='HANDLE_MEMBERSHIP_INPUT_CHANGE';
export const HANDLE_PACKAGE_INPUT_CHANGE='HANDLE_PACKAGE_INPUT_CHANGE';
export const HANDLE_ADD_BILL_BY_APPOINTMENTID='HANDLE_ADD_BILL_BY_APPOINTMENTID'
export const  HANDLE_REWARD_POINT_CHANGE=' HANDLE_REWARD_POINT_CHANGE';