import { Box, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import FieldData from './FieldData';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const initialFilter = [
  {
    trait: 'created_at_utc',
    op: 'gt',
    val: '2020-01-01',
  },
  {
    trait: 'created_at_utc',
    op: 'lt',
    supr_op: 'and',
    val: getCurrentDateInFormat(),
  },
];
const AllWhatsapp = () => {
  const { fetchWhatsappUser, activeBranchId } = useMain();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [hasNextPage, setHasNextPage] = useState(false);

  const getData = async () => {
    const queryParams = `?offset=${limit * (page - 1)}&limit=${limit}`;
    setLoading(true);
    try {
      const res = await fetchWhatsappUser({ filters: filter }, queryParams);
      if (res.statusCode === 200) {
        const result = res?.data?.data;
        setHasNextPage(result?.has_next_page || false);
        setData(result?.customers || []);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) getData();
    return () => {
      setData([]);
    };
  }, [activeBranchId, page, limit]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <PageContainer title="whatsapp" description="This contains all whatsapp">
      <DashboardCard2 title="whatsapp">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  // width:'100px'
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <ShowLoader />
        ) : data.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <FieldData
                    data={data}
                    setData={setData}
                    getData={getData}
                    hasNextPage={hasNextPage}
                    page={page}
                    setPage={setPage}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllWhatsapp;

function getCurrentDateInFormat() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
