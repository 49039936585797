import { Backdrop, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

function CustomLoader({ open, data, link = null }) {
  const navigate = useNavigate();
  if (open)
    return (
      <Backdrop sx={{ color: '#fff', zIndex: 100 }} open={open} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  if (data?.length == 0)
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: 'red',
          fontWeight: 'bold',
          fontSize: '18px',
        }}
      >
        No Data Found
        <br />
        <br />
        {link != null && (
          <Button
            variant="contained"
            onClick={() => {
              navigate(link);
            }}
          >
            Create Now
          </Button>
        )}
      </div>
    );
}

export default CustomLoader;

export const ReqField = (color) => {
  return <span style={{ color: 'red' }}>*</span>;
};
